import { createPromiseClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  DeleteErTemplateSectionRequest,
  EmployeeRecordBuilderService,
  ErbFieldCategories,
  ErTemplate,
  ErTemplateSection,
  RetrieveDefaultErTemplateSectionRequest,
  RetrieveErbFieldsByCategoryIdRequest,
  RetrieveErbFieldsByCategoryIdResponse,
  RetrieveErbFieldsResponse,
  RetrieveErTemplateRequest,
  SaveErTemplateSectionRequest
} from '@thrivea/organization-client';
import { msalInstance } from '@/main';
import { tokenRequest } from '@utils/authConfig';
import { Empty } from '@bufbuild/protobuf';

export const employeeRecordBuilderClient = createPromiseClient(
  EmployeeRecordBuilderService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveErbFieldCategoriesAndSections = async (request: Empty): Promise<ErbFieldCategories> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await employeeRecordBuilderClient.retrieveErbFieldCategoriesAndSections(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveDefaultErTemplateSection = async (request: RetrieveDefaultErTemplateSectionRequest): Promise<ErTemplateSection> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await employeeRecordBuilderClient.retrieveDefaultErTemplateSection(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const saveErTemplateSection = async (request: SaveErTemplateSectionRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await employeeRecordBuilderClient.saveErTemplateSection(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveErTemplate = async (request: RetrieveErTemplateRequest): Promise<ErTemplate> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await employeeRecordBuilderClient.retrieveErTemplate(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveErbFieldsByCategoryId = async (request: RetrieveErbFieldsByCategoryIdRequest): Promise<RetrieveErbFieldsByCategoryIdResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await employeeRecordBuilderClient.retrieveErbFieldsByCategoryId(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const deleteErTemplateSection = async (request: DeleteErTemplateSectionRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await employeeRecordBuilderClient.deleteErTemplateSection(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveErbFields = async (request: Empty): Promise<RetrieveErbFieldsResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await employeeRecordBuilderClient.retrieveErbFields(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};
