import { Button, ButtonProps, styled } from '@mui/material';

interface AssetsActionButtonProps extends ButtonProps {
  isActive?: boolean;
}

export const AssetsActionButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isActive'
})<AssetsActionButtonProps>(({ theme, isActive }) => ({
  backgroundColor: isActive ? theme.palette.customTheme.selectItem : theme.palette.customTheme.bgr,
  color: theme.palette.primary.main,
  padding: '8px',
  border: '2px solid',
  borderColor: isActive ? theme.palette.customTheme.navColor : theme.palette.grey[900],
  borderRadius: '12px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '4px',
  fontWeight: 600,
  lineHeight: '20px',
  opacity: 1,
  transition: theme.transitions.create('background-color, border-color', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  '&:hover': {
    backgroundColor: theme.palette.common.white
  }
}));
