import { all, call, delay, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  CopyFilesRequest,
  CreateFolderRequest,
  EditDescriptionRequest,
  MoveFilesRequest,
  RenameFileRequest,
  RetrieveFilesInfoRequest,
  RetrieveFilesRequest,
  RetrieveFilesResponse,
  RetrieveTopLevelFoldersRequest,
  RetrieveTopLevelFoldersResponse,
  RetrieveTrashBinFilesRequest,
  RetrieveTrashBinFilesResponse,
  SoftDeleteFilesRequest,
  StarFileRequest,
  UploadFilesRequest
} from '@thrivea/organization-client';
import * as Sentry from '@sentry/react';
import {
  createFolderFailed,
  createFolderRequested,
  createFolderSucceeded,
  editDescriptionRequested,
  editDescriptionSucceeded,
  editDescriptionFailed,
  renameFileFailed,
  renameFileRequested,
  renameFileSucceeded,
  retrieveFilesFailed,
  retrieveFilesRequested,
  retrieveFilesSucceeded,
  retrieveMyUploadsReadSasTokenFailed,
  retrieveMyUploadsReadSasTokenSucceeded,
  retrieveTopLevelFoldersFailed,
  retrieveTopLevelFoldersRequested,
  retrieveTopLevelFoldersSucceeded,
  uploadFilesFailed,
  uploadFilesRequested,
  uploadFilesSucceeded,
  retrieveMyUploadsCreateDeleteSasUriSucceeded,
  retrieveMyUploadsCreateDeleteSasUriFailed,
  refreshMyUploadsCreateDeleteSasUriSucceeded,
  refreshMyUploadsCreateDeleteSasUriFailed,
  retrieveMyUploadsReadSasTokenRequested,
  retrieveMyUploadsCreateDeleteSasUriRequested,
  refreshMyUploadsCreateDeleteSasUriRequested,
  selectMyUploadsCreateDeleteSasUri,
  selectFilesWithStatusForUpload,
  notifyBackendOnUpload,
  selectUploadedFilesWithStatuses,
  softDeleteFilesSucceeded,
  softDeleteFilesFailed,
  softDeleteFilesRequested,
  retrieveFilesInfoSucceeded,
  retrieveFilesInfoFailed,
  retrieveFilesInfoRequested,
  selectRootFolderId,
  copyFilesSucceeded,
  copyFilesFailed,
  copyFilesRequested,
  starFileSucceeded,
  starFileFailed,
  starFileRequested,
  moveFilesSucceeded,
  moveFilesFailed,
  moveFilesRequested,
  retrieveTrashBinFilesSucceeded,
  retrieveTrashBinFilesFailed,
  retrieveTrashBinFilesRequested
} from '@features/assets';
import {
  copyFiles,
  createFolder,
  editDescription,
  moveFiles,
  renameFile,
  retrieveFiles,
  retrieveFilesInfo,
  retrieveTopLevelFolders,
  retrieveTrashBinFiles,
  softDeleteFiles,
  starFile,
  uploadFiles
} from '@api/assets.api';
import { PayloadAction } from '@reduxjs/toolkit';
import { refreshMyUploadsCreateDeleteSasUri, retrieveMyUploadsCreateDeleteSasUri, retrieveMyUploadsReadSasToken } from '@api/shared-access-signature.api';
import { Empty } from '@bufbuild/protobuf';
import { SasToken, SasUri } from '@thrivea/auth-client';
import { FileWithStatus, UploadFilesInDirectoryWithProgressRequest, uploadFilesWithProgressInDirectory } from '@api/blob-storage.api';
import { buildFileTree, handleFileUploaded, handleStartUploadingFile, handleUpdateFileUploadProgress } from './assets.service';
import { selectCurrentEmployeeId } from '@app/user';
import { showSuccess } from '../snackbar';
import { t } from 'i18next';

const MAX_RETRY_COUNT = 5;

function* retrieveFilesRequestedGenerator(action: PayloadAction<RetrieveFilesRequest>) {
  try {
    const response: RetrieveFilesResponse = yield call(retrieveFiles, action.payload);
    yield put(retrieveFilesSucceeded(response));
  } catch (error) {
    console.log('retrieveFilesRequestedGenerator error', error);
    Sentry.captureException(error);
    yield put(retrieveFilesFailed());
  }
}

function* retrieveTopLevelFoldersRequestedGenerator(action: PayloadAction<RetrieveTopLevelFoldersRequest>) {
  try {
    const response: RetrieveTopLevelFoldersResponse = yield call(retrieveTopLevelFolders, action.payload);
    yield put(retrieveTopLevelFoldersSucceeded(response));
  } catch (error) {
    console.log('retrieveTopLevelFoldersRequestedGenerator error', error);
    Sentry.captureException(error);
    yield put(retrieveTopLevelFoldersFailed());
  }
}

function* uploadFilesRequestedGenerator() {
  let retryCount = 0;
  const filesWithStatus: FileWithStatus[] = yield select(selectFilesWithStatusForUpload);
  let lastError: unknown = undefined;

  while (retryCount < MAX_RETRY_COUNT) {
    const sasUri: string = yield select(selectMyUploadsCreateDeleteSasUri);

    const request: UploadFilesInDirectoryWithProgressRequest = {
      sasUri,
      filesWithStatus,
      handleUpdateFileUploadProgress,
      handleStartUploadingFile,
      handleFileUploaded
    };

    try {
      yield call(uploadFilesWithProgressInDirectory, request);
      yield put(notifyBackendOnUpload());

      return;
    } catch (error) {
      retryCount++;
      lastError = error;

      if (sasUri === '') {
        const newSasUri: SasUri = yield call(retrieveMyUploadsCreateDeleteSasUriRequested, new Empty());
        yield put(retrieveMyUploadsCreateDeleteSasUriSucceeded(newSasUri));
      } else {
        const newSasUri: SasUri = yield call(refreshMyUploadsCreateDeleteSasUri, new Empty());
        yield put(refreshMyUploadsCreateDeleteSasUriSucceeded(newSasUri));
      }

      yield delay(500 * retryCount + 1);
    }
  }

  Sentry.captureException(lastError);
  yield put(uploadFilesFailed());
}

function* notifyBackendOnUploadGenerator() {
  const employeeId: string = yield select(selectCurrentEmployeeId);
  const filesWithStatus: FileWithStatus[] = yield select(selectUploadedFilesWithStatuses);
  const rootFolderId = yield select(selectRootFolderId);

  // Convert acceptedFiles to tree structure
  const uploadedFiles = buildFileTree(filesWithStatus);

  // TODO:
  // Create the UploadFilesRequest object
  const request = new UploadFilesRequest({
    uploadedFiles,
    uploadedBy: employeeId,
    rootFolderId
  });

  console.log(request); // Output the request object


  try {
    const _: Empty = yield call(uploadFiles, new UploadFilesRequest({ uploadedFiles, rootFolderId, uploadedBy: employeeId }));
    yield put(uploadFilesSucceeded());
    yield put(showSuccess(t('file_with_count', { ns: 'assets', count: uploadFiles.length })));
  } catch (error) {
    console.log('notifyBackendOnUploadGenerator error:', error);
    Sentry.captureException(error);
    yield put(uploadFilesFailed());
  }
}

function* createFolderRequestedGenerator(action: PayloadAction<CreateFolderRequest>) {
  try {
    yield call(createFolder, action.payload);
    yield put(createFolderSucceeded(action.payload));
  } catch (error) {
    console.log('createFolderRequestedGenerator error', error);
    Sentry.captureException(error);
    yield put(createFolderFailed());
  }
}

function* renameFileRequestedGenerator(action: PayloadAction<RenameFileRequest>) {
  try {
    yield call(renameFile, action.payload);
    yield put(renameFileSucceeded(action.payload.newName));
  } catch (error) {
    console.log('renameFileRequestedGenerator error: ', error);
    Sentry.captureException(error);
    yield put(renameFileFailed());
  }
}

function* editDescriptionRequestedGenerator(action: PayloadAction<EditDescriptionRequest>) {
  try {
    const response = yield call(editDescription, action.payload);
    yield put(editDescriptionSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    console.log('editDescriptionRequestedGenerator error', error);
    yield put(editDescriptionFailed());
  }
}

function* retrieveMyUploadsReadSasTokenRequestedGenerator(action: PayloadAction<Empty>) {
  try {
    const response: SasToken = yield call(retrieveMyUploadsReadSasToken, action.payload);
    yield put(retrieveMyUploadsReadSasTokenSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveMyUploadsReadSasTokenFailed());
  }
}

function* retrieveMyUploadsCreateDeleteSasUriRequestedGenerator(action: PayloadAction<Empty>) {
  try {
    const response: SasUri = yield call(retrieveMyUploadsCreateDeleteSasUri, action.payload);
    yield put(retrieveMyUploadsCreateDeleteSasUriSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveMyUploadsCreateDeleteSasUriFailed());
  }
}

function* refreshMyUploadsCreateDeleteSasUriRequestedGenerator(action: PayloadAction<Empty>) {
  try {
    const response: SasUri = yield call(refreshMyUploadsCreateDeleteSasUri, action.payload);
    yield put(refreshMyUploadsCreateDeleteSasUriSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(refreshMyUploadsCreateDeleteSasUriFailed());
  }
}

function* softDeleteFilesRequestedGenerator(action: PayloadAction<SoftDeleteFilesRequest>) {
  try {
    yield call(softDeleteFiles, action.payload);
    yield put(softDeleteFilesSucceeded(action.payload.fileIds));
    yield put(showSuccess(t('file_removed_with_count', { ns: 'assets', count: action.payload.fileIds.length })));
  } catch (error) {
    console.log('softDeleteFilesRequestedGenerator error:', error);
    Sentry.captureException(error);
    yield put(softDeleteFilesFailed());
  }
}

function* copyFilesRequestedGenerator(action: PayloadAction<CopyFilesRequest>) {
  try {
    yield call(copyFiles, action.payload);
    yield put(copyFilesSucceeded());
  } catch (error) {
    console.log('copyFilesRequestedGenerator error:', error);
    Sentry.captureException(error);
    yield put(copyFilesFailed());
  }
}

function* retrieveFilesInfoRequestedGenerator(action: PayloadAction<RetrieveFilesInfoRequest>) {
  try {
    const response = yield call(retrieveFilesInfo, action.payload);
    yield put(retrieveFilesInfoSucceeded(response));
  } catch (error) {
    console.log('retrieveFilesInfoRequestedGenerator error:', error);
    Sentry.captureException(error);
    yield put(retrieveFilesInfoFailed());
  }
}

function* starFilesRequestedGenerator(action: PayloadAction<StarFileRequest>) {
  try {
    yield call(starFile, action.payload);
    yield put(starFileSucceeded(action.payload.fileId));
  } catch (error) {
    console.log('starFilesRequestedGenerator error:', error);
    Sentry.captureException(error);
    yield put(starFileFailed());
  }
}

function* moveFilesRequestedGenerator(action: PayloadAction<MoveFilesRequest>) {
  try {
    yield call(moveFiles, action.payload);
    yield put(moveFilesSucceeded(action.payload.sourceFileIds));
  } catch (error) {
    console.log('moveFilesRequestedGenerator error:', error);
    Sentry.captureException(error);
    yield put(moveFilesFailed());
  }
}

function* retrieveTrashBinFilesRequestedGenerator(action: PayloadAction<RetrieveTrashBinFilesRequest>) {
  try {
    const response: RetrieveTrashBinFilesResponse = yield call(retrieveTrashBinFiles, action.payload);
    yield put(retrieveTrashBinFilesSucceeded(response));
  } catch (error) {
    console.log('retrieveTrashBinFilesRequestedGenerator error:', error);
    Sentry.captureException(error);
    yield put(retrieveTrashBinFilesFailed());
  }
}

function* retrieveFilesRequestedWatcher() {
  yield takeLatest(retrieveFilesRequested.type, retrieveFilesRequestedGenerator);
}

function* retrieveTopLevelFoldersRequestedWatcher() {
  yield takeLatest(retrieveTopLevelFoldersRequested.type, retrieveTopLevelFoldersRequestedGenerator);
}

function* uploadFilesRequestedWatcher() {
  yield takeLatest(uploadFilesRequested.type, uploadFilesRequestedGenerator);
}

function* notifyBackendOnUploadWatcher() {
  yield takeLatest(notifyBackendOnUpload.type, notifyBackendOnUploadGenerator);
}

function* createFolderRequestedWatcher() {
  yield takeLatest(createFolderRequested.type, createFolderRequestedGenerator);
}

function* renameFileRequestedWatcher() {
  yield takeLatest(renameFileRequested.type, renameFileRequestedGenerator);
}

function* editDescriptionRequestedWatcher() {
  yield takeLatest(editDescriptionRequested.type, editDescriptionRequestedGenerator);
}

function* retrieveMyUploadsReadSasTokenRequestedWatcher() {
  yield takeLatest(retrieveMyUploadsReadSasTokenRequested.type, retrieveMyUploadsReadSasTokenRequestedGenerator);
}

function* retrieveMyUploadsCreateDeleteSasUriRequestedWatcher() {
  yield takeLatest(retrieveMyUploadsCreateDeleteSasUriRequested.type, retrieveMyUploadsCreateDeleteSasUriRequestedGenerator);
}

function* refreshMyUploadsCreateDeleteSasUriRequestedWatcher() {
  yield takeLatest(refreshMyUploadsCreateDeleteSasUriRequested.type, refreshMyUploadsCreateDeleteSasUriRequestedGenerator);
}

function* softDeleteFilesRequestedWatcher() {
  yield takeLatest(softDeleteFilesRequested.type, softDeleteFilesRequestedGenerator);
}

function* retrieveFilesInfoRequestedWatcher() {
  yield takeLatest(retrieveFilesInfoRequested.type, retrieveFilesInfoRequestedGenerator);
}

function* copyFilesRequestedWatcher() {
  yield takeLatest(copyFilesRequested.type, copyFilesRequestedGenerator);
}

function* starFileRequestedWatcher() {
  yield takeLatest(starFileRequested.type, starFilesRequestedGenerator);
}

function* moveFilesRequestedWatcher() {
  yield takeLatest(moveFilesRequested.type, moveFilesRequestedGenerator);
}
function* retrieveTrashBinFilesRequestedWatcher() {
  yield takeLatest(retrieveTrashBinFilesRequested.type, retrieveTrashBinFilesRequestedGenerator);
}

export function* assetsSaga() {
  yield all([
    fork(copyFilesRequestedWatcher),
    fork(retrieveFilesRequestedWatcher),
    fork(retrieveFilesInfoRequestedWatcher),
    fork(retrieveTopLevelFoldersRequestedWatcher),
    fork(uploadFilesRequestedWatcher),
    fork(notifyBackendOnUploadWatcher),
    fork(createFolderRequestedWatcher),
    fork(renameFileRequestedWatcher),
    fork(editDescriptionRequestedWatcher),
    fork(retrieveMyUploadsReadSasTokenRequestedWatcher),
    fork(retrieveMyUploadsCreateDeleteSasUriRequestedWatcher),
    fork(refreshMyUploadsCreateDeleteSasUriRequestedWatcher),
    fork(softDeleteFilesRequestedWatcher),
    fork(starFileRequestedWatcher),
    fork(moveFilesRequestedWatcher),
    fork(retrieveTrashBinFilesRequestedWatcher)
  ]);
}
