import { createPromiseClient } from '@connectrpc/connect';
import { createGrpcWebTransport } from '@connectrpc/connect-web';
import {
  AssetsService,
  CopyFilesRequest,
  CreateFolderRequest,
  EditDescriptionRequest,
  MoveFilesRequest,
  RenameFileRequest,
  RetrieveFilesInfoRequest,
  RetrieveFilesInfoResponse,
  RetrieveFilesRequest,
  RetrieveFilesResponse,
  RetrieveTopLevelFoldersRequest,
  RetrieveTopLevelFoldersResponse,
  RetrieveTrashBinFilesRequest,
  RetrieveTrashBinFilesResponse,
  SoftDeleteFilesRequest,
  StarFileRequest,
  UploadFilesRequest
} from '@thrivea/organization-client';
import { msalInstance } from '@/main';
import { tokenRequest } from '@utils/authConfig';
import { Empty } from '@bufbuild/protobuf';

export const assetsClient = createPromiseClient(
  AssetsService,
  createGrpcWebTransport({
    baseUrl: import.meta.env.VITE_APP_API_URL,
    useBinaryFormat: true
  })
);

export const retrieveFiles = async (request: RetrieveFilesRequest): Promise<RetrieveFilesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.retrieveFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveTopLevelFolders = async (request: RetrieveTopLevelFoldersRequest): Promise<RetrieveTopLevelFoldersResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.retrieveTopLevelFolders(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const uploadFiles = async (request: UploadFilesRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.uploadFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveFilesInfo = async (request: RetrieveFilesInfoRequest): Promise<RetrieveFilesInfoResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.retrieveFilesInfo(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const copyFiles = async (request: CopyFilesRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.copyFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const createFolder = async (request: CreateFolderRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.createFolder(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const editDescription = async (request: EditDescriptionRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.editDescription(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const renameFile = async (request: RenameFileRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.renameFile(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const softDeleteFiles = async (request: SoftDeleteFilesRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.softDeleteFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const moveFiles = async (request: MoveFilesRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.moveFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const starFile = async (request: StarFileRequest): Promise<Empty> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.starFile(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};

export const retrieveTrashBinFiles = async (request: RetrieveTrashBinFilesRequest): Promise<RetrieveTrashBinFilesResponse> => {
  const account = await msalInstance.acquireTokenSilent(tokenRequest);

  return await assetsClient.retrieveTrashBinFiles(request, {
    headers: new Headers({ Authorization: `Bearer ${account.accessToken}` })
  });
};
