import { Button, buttonClasses, ButtonProps, styled } from '@mui/material';

interface StyledActionsMenuButtonProps extends ButtonProps {
  isMenuOpen: boolean;
}

export const ActionsMenuButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'isMenuOpen'
})<StyledActionsMenuButtonProps>(({ theme, isMenuOpen }) => ({
  [`&.${buttonClasses.root}`]: {
    backgroundColor: isMenuOpen ? theme.palette.customTheme.contColor : 'transparent',
    color: isMenuOpen ? theme.palette.common.white : theme.palette.common.black,
    border: isMenuOpen ? undefined : '1px solid',
    borderColor: isMenuOpen ? 'transparent' : theme.palette.customTheme.contColor,
    borderRadius: '7px',
    width: 226,
    height: 45,
    padding: '16px 12px',
    lineHeight: 'normal',
    fontWeight: 700,
    boxShadow: 'none',
    [`& .${buttonClasses.endIcon}`]: {
      marginTop: '2px',
      transform: isMenuOpen ? 'rotate(180deg)' : undefined,
      transition: theme.transitions.create('transform', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      '& path': {
        fill: isMenuOpen ? theme.palette.common.white : theme.palette.common.black
      }
    },
    '&:hover': {
      backgroundColor: isMenuOpen ? theme.palette.primary.dark : theme.palette.secondary.dark,
      borderColor: 'transparent'
    }
  }
}));
