const ARCHIVE_MIME_TYPES = [
  'application/zip',
  'application/x-rar-compressed',
  'application/x-7z-compressed',
  'application/x-tar',
  'application/gzip',
  'application/x-bzip',
  'application/x-bzip2',
  'application/vnd.rar',
  'application/x-apple-diskimage', // For .dmg (macOS disk image)
  'application/x-iso9660-image' // For .iso (disk image)
];

export const isArchiveMimeType = (mimeType: string) => ARCHIVE_MIME_TYPES.includes(mimeType);
