const IMAGE_MIME_TYPES = [
  'image/jpeg',
  'image/png',
  'image/gif',
  'image/webp',
  'image/svg+xml',
  'image/bmp',
  'image/tiff',
  'image/x-icon',
  'image/vnd.microsoft.icon',
  'image/heic',
  'image/heif'
];

export const IMAGE_FILE_TYPES = ['jpeg', 'jpg', 'gif', 'png', 'bmp', 'svg', 'webp'];

const IMAGE_EXTENSION_PATTERN = /\.(jpeg|jpg|gif|png|bmp|svg|webp)$/i;
const IMAGE_MIME_TYPES_PATTERN = /^image\/(jpeg|jpg|gif|png|bmp|svg\+xml|webp)$/i;

export const isImageUrl = (url: string) => IMAGE_EXTENSION_PATTERN.test(new URL(url).pathname);
export const isImage = (filename: string) => IMAGE_MIME_TYPES_PATTERN.test(filename);

export const isImageMimeType = (mimeType: string) => IMAGE_MIME_TYPES.includes(mimeType);
