import { styled, Select, SelectProps, formHelperTextClasses, inputBaseClasses, paperClasses, svgIconClasses, alpha } from '@mui/material';

export const TableRoleSelect  = styled(Select)<SelectProps>(({ theme }) => ({
  width: '100%',

  'label + &': {
    marginTop: 0
  },

  [`& .${inputBaseClasses.input}`]: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    fontSize: 14,
    width: '100%',
    padding: '10px 12px',
    textAlign: 'left',
    WebkitAppearance: 'none',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow'])
  },

  [`& .${inputBaseClasses.root}.Mui-disabled`]: {
    backgroundColor: 'transparent'
  },

  'div[role="combobox"]': {
    padding: '15px 12px 10px',
    backgroundColor: 'transparent',
    textFillColor: theme.palette.common.black,
    border: '1px solid',
    borderColor: 'transparent',
    transition: theme.transitions.create(['border-color']),
    fontSize: 16
  },

  'div[role="combobox"].Mui-disabled': {
    backgroundColor: 'transparent',
    borderColor: 'transparent'
  },

  'div[aria-expanded="true"]': {
    [`& ~ .${svgIconClasses.root}`]: {
      transform: 'translateY(-45%) rotate(180deg)'
    }
  },

  [`& .${inputBaseClasses.root}.Mui-focused`]: {
    '& input': {
      backgroundColor: theme.palette.common.white
    }
  },

  [`& .${formHelperTextClasses.root}`]: {
    textAlign: 'right'
  },

  [`& .${inputBaseClasses.inputMultiline}`]: {
    padding: 0
  },

  [`& .${paperClasses.root}`]: {
    backgroundColor: theme.palette.common.white
  },

  [`& .${svgIconClasses.root}`]: {
    userSelect: 'none',
    width: 12,
    height: 7,
    display: 'inline-block',
    flexShrink: 0,
    fontSize: '1.5rem',
    position: 'absolute',
    right: '15px',
    top: '50%',
    transform: 'translateY(-50%) rotate(0deg)',
    pointerEvents: 'none',
    color: alpha(theme.palette.common.black, 0.54),
    transition: theme.transitions.create('transform', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },

  '& fieldset': {
    border: 0
  }
}));
