import { styled, CardContent, CardContentProps } from '@mui/material';

export const GroupCardContent = styled(CardContent)<CardContentProps>(({ theme }) => ({
  padding: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 10,
  textAlign: 'center'
}));
