import { Chip, chipClasses, ChipProps, styled } from '@mui/material';

export const DrawerChip = styled(Chip)<ChipProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.selectLabel,
  padding: '12px 16px',
  borderRadius: '8px',
  fontSize: 16,
  fontWeight: 700,
  height: 35,
  [`& .${chipClasses.label}`]: {
    padding: 0
  }
}));
