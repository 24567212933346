import { Button, ButtonProps, styled } from '@mui/material';

export const BuilderButton = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: '12px 32px',
  backgroundColor: theme.palette.customTheme.contColor,
  color: theme.palette.customTheme.contBgr,
  width: 183,
  marginTop: '30px',
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: theme.palette.customTheme.focusItem
  }
}));
