import { styled, Button, ButtonProps } from '@mui/material';

export const SubmitPostButton = styled(Button)<ButtonProps>(({ theme }) => ({
  padding: '12px 32px',
  backgroundColor: theme.palette.customTheme.drawerBackground,
  fontSize: 16,
  fontWeight: 700,
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: theme.palette.customTheme.contColor,
    color: theme.palette.common.white
  }
}));
