import { styled, ListItemButtonProps, listItemButtonClasses, ListItemButton, typographyClasses } from '@mui/material';

export const RecordListItemButton = styled(ListItemButton)<ListItemButtonProps>(({ theme }) => ({
  [`&.${listItemButtonClasses.root}`]: {
    padding: '2px 16px',
    position: 'relative',
    borderRadius: '7px',
    margin: '3px 0',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.customTheme.headerColorSub,
    transition: theme.transitions.create('border-left', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.standard
    }),
    '&::before': {
      content: 'none'
    }
  },
  [`&.${listItemButtonClasses.selected}`]: {
    backgroundColor: theme.palette.secondary.dark,
    [`& .${typographyClasses.root}`]: {
      fontWeight: 700
    }
  },
  [`&.${listItemButtonClasses.focusVisible}`]: {
    backgroundColor: theme.palette.secondary.dark
  },
  '&:hover': {
    borderLeft: `8px solid ${theme.palette.primary.dark}`
  }
}));
