import { Button, ButtonProps, styled } from '@mui/material';

export const AddSectionButton = styled(Button)<ButtonProps>(({ theme, disabled }) => ({
  padding: '12px 32px',
  backgroundColor: disabled ? theme.palette.customTheme.zebraFreezeOne : theme.palette.customTheme.contColor,
  color: disabled ? theme.palette.common.white : theme.palette.customTheme.contBgr,
  width: 192,
  marginTop: '30px',
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: theme.palette.customTheme.focusItem
  }
}));
