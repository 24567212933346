import React from 'react';
import { SvgIconProps } from '@mui/material/SvgIcon';
import { StyledFilledInput } from '@/shared';
import { ControllerFieldState, Noop } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

interface SocialLinkInputProps {
  field: {
    value: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur: Noop;
  };
  isEditable: boolean;
  fieldState: ControllerFieldState;
  predefinedPart: string;
  label: string;
  icon?: React.ReactElement<SvgIconProps>;
}

export const SocialLinkInput: React.FC<SocialLinkInputProps> = ({
  field,
  isEditable,
  fieldState = { error: null, isTouched: false, isDirty: false },
  predefinedPart,
  label,
  icon
}) => {
  const { t } = useTranslation(['common']);
  const { value } = field;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    field.onChange({
      ...event,
      target: {
        ...event.target,
        value: newValue.startsWith(predefinedPart) ? newValue : predefinedPart + newValue
      }
    });
  };

  return (
    <StyledFilledInput
      id={`social_${label.toLowerCase()}`}
      label={label}
      placeholder={`{${t('user_handle', { ns: 'common' })}}`}
      disabled={!isEditable}
      error={!!fieldState.error}
      helperText={fieldState.error?.message}
      onChange={handleOnChange}
      onBlur={field.onBlur}
      value={value.startsWith(predefinedPart) ? value.slice(predefinedPart.length) : value}
      InputProps={{
        startAdornment: icon,
        readOnly: !isEditable
      }}
    />
  );
};
