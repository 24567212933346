import { filledInputClasses, formControlClasses, styled } from '@mui/material';
import { StyledFilledInput } from '@/shared';

export const AutocompleteInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ width }) => ({
  [`& .${filledInputClasses.root}`]: {
    width,
    height: 50,
    border: 0,
    borderBottom: 0,
    borderColor: 'transparent',
    '&::before': {
      content: 'none'
    }
  },
  [`&.${formControlClasses.root}`]: {
    width,
    [`& .${filledInputClasses.focused}`]: {
      border: 0,
      borderBottom: 0,
      borderRadius: 0,
      borderTopLeftRadius: 10,
      borderTopRightRadius: 10
    }
  }
}));
