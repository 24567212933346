import { Typography, Stack, Box, IconButton, CircularProgress, ListItem, Autocomplete } from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  RowCenterStack,
  VisuallyHidden,
  EditIcon,
  CancelEditIcon,
  ChevronDownIcon,
  StyledFormWrapper,
  StyledFormHeader,
  StyledFormSubmitButton,
  CancelButton,
  StyledFilledInput,
  ActionStatus
} from '@/shared';
import { ForwardedRef, forwardRef, useEffect, useId, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useAppSelector } from '@app/hooks';
import { Emergency, EmergencyContactRelation, UpdateEmergencyRequest } from '@thrivea/organization-client';
import { createEmergencyCategorySchema, selectEmergencyCategory, selectProfileNotification, updateEmergencyRequested } from '@features/employee-profile';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { camelCase, snakeCase } from 'lodash';
import { zodResolver } from '@hookform/resolvers/zod';
import { AllowedTo } from 'react-abac';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';

interface EmergencyCategoryProps {
  ref: ForwardedRef<HTMLDivElement>;
  employeeId: string;
}

export const EmergencyCategory = forwardRef<HTMLDivElement, EmergencyCategoryProps>(({ employeeId }, ref) => {
  const id = useId();
  const { t } = useTranslation(['common', 'employeeProfile', 'emergency_contact_relation']);
  const name = t('emergency_category_title', { ns: 'employee_profile' });
  const { status, category } = useAppSelector(selectProfileNotification);
  const isPendingAndMatchingName = status === ActionStatus.Pending && category === camelCase(name);
  const [isEditable, setIsEditable] = useState(false);
  const dispatch = useDispatch();
  const emergencyCategory = useAppSelector(selectEmergencyCategory);
  const emergencyCategorySchema = useMemo(() => createEmergencyCategorySchema(t), [t]);

  const {
    control,
    reset,
    handleSubmit,
    formState: { dirtyFields, errors }
  } = useForm<Emergency>({
    mode: 'all',
    resolver: zodResolver(emergencyCategorySchema),
    defaultValues: {
      emergencyContactAddress: emergencyCategory.emergencyContactAddress,
      emergencyContactCityOfResidence: emergencyCategory.emergencyContactCityOfResidence,
      emergencyContactEmailAddress: emergencyCategory.emergencyContactEmailAddress,
      emergencyContactCountryOfResidence: emergencyCategory.emergencyContactCountryOfResidence,
      emergencyContactFirstName: emergencyCategory.emergencyContactFirstName,
      emergencyContactLandlinePhoneNumber: emergencyCategory.emergencyContactLandlinePhoneNumber,
      emergencyContactLastName: emergencyCategory.emergencyContactLastName,
      emergencyContactMiddleName: emergencyCategory.emergencyContactMiddleName,
      emergencyContactMobilePhoneNumber: emergencyCategory.emergencyContactMobilePhoneNumber,
      emergencyContactPostCode: emergencyCategory.emergencyContactPostCode,
      emergencyContactRelation: emergencyCategory.emergencyContactRelation
    }
  });

  const onSubmit = (data: Emergency) => {
    setIsEditable(false);
    dispatch(updateEmergencyRequested({ ...data, employeeId } as UpdateEmergencyRequest));
  };

  const handleToggleEditable = () => {
    setIsEditable(!isEditable);
  };

  const emergencyContactRelations = Object.keys(EmergencyContactRelation)
    .filter((key) => !isNaN(Number(key)))
    .slice(1) // exclude unspecified option
    .map((key) => ({
      id: parseInt(key, 10) - 1, //deduct by 1 as we are removing first option
      // removes underscores and sets only the first letter to uppercase
      name: EmergencyContactRelation[Number(key)].toLowerCase()
    }));

  useEffect(() => {
    reset({
      emergencyContactAddress: emergencyCategory.emergencyContactAddress,
      emergencyContactCityOfResidence: emergencyCategory.emergencyContactCityOfResidence,
      emergencyContactEmailAddress: emergencyCategory.emergencyContactEmailAddress,
      emergencyContactCountryOfResidence: emergencyCategory.emergencyContactCountryOfResidence,
      emergencyContactFirstName: emergencyCategory.emergencyContactFirstName,
      emergencyContactLandlinePhoneNumber: emergencyCategory.emergencyContactLandlinePhoneNumber,
      emergencyContactLastName: emergencyCategory.emergencyContactLastName,
      emergencyContactMiddleName: emergencyCategory.emergencyContactMiddleName,
      emergencyContactMobilePhoneNumber: emergencyCategory.emergencyContactMobilePhoneNumber,
      emergencyContactPostCode: emergencyCategory.emergencyContactPostCode,
      emergencyContactRelation: emergencyCategory.emergencyContactRelation
    });
  }, [emergencyCategory]);

  const handleCloseEditable = () => {
    reset({
      emergencyContactAddress: emergencyCategory.emergencyContactAddress,
      emergencyContactCityOfResidence: emergencyCategory.emergencyContactCityOfResidence,
      emergencyContactEmailAddress: emergencyCategory.emergencyContactEmailAddress,
      emergencyContactCountryOfResidence: emergencyCategory.emergencyContactCountryOfResidence,
      emergencyContactFirstName: emergencyCategory.emergencyContactFirstName,
      emergencyContactLandlinePhoneNumber: emergencyCategory.emergencyContactLandlinePhoneNumber,
      emergencyContactLastName: emergencyCategory.emergencyContactLastName,
      emergencyContactMiddleName: emergencyCategory.emergencyContactMiddleName,
      emergencyContactMobilePhoneNumber: emergencyCategory.emergencyContactMobilePhoneNumber,
      emergencyContactPostCode: emergencyCategory.emergencyContactPostCode,
      emergencyContactRelation: emergencyCategory.emergencyContactRelation
    });
    setIsEditable(false);
  };

  return (
    <StyledFormWrapper isEditable={isEditable} id={snakeCase(name)} ref={ref}>
      <Box
        component="form"
        name={name}
        onSubmit={handleSubmit(onSubmit)}
        sx={{
          backgroundColor: 'transparent'
        }}
      >
        <Stack>
          <StyledFormHeader isEditable={isEditable} className="Mui-ProfileFiledHeader">
            <RowCenterStack gap={1}>
              {isPendingAndMatchingName && <CircularProgress size={24} thickness={4} />}
              <Typography component="h3" variant="h5" fontWeight={700}>
                {name}
              </Typography>
            </RowCenterStack>
            <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'emergency' } as ProfileCategoryInfo}>
              <IconButton
                onClick={handleToggleEditable}
                sx={{
                  opacity: '0',
                  display: isEditable ? 'none' : 'inline-flex'
                }}
              >
                <VisuallyHidden>Edit {name}</VisuallyHidden>
                <EditIcon />
              </IconButton>
            </AllowedTo>
            <RowCenterStack
              gap={2}
              sx={{
                display: isEditable ? 'flex' : 'none'
              }}
            >
              <CancelButton variant="contained" onClick={handleCloseEditable} startIcon={<CancelEditIcon />}>
                {t('cancel', { ns: 'common' })}
              </CancelButton>
              <StyledFormSubmitButton
                type="submit"
                disabled={Object.values(dirtyFields).length === 0 || Object.values(errors).length !== 0}
                variant="contained"
              >
                {t('done', { ns: 'common' })}
              </StyledFormSubmitButton>
            </RowCenterStack>
          </StyledFormHeader>
          <Grid
            container
            spacing={2}
            sx={{
              padding: 2
            }}
          >
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="emergencyContactFirstName"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="emergencyContactMiddleName"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="emergencyContactLastName"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="emergencyContactLandlinePhoneNumber"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="emergencyContactMobilePhoneNumber"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="emergencyContactEmailAddress"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="emergencyContactAddress"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="emergencyContactCityOfResidence"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="emergencyContactPostCode"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="emergencyContactCountryOfResidence"
                control={control}
                render={({ field, fieldState }) => (
                  <StyledFilledInput
                    {...field}
                    id={id + field.name}
                    label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                    disabled={!isEditable}
                    error={!!fieldState.error}
                    helperText={fieldState.error?.message}
                  />
                )}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
              <Controller
                name="emergencyContactRelation"
                control={control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    value={emergencyContactRelations.find((option) => option.id === field.value) || null}
                    popupIcon={<ChevronDownIcon />}
                    disabled={!isEditable}
                    disableClearable={field.value !== null}
                    options={emergencyContactRelations}
                    getOptionLabel={(option) => t(option.name, { ns: 'emergency_contact_relation' })}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(_, value) => field.onChange(value && value.id)}
                    renderInput={(params) => (
                      <StyledFilledInput
                        {...params}
                        id={id + field.name}
                        label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                        disabled={!isEditable}
                        error={!!fieldState.error}
                        helperText={fieldState.error?.message}
                        onBlur={field.onBlur}
                      />
                    )}
                    renderOption={(props, option) => (
                      <ListItem {...props} key={crypto.randomUUID()}>
                        {t(option.name, { ns: 'emergency_contact_relation' })}
                      </ListItem>
                    )}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Stack>
      </Box>
    </StyledFormWrapper>
  );
});
