import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/store';
import {
  CalendarItem,
  EmployeeItem,
  EmployeeProfile,
  Employment,
  EmploymentEvent,
  EmploymentType,
  Ethnicity,
  Gender,
  JobCategory,
  Payroll,
  PayrollEvent,
  Prefix,
  Pronouns,
  UpdateEeoRequest,
  WorkEligibility,
  WorkEligibilityItem,
  WorkEligibilityType,
  WorkingPattern,
  Eeo,
  WorkEvent,
  AddressEvent,
  ChildItem,
  Home,
  Address,
  Work,
  UserData,
  UpdateEmergencyRequest,
  Emergency,
  UpdatePayrollRequest,
  UpdateEmploymentRequest,
  UpdateWorkEligibilityRequest,
  UpdateFinancialRequest,
  Financial,
  UpdateHomeRequest,
  UpdateBasicInfoRequest,
  BasicInfo,
  PersonalContactDetails,
  UpdatePersonalContactDetailsRequest,
  Personal,
  UpdatePersonalRequest,
  Identification,
  UpdateIdentificationRequest,
  UpdateWorkRequest,
  WorkContactDetails,
  UpdateWorkContactDetailsRequest,
  UpdateAddressRequest,
  About,
  UpdateAboutRequest,
  Site,
  Department,
  SuperPower,
  Hobby,
  FoodPreference,
  UpdateProfileMediaRequest,
  ProfileMedia,
  SiteStatus
} from '@thrivea/organization-client';
import { ActionStatus, MultiSizeImageBlobs } from '@/shared';
import { LoadEmployeeProfileResults } from '@features/employee-profile';
import { SasToken } from '@thrivea/auth-client';

interface EmployeeProfileState {
  locale: string;
  userStatus: 'idle' | 'loading' | 'failed';
  updateUserStatus: 'inactive' | 'idle' | 'loading' | 'failed';
  entities: {
    employeeProfile: EmployeeProfile;
    employeeItems: {
      byId: {
        [id: string]: EmployeeItem;
      };
      allIds: string[];
    };
    prefixes: Prefix[];
    pronounces: Pronouns[];
    genders: Gender[];
    workEligibilityTypes: WorkEligibilityType[];
    employmentTypes: EmploymentType[];
    ethnicities: Ethnicity[];
    jobCategories: JobCategory[];
    workingPatterns: WorkingPattern[];
    calendarItems: CalendarItem[];
    organizationSites: Site[];
    organizationDepartments: Department[];
    superpowers: SuperPower[];
    hobbies: Hobby[];
    foodPreferences: FoodPreference[];
  };
  ui: {
    isNotificationVisible: boolean;
    profileNotification: {
      status: ActionStatus;
      i18n_status_label: string;
      firstName: string;
      i18n_category_label: string;
      category?: string;
    };
    employeeProfileStatus: ActionStatus;
    eeoCategoryStatus: ActionStatus;
    emergencyCategoryStatus: ActionStatus;
    payrollCategoryStatus: ActionStatus;
    employmentCategoryStatus: ActionStatus;
    workEligibilityCategoryStatus: ActionStatus;
    financialCategoryStatus: ActionStatus;
    homeCategoryStatus: ActionStatus;
    basicInfoStatus: ActionStatus;
    personalCategoryStatus: ActionStatus;
    personalContactDetailsStatus: ActionStatus;
    identificationStatus: ActionStatus;
    workStatus: ActionStatus;
    workContactDetailsStatus: ActionStatus;
    addressStatus: ActionStatus;
    aboutStatus: ActionStatus;
  };
  newWorkEvents: WorkEvent[];
  newAddressEvent: AddressEvent[];
  newChildItems: ChildItem[];
  newAddressEvents: AddressEvent[];
  newChildItem: ChildItem[];
  newPayrollEvents: PayrollEvent[];
  newEmploymentEvents: EmploymentEvent[];
  newWorkEligibilityItems: WorkEligibilityItem[];
  tempEEOState?: Eeo;
  eeoResetState: boolean;
  employeeProfileAndCoverReadSasToken: string;
  employeeProfilePictureFile?: MultiSizeImageBlobs;
  employeeProfilePictureFileMultiSize: MultiSizeImageBlobs | undefined | null;
  employeeProfileCoverPictureMultiSize: MultiSizeImageBlobs | undefined | null;
  employeeProfileCoverPictureFile?: MultiSizeImageBlobs;
}

const initialState: EmployeeProfileState = {
  locale: 'en',
  userStatus: 'idle',
  updateUserStatus: 'inactive',
  entities: {
    employeeProfile: {
      financial: new Financial(),
      basicInfo: new BasicInfo(),
      profileMedia: new ProfileMedia(),
      personal: new Personal(),
      personalContactDetails: new PersonalContactDetails(),
      identification: new Identification(),
      work: new Work({ workEvents: [] }),
      workContactDetails: new WorkContactDetails(),
      address: new Address({ addressEvents: [] }),
      home: new Home({ childItems: [] }),
      about: new About(),
      payroll: new Payroll({ payrollEvents: [] }),
      employment: new Employment({ employmentEvents: [] }),
      workEligibility: new WorkEligibility({ workEligibilityItems: [] }),
      emergency: new Emergency(),
      userData: new UserData(),
      eeo: new Eeo()
    } as EmployeeProfile,
    employeeItems: {
      byId: {},
      allIds: []
    },
    prefixes: [],
    pronounces: [],
    genders: [],
    workEligibilityTypes: [],
    employmentTypes: [],
    ethnicities: [],
    jobCategories: [],
    workingPatterns: [],
    calendarItems: [],
    organizationSites: [],
    organizationDepartments: [],
    superpowers: [],
    hobbies: [],
    foodPreferences: []
  },
  ui: {
    isNotificationVisible: false,
    profileNotification: {
      status: ActionStatus.Idle,
      i18n_status_label: '',
      firstName: '',
      i18n_category_label: '',
      category: ''
    },
    basicInfoStatus: ActionStatus.Idle,
    personalCategoryStatus: ActionStatus.Idle,
    personalContactDetailsStatus: ActionStatus.Idle,
    identificationStatus: ActionStatus.Idle,
    workStatus: ActionStatus.Idle,
    workContactDetailsStatus: ActionStatus.Idle,
    addressStatus: ActionStatus.Idle,
    aboutStatus: ActionStatus.Idle,
    employeeProfileStatus: ActionStatus.Idle,
    eeoCategoryStatus: ActionStatus.Idle,
    emergencyCategoryStatus: ActionStatus.Idle,
    employmentCategoryStatus: ActionStatus.Idle,
    financialCategoryStatus: ActionStatus.Idle,
    payrollCategoryStatus: ActionStatus.Idle,
    workEligibilityCategoryStatus: ActionStatus.Idle,
    homeCategoryStatus: ActionStatus.Idle
  },
  newWorkEvents: [],
  newAddressEvent: [],
  newChildItems: [],
  newAddressEvents: [],
  newChildItem: [],
  newPayrollEvents: [],
  newEmploymentEvents: [],
  newWorkEligibilityItems: [],
  eeoResetState: false,
  employeeProfileAndCoverReadSasToken: '',
  employeeProfilePictureFileMultiSize: undefined,
  employeeProfileCoverPictureMultiSize: undefined
};

const employeeProfileSlice = createSlice({
  name: 'employee-profile',
  initialState,
  reducers: {
    localize: (state, action: PayloadAction<string>) => {
      state.locale = action.payload;
    },
    loadEmployeeProfileRequested: (state, action: PayloadAction<string>) => {
      state.ui.employeeProfileStatus = ActionStatus.Pending;
    },
    loadEmployeeProfileSucceeded: (state, action: PayloadAction<LoadEmployeeProfileResults>) => {
      state.entities.employeeProfile = action.payload.employeeProfile;

      state.entities.employeeItems.allIds = [];
      state.entities.employeeItems.byId = {};
      for (const ei of action.payload.employeeItemsResponse.employeeItems) {
        state.entities.employeeItems.byId[ei.employeeId] = ei;
        state.entities.employeeItems.allIds.push(ei.employeeId);
      }

      state.entities.prefixes = action.payload.prefixesResponse.prefixes;
      state.entities.pronounces = action.payload.pronounsResponse.pronouns;
      state.entities.genders = action.payload.gendersResponse.genders;
      state.entities.workEligibilityTypes = action.payload.workEligibilityTypesResponse.workEligibilityTypes;
      state.entities.ethnicities = action.payload.ethnicitiesResponse.ethnicity;
      state.entities.jobCategories = action.payload.jobCategoriesResponse.jobCategories;
      state.entities.workingPatterns = action.payload.workingPatternsResponse.workingPatterns;
      state.entities.employmentTypes = action.payload.employmentTypeResponse.employmentTypes;
      state.entities.calendarItems = action.payload.calendarItemsResponse.calendarItems;
      state.entities.organizationSites = action.payload.organizationSitesResponse.sites;
      state.entities.organizationDepartments = action.payload.organizationDepartmentsResponse.departments;
      state.entities.superpowers = action.payload.superPowersResponse.superPowers;
      state.entities.hobbies = action.payload.hobbiesResponse.hobbies;
      state.entities.foodPreferences = action.payload.foodPreferencesResponse.foodPreferences;

      state.ui.employeeProfileStatus = ActionStatus.Idle;
    },
    loadEmployeeProfileFailed: (state) => {},
    updateHomeRequested: (state, action: PayloadAction<UpdateHomeRequest>) => {
      state.ui.profileNotification = {
        status: ActionStatus.Pending,
        i18n_status_label: 'update_requested',
        firstName: '',
        i18n_category_label: '',
        category: 'home'
      };
    },
    updateHomeSucceeded: (state, action: PayloadAction<UpdateHomeRequest>) => {
      state.entities.employeeProfile.home = action.payload as Home;
      state.newChildItems = [];
      state.ui.profileNotification = {
        status: ActionStatus.Idle,
        i18n_status_label: 'update_succeeded',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'home'
      };
      state.ui.isNotificationVisible = true;
    },
    updateHomeFailed: (state) => {
      state.entities.employeeProfile.home = { ...state.entities.employeeProfile.home } as Home;
      state.entities.employeeProfile.home!.childItems = state.entities.employeeProfile.home!.childItems.splice(state.newChildItems.length);
      state.newChildItems = [];
      state.ui.profileNotification = {
        status: ActionStatus.Failed,
        i18n_status_label: 'update_failed',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'home'
      };
      state.ui.isNotificationVisible = true;
    },
    addNewChildItem: (state, action: PayloadAction<ChildItem>) => {
      state.newChildItems.push(action.payload);
      state.entities.employeeProfile.home!.childItems.unshift(action.payload);
    },
    removeChildItems: (state) => {
      state.entities.employeeProfile.home!.childItems = state.entities.employeeProfile.home!.childItems.splice(state.newChildItems.length);
      state.newChildItems = [];
    },
    updateFinancialRequested: (state, action: PayloadAction<UpdateFinancialRequest>) => {
      state.ui.profileNotification = {
        status: ActionStatus.Pending,
        i18n_status_label: 'update_requested',
        firstName: '',
        i18n_category_label: '',
        category: 'financial'
      };
    },
    updateFinancialSucceeded: (state, action: PayloadAction<UpdateFinancialRequest>) => {
      state.entities.employeeProfile.financial = action.payload as Financial;
      state.ui.profileNotification = {
        status: ActionStatus.Idle,
        i18n_status_label: 'update_succeeded',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'financial'
      };
      state.ui.isNotificationVisible = true;
    },
    updateFinancialFailed: (state) => {
      state.entities.employeeProfile.financial = { ...state.entities.employeeProfile.financial } as Financial;
      state.ui.profileNotification = {
        status: ActionStatus.Failed,
        i18n_status_label: 'update_failed',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'financial'
      };
      state.ui.isNotificationVisible = true;
    },
    updateWorkEligibilityRequested: (state, action: PayloadAction<UpdateWorkEligibilityRequest>) => {
      state.ui.profileNotification = {
        status: ActionStatus.Pending,
        i18n_status_label: 'update_requested',
        firstName: '',
        i18n_category_label: '',
        category: 'workEligibility'
      };
    },
    updateWorkEligibilitySucceeded: (state, action: PayloadAction<UpdateWorkEligibilityRequest>) => {
      state.newWorkEligibilityItems = [];
      state.entities.employeeProfile.workEligibility = action.payload as WorkEligibility;
      state.ui.profileNotification = {
        status: ActionStatus.Idle,
        i18n_status_label: 'update_succeeded',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'work_eligibility'
      };
      state.ui.isNotificationVisible = true;
    },
    updateWorkEligibilityFailed: (state) => {
      state.entities.employeeProfile.workEligibility!.workEligibilityItems = state.entities.employeeProfile.workEligibility!.workEligibilityItems.slice(
        state.newWorkEligibilityItems.length
      );
      state.newWorkEligibilityItems = [];
      state.ui.profileNotification = {
        status: ActionStatus.Failed,
        i18n_status_label: 'update_failed',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'work_eligibility'
      };
      state.ui.isNotificationVisible = true;
    },
    addNewWorkEligibilityItem: (state, action: PayloadAction<WorkEligibilityItem>) => {
      state.newWorkEligibilityItems.push(action.payload);
      state.entities.employeeProfile.workEligibility!.workEligibilityItems.unshift(action.payload);
    },
    removeNewWorkEligibilityItems: (state) => {
      state.entities.employeeProfile.workEligibility!.workEligibilityItems = state.entities.employeeProfile.workEligibility!.workEligibilityItems.slice(
        state.newWorkEligibilityItems.length
      );
      state.newWorkEligibilityItems = [];
    },
    updateEmploymentRequested: (state, action: PayloadAction<UpdateEmploymentRequest>) => {
      state.ui.profileNotification = {
        status: ActionStatus.Pending,
        i18n_status_label: 'update_requested',
        firstName: '',
        i18n_category_label: '',
        category: 'employment'
      };
    },
    updateEmploymentSucceeded: (state, action: PayloadAction<UpdateEmploymentRequest>) => {
      state.newEmploymentEvents = [];
      state.entities.employeeProfile.employment = action.payload;
      state.ui.profileNotification = {
        status: ActionStatus.Idle,
        i18n_status_label: 'update_succeeded',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'employment'
      };
      state.ui.isNotificationVisible = true;
    },
    updateEmploymentFailed: (state) => {
      state.entities.employeeProfile.employment!.employmentEvents = state.entities.employeeProfile.employment!.employmentEvents.slice(
        state.newEmploymentEvents.length
      );
      state.newEmploymentEvents = [];
      state.ui.profileNotification = {
        status: ActionStatus.Failed,
        i18n_status_label: 'update_failed',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'employment'
      };
      state.ui.isNotificationVisible = true;
    },
    addNewEmploymentEvent: (state, action: PayloadAction<EmploymentEvent>) => {
      state.newEmploymentEvents.push(action.payload);
      state.entities.employeeProfile.employment!.employmentEvents.unshift(action.payload);
    },
    removeNewEmploymentEvents: (state) => {
      state.entities.employeeProfile.employment!.employmentEvents = state.entities.employeeProfile.employment!.employmentEvents.slice(
        state.newEmploymentEvents.length
      );
      state.newEmploymentEvents = [];
    },
    updatePayrollRequested: (state, action: PayloadAction<UpdatePayrollRequest>) => {
      state.ui.profileNotification = {
        status: ActionStatus.Pending,
        i18n_status_label: 'update_requested',
        firstName: '',
        i18n_category_label: '',
        category: 'payroll'
      };
    },
    updatePayrollSucceeded: (state, action: PayloadAction<UpdatePayrollRequest>) => {
      state.entities.employeeProfile.payroll = action.payload as Payroll;
      state.entities.employeeProfile.payroll!.payrollEvents ??= [];
      state.newPayrollEvents = [];
      state.ui.profileNotification = {
        status: ActionStatus.Idle,
        i18n_status_label: 'update_succeeded',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'payroll'
      };
      state.ui.isNotificationVisible = true;
    },
    updatePayrollFailed: (state) => {
      state.entities.employeeProfile.payroll = { ...state.entities.employeeProfile.payroll } as Payroll;
      state.entities.employeeProfile.payroll.payrollEvents = state.entities.employeeProfile.payroll.payrollEvents.slice(state.newPayrollEvents.length);
      state.newPayrollEvents = [];
      state.ui.profileNotification = {
        status: ActionStatus.Failed,
        i18n_status_label: 'update_failed',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'payroll'
      };
      state.ui.isNotificationVisible = true;
    },
    addNewPayrollEvent: (state, action: PayloadAction<PayrollEvent>) => {
      state.newPayrollEvents.push({
        baseSalary: action.payload.baseSalary,
        currencyCode: action.payload.currencyCode,
        effectiveDate: action.payload.effectiveDate,
        reason: action.payload.reason,
        salaryPayFrequency: action.payload.salaryPayFrequency,
        salaryPayPeriod: action.payload.salaryPayPeriod
      } as PayrollEvent);
      state.entities.employeeProfile.payroll!.payrollEvents.unshift({
        baseSalary: action.payload.baseSalary,
        currencyCode: action.payload.currencyCode,
        effectiveDate: action.payload.effectiveDate,
        reason: action.payload.reason,
        salaryPayFrequency: action.payload.salaryPayFrequency,
        salaryPayPeriod: action.payload.salaryPayPeriod
      } as PayrollEvent);
    },
    removeNewPayrollEvents: (state) => {
      state.entities.employeeProfile.payroll!.payrollEvents = state.entities.employeeProfile.payroll!.payrollEvents.slice(state.newPayrollEvents.length);
      state.newPayrollEvents = [];
    },
    updateEmergencyRequested: (state, action: PayloadAction<UpdateEmergencyRequest>) => {
      state.ui.profileNotification = {
        status: ActionStatus.Pending,
        i18n_status_label: 'update_requested',
        firstName: '',
        i18n_category_label: '',
        category: 'emergency'
      };
    },
    updateEmergencySucceeded: (state, action: PayloadAction<UpdateEmergencyRequest>) => {
      state.entities.employeeProfile.emergency = action.payload as Emergency;
      state.ui.profileNotification = {
        status: ActionStatus.Idle,
        i18n_status_label: 'update_succeeded',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'emergency'
      };
      state.ui.isNotificationVisible = true;
    },
    updateEmergencyFailed: (state) => {
      state.entities.employeeProfile.emergency = { ...state.entities.employeeProfile.emergency } as Emergency;
      state.ui.profileNotification = {
        status: ActionStatus.Failed,
        i18n_status_label: 'update_failed',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'emergency'
      };
      state.ui.isNotificationVisible = true;
    },
    updateEEORequested: (state, action: PayloadAction<UpdateEeoRequest>) => {
      state.ui.profileNotification = {
        status: ActionStatus.Pending,
        i18n_status_label: 'update_requested',
        firstName: '',
        i18n_category_label: '',
        category: 'eeo'
      };
    },
    updateEEOSucceeded: (state, action: PayloadAction<UpdateEeoRequest>) => {
      state.entities.employeeProfile.eeo = action.payload as Eeo;
      state.ui.profileNotification = {
        status: ActionStatus.Idle,
        i18n_status_label: 'update_succeeded',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'eeo'
      };
      state.ui.isNotificationVisible = true;
    },
    updateEEOFailed: (state) => {
      state.entities.employeeProfile.eeo = { ...state.entities.employeeProfile.eeo } as Eeo;
      state.ui.profileNotification = {
        status: ActionStatus.Failed,
        i18n_status_label: 'update_failed',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'eeo'
      };
      state.ui.isNotificationVisible = true;
    },
    setEeoResetStateToFalse: (state) => {
      state.eeoResetState = false;
    },
    addNewWorkEvent: (state, action: PayloadAction<WorkEvent>) => {
      state.newWorkEvents.push(action.payload);
      state.entities.employeeProfile.work!.workEvents.unshift(action.payload);
    },
    removeNewWorkEvent: (state) => {
      state.entities.employeeProfile.work!.workEvents = state.entities.employeeProfile.work!.workEvents.splice(state.newWorkEvents.length);
      state.newWorkEvents = [];
    },
    addNewAddressEvent: (state, action: PayloadAction<AddressEvent>) => {
      state.newAddressEvents.push(action.payload);
      state.entities.employeeProfile.address?.addressEvents.unshift(action.payload);
    },
    removeNewAddressEvents: (state) => {
      state.entities.employeeProfile.address!.addressEvents = state.entities.employeeProfile.address!.addressEvents.splice(state.newAddressEvents.length);
      state.newAddressEvents = [];
    },
    updateBasicInfoRequested: (state, action: PayloadAction<UpdateBasicInfoRequest>) => {
      state.ui.profileNotification = {
        status: ActionStatus.Pending,
        i18n_status_label: 'update_requested',
        firstName: '',
        i18n_category_label: '',
        category: 'basicInfo'
      };
    },
    updateBasicInfoSucceeded: (state, action: PayloadAction<UpdateBasicInfoRequest>) => {
      state.entities.employeeProfile.basicInfo = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        middleName: action.payload.middleName,
        displayName: action.payload.displayName
      } as BasicInfo;
      state.ui.profileNotification = {
        status: ActionStatus.Idle,
        i18n_status_label: 'update_succeeded',
        firstName: state.entities.employeeProfile.basicInfo.firstName,
        i18n_category_label: 'basic_info'
      };
      state.ui.isNotificationVisible = true;
    },
    updateBasicInfoFailed: (state, action: PayloadAction<UpdateBasicInfoRequest>) => {
      state.entities.employeeProfile.basicInfo = { ...state.entities.employeeProfile.basicInfo } as BasicInfo;
      state.ui.profileNotification = {
        status: ActionStatus.Failed,
        i18n_status_label: 'update_failed',
        firstName: state.entities.employeeProfile.basicInfo.firstName,
        i18n_category_label: 'basic_info'
      };
      state.ui.isNotificationVisible = true;
    },
    updatePersonalRequested: (state, action: PayloadAction<UpdatePersonalRequest>) => {
      state.ui.profileNotification = {
        status: ActionStatus.Pending,
        i18n_status_label: 'update_requested',
        firstName: '',
        i18n_category_label: '',
        category: 'personal'
      };
    },
    updatePersonalSucceeded: (state, action: PayloadAction<UpdatePersonalRequest>) => {
      state.entities.employeeProfile.personal = {
        prefixId: action.payload.prefixId,
        pronounsId: action.payload.pronounsId,
        dateOfBirth: action.payload.dateOfBirth,
        nationalityCodes: action.payload.nationalityCodes
      } as Personal;
      state.ui.profileNotification = {
        status: ActionStatus.Idle,
        i18n_status_label: 'update_succeeded',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'personal'
      };
      state.ui.isNotificationVisible = true;
    },
    updatePersonalFailed: (state, action: PayloadAction<UpdatePersonalRequest>) => {
      state.entities.employeeProfile.personal = { ...state.entities.employeeProfile.personal } as Personal;
      state.ui.profileNotification = {
        status: ActionStatus.Failed,
        i18n_status_label: 'update_failed',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'personal'
      };
      state.ui.isNotificationVisible = true;
    },
    updatePersonalContactDetailsRequested: (state, action: PayloadAction<UpdatePersonalContactDetailsRequest>) => {
      state.ui.profileNotification = {
        status: ActionStatus.Pending,
        i18n_status_label: 'update_requested',
        firstName: '',
        i18n_category_label: '',
        category: 'personalContactDetails'
      };
    },
    updatePersonalContactDetailsSucceeded: (state, action: PayloadAction<UpdatePersonalContactDetailsRequest>) => {
      state.entities.employeeProfile.personalContactDetails = {
        personalEmailAddress: action.payload.personalEmailAddress,
        personalMobilePhoneNumber: action.payload.personalMobilePhoneNumber,
        personalLandlinePhoneNumber: action.payload.personalLandlinePhoneNumber
      } as PersonalContactDetails;
      state.ui.profileNotification = {
        status: ActionStatus.Idle,
        i18n_status_label: 'update_succeeded',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'personal_contact_details'
      };
      state.ui.isNotificationVisible = true;
    },
    updatePersonalContactDetailsFailed: (state, action: PayloadAction<UpdatePersonalContactDetailsRequest>) => {
      state.entities.employeeProfile.personalContactDetails = { ...state.entities.employeeProfile.personalContactDetails } as PersonalContactDetails;
      state.ui.profileNotification = {
        status: ActionStatus.Failed,
        i18n_status_label: 'update_failed',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'personal_contact_details'
      };
      state.ui.isNotificationVisible = true;
    },
    updateIdentificationRequested: (state, action: PayloadAction<UpdateIdentificationRequest>) => {
      state.ui.profileNotification = {
        status: ActionStatus.Pending,
        i18n_status_label: 'update_requested',
        firstName: '',
        i18n_category_label: '',
        category: 'identification'
      };
    },
    updateIdentificationSucceeded: (state, action: PayloadAction<UpdateIdentificationRequest>) => {
      state.entities.employeeProfile.identification = {
        passportNumber: action.payload.passportNumber,
        nationalId: action.payload.nationalId,
        ssn: action.payload.ssn,
        niNumber: action.payload.niNumber
      } as Identification;
      state.ui.profileNotification = {
        status: ActionStatus.Idle,
        i18n_status_label: 'update_succeeded',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'identification'
      };
      state.ui.isNotificationVisible = true;
    },
    updateIdentificationFailed: (state, action: PayloadAction<UpdateIdentificationRequest>) => {
      state.entities.employeeProfile.identification = { ...state.entities.employeeProfile.identification } as Identification;
      state.ui.profileNotification = {
        status: ActionStatus.Failed,
        i18n_status_label: 'update_failed',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'identification'
      };
      state.ui.isNotificationVisible = true;
    },
    updateWorkRequested: (state, action: PayloadAction<UpdateWorkRequest>) => {
      state.ui.profileNotification = {
        status: ActionStatus.Pending,
        i18n_status_label: 'update_requested',
        firstName: '',
        i18n_category_label: '',
        category: 'work'
      };
    },
    updateWorkSucceeded: (state, action: PayloadAction<UpdateWorkRequest>) => {
      state.entities.employeeProfile.work!.workEvents &&= [];
      state.entities.employeeProfile.work = {
        buddyId: action.payload.buddyId,
        hrbpId: action.payload.hrbpId,
        payrollManagerId: action.payload.payrollManagerId,
        itAdminId: action.payload.itAdminId,
        customEmployeeId: action.payload.customEmployeeId,
        workEvents: action.payload.workEvents
      } as Work;
      state.newWorkEvents = [];
      state.ui.profileNotification = {
        status: ActionStatus.Idle,
        i18n_status_label: 'update_succeeded',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'work'
      };
      state.ui.isNotificationVisible = true;
    },
    updateWorkFailed: (state, action: PayloadAction<UpdateWorkRequest>) => {
      state.entities.employeeProfile.work = { ...state.entities.employeeProfile.work } as Work;
      state.entities.employeeProfile.work!.workEvents = state.entities.employeeProfile.work!.workEvents.splice(state.newWorkEvents.length);
      state.newWorkEvents = [];
      state.ui.profileNotification = {
        status: ActionStatus.Failed,
        i18n_status_label: 'update_failed',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'work'
      };
      state.ui.isNotificationVisible = true;
    },
    updateWorkContactDetailsRequested: (state, action: PayloadAction<UpdateWorkContactDetailsRequest>) => {
      state.ui.workContactDetailsStatus = ActionStatus.Pending;
      state.ui.profileNotification = {
        status: ActionStatus.Pending,
        i18n_status_label: 'update_requested',
        firstName: '',
        i18n_category_label: '',
        category: 'workContactDetails'
      };
    },
    updateWorkContactDetailsSucceeded: (state, action: PayloadAction<UpdateWorkContactDetailsRequest>) => {
      state.entities.employeeProfile.workContactDetails = {
        workLandlinePhoneNumber: action.payload.workLandlinePhoneNumber,
        workMobilePhoneNumber: action.payload.workMobilePhoneNumber,
        slackUsername: action.payload.slackUsername,
        skypeUsername: action.payload.skypeUsername
      } as WorkContactDetails;
      state.ui.profileNotification = {
        status: ActionStatus.Idle,
        i18n_status_label: 'update_succeeded',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'work_contact_details'
      };
      state.ui.isNotificationVisible = true;
    },
    updateWorkContactDetailsFailed: (state, action: PayloadAction<UpdateWorkContactDetailsRequest>) => {
      state.entities.employeeProfile.workContactDetails = { ...state.entities.employeeProfile.workContactDetails } as WorkContactDetails;
      state.ui.profileNotification = {
        status: ActionStatus.Failed,
        i18n_status_label: 'update_failed',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'work_contact_details'
      };
      state.ui.isNotificationVisible = true;
    },
    updateAddressRequested: (state, action: PayloadAction<UpdateAddressRequest>) => {
      state.ui.addressStatus = ActionStatus.Pending;
      state.ui.profileNotification = {
        status: ActionStatus.Pending,
        i18n_status_label: 'update_requested',
        firstName: '',
        i18n_category_label: '',
        category: 'address'
      };
    },
    updateAddressSucceeded: (state, action: PayloadAction<UpdateAddressRequest>) => {
      state.entities.employeeProfile.address = action.payload as Address;
      state.ui.profileNotification = {
        status: ActionStatus.Idle,
        i18n_status_label: 'update_succeeded',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'address'
      };
      state.ui.isNotificationVisible = true;
    },
    updateAddressFailed: (state, action: PayloadAction<UpdateAddressRequest>) => {
      state.entities.employeeProfile.address = { ...state.entities.employeeProfile.address } as Address;
      state.newAddressEvents = [];
      state.ui.profileNotification = {
        status: ActionStatus.Failed,
        i18n_status_label: 'update_failed',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'address'
      };
      state.ui.isNotificationVisible = true;
    },
    updateAboutRequested: (state, action: PayloadAction<UpdateAboutRequest>) => {
      state.ui.profileNotification = {
        status: ActionStatus.Pending,
        i18n_status_label: 'update_requested',
        firstName: '',
        i18n_category_label: '',
        category: 'about'
      };
    },
    updateAboutSucceeded: (state, action: PayloadAction<UpdateAboutRequest>) => {
      state.entities.employeeProfile.about = {
        about: action.payload.about,
        facebookProfileUrl: action.payload.facebookProfileUrl,
        xProfileUrl: action.payload.xProfileUrl,
        linkedinProfileUrl: action.payload.linkedinProfileUrl,
        hobbyIds: action.payload.hobbyIds,
        foodPreferenceIds: action.payload.foodPreferenceIds,
        superPowerIds: action.payload.superPowerIds
      } as About;
      state.ui.profileNotification = {
        status: ActionStatus.Idle,
        i18n_status_label: 'update_succeeded',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'about'
      };
      state.ui.isNotificationVisible = true;
    },
    updateAboutFailed: (state, action: PayloadAction<UpdateAboutRequest>) => {
      state.entities.employeeProfile.about = { ...state.entities.employeeProfile.about } as About;
      state.ui.profileNotification = {
        status: ActionStatus.Failed,
        i18n_status_label: 'update_failed',
        firstName: state.entities.employeeProfile.basicInfo!.firstName,
        i18n_category_label: 'about'
      };
      state.ui.isNotificationVisible = true;
    },
    updateProfileMediaRequested: (state, action: PayloadAction<UpdateProfileMediaRequest>) => {},
    updateProfileMediaSucceeded: (state, action: PayloadAction<ProfileMedia>) => {
      state.entities.employeeProfile.profileMedia = action.payload;
    },
    updateProfileMediaFailed: (state) => {},
    closeNotification: (state) => {
      state.ui.isNotificationVisible = false;
    },
    retrieveEmployeeProfileAndCoverReadSasTokenRequested: (state) => {},
    retrieveEmployeeProfileAndCoverReadSasTokenSucceeded: (state, action: PayloadAction<SasToken>) => {
      state.employeeProfileAndCoverReadSasToken = action.payload.token;
    },
    retrieveEmployeeProfileAndCoverReadSasTokenFailed: (state) => {},
    setEmployeeProfilePictureFileMultipleSizes: (state, action: PayloadAction<MultiSizeImageBlobs | undefined>) => {
      state.employeeProfilePictureFileMultiSize = action.payload;
    },
    removeEmployeeProfilePictureFileMultipleSizes: (state) => {
      state.employeeProfilePictureFileMultiSize = null;
    },
    resetEmployeeProfilePictureFileMultipleSizes: (state) => {
      state.employeeProfilePictureFileMultiSize = undefined;
    },
    setEmployeeCoverPictureFileMultipleSizes: (state, action: PayloadAction<MultiSizeImageBlobs | undefined>) => {
      state.employeeProfileCoverPictureMultiSize = action.payload;
    },
    removeEmployeeProfileCoverPictureFileMultiSize: (state) => {
      state.employeeProfileCoverPictureMultiSize = null;
    },
    resetEmployeeProfileCoverPictureFileMultiSize: (state) => {
      state.employeeProfileCoverPictureMultiSize = undefined;
    }
  }
});

export const selectLocale = (state: RootState) => state.employeeProfile.locale;
export const selectUserStatus = (state: RootState) => state.employeeProfile.userStatus;
export const selectUpdateUserStatus = (state: RootState) => state.employeeProfile.updateUserStatus;
export const selectEmployeeItems = (state: RootState) => state.employeeProfile.entities.employeeItems;
export const selectEmployeeItemsIds = (state: RootState) => state.employeeProfile.entities.employeeItems.allIds;
export const selectEmployeeProfileStatus = (state: RootState) => state.employeeProfile.ui.employeeProfileStatus;
export const selectPronouns = (state: RootState) => state.employeeProfile.entities.pronounces;
export const selectPrefixes = (state: RootState) => state.employeeProfile.entities.prefixes;
export const selectGenders = (state: RootState) => state.employeeProfile.entities.genders;
export const selectWorkEligibilityTypes = (state: RootState) => state.employeeProfile.entities.workEligibilityTypes;
export const selectEthnicities = (state: RootState) => state.employeeProfile.entities.ethnicities;
export const selectJobCategories = (state: RootState) => state.employeeProfile.entities.jobCategories;
export const selectEmploymentTypes = (state: RootState) => state.employeeProfile.entities.employmentTypes;
export const selectWorkingPatterns = (state: RootState) => state.employeeProfile.entities.workingPatterns!;
export const selectCalendarItems = (state: RootState) => state.employeeProfile.entities.calendarItems;
export const selectOrganizationSites = (state: RootState) => state.employeeProfile.entities.organizationSites;
export const selectCompletedOrganizationSites = createSelector([selectOrganizationSites], (sites) =>
  sites.filter((site) => site.status === SiteStatus.SITE_COMPLETED)
);
export const selectOrganizationDepartments = (state: RootState) => state.employeeProfile.entities.organizationDepartments;
export const selectBasicInfoCategory = (state: RootState) => state.employeeProfile.entities.employeeProfile.basicInfo!;
export const selectBasicInfoStatus = (state: RootState) => state.employeeProfile.ui.basicInfoStatus;
export const selectFinancialCategory = (state: RootState) => state.employeeProfile.entities.employeeProfile.financial!;
export const selectEEOCategory = (state: RootState) => state.employeeProfile.entities.employeeProfile.eeo!;
export const selectUserDataCategory = (state: RootState) => state.employeeProfile.entities.employeeProfile.userData!;
export const selectEmergencyCategory = (state: RootState) => state.employeeProfile.entities.employeeProfile.emergency!;
export const selectPayrollCategory = (state: RootState) => state.employeeProfile.entities.employeeProfile.payroll!;
export const selectPayrollCategoryTableData = (state: RootState) => state.employeeProfile.entities.employeeProfile.payroll!.payrollEvents;
export const selectEmploymentCategory = (state: RootState) => state.employeeProfile.entities.employeeProfile.employment!;
export const selectEmploymentCategoryTableData = (state: RootState) => state.employeeProfile.entities.employeeProfile.employment!.employmentEvents;
export const selectWorkEligibilityCategory = (state: RootState) => state.employeeProfile.entities.employeeProfile.workEligibility!;
export const selectWorkEligibilityTableData = (state: RootState) => state.employeeProfile.entities.employeeProfile.workEligibility!.workEligibilityItems;
export const selectNewPayrollEvents = (state: RootState) => state.employeeProfile.newPayrollEvents;
export const selectNewEmploymentEvents = (state: RootState) => state.employeeProfile.newEmploymentEvents;
export const selectNewWorkEligibilityItems = (state: RootState) => state.employeeProfile.newWorkEligibilityItems;
export const selectNewChildItems = (state: RootState) => state.employeeProfile.newChildItems;
export const selectEeoResetState = (state: RootState) => state.employeeProfile.eeoResetState;
export const selectPersonalCategory = (state: RootState) => state.employeeProfile.entities.employeeProfile.personal!;
export const selectPersonalCategoryStatus = (state: RootState) => state.employeeProfile.ui.personalCategoryStatus;
export const selectPersonalContactDetailsCategory = (state: RootState) => state.employeeProfile.entities.employeeProfile.personalContactDetails!;
export const selectPersonalContactDetailsStatus = (state: RootState) => state.employeeProfile.ui.personalContactDetailsStatus;
export const selectWorkCategory = (state: RootState) => state.employeeProfile.entities.employeeProfile.work!;
export const selectNewWorkEvents = (state: RootState) => state.employeeProfile.newWorkEvents;
export const selectWorkStatus = (state: RootState) => state.employeeProfile.ui.workStatus;
export const selectWorkCategoryTableData = (state: RootState) => state.employeeProfile.entities.employeeProfile.work!.workEvents;
export const selectWorkContactCategory = (state: RootState) => state.employeeProfile.entities.employeeProfile.workContactDetails!;
export const selectWorkContactStatus = (state: RootState) => state.employeeProfile.ui.workContactDetailsStatus;
export const selectAddressCategory = (state: RootState) => state.employeeProfile.entities.employeeProfile.address!;
export const selectNewAddressEvents = (state: RootState) => state.employeeProfile.newAddressEvents;
export const selectAddressCategoryTable = (state: RootState) => state.employeeProfile.entities.employeeProfile.address!.addressEvents;
export const selectAddressEvents = (state: RootState) => state.employeeProfile.entities.employeeProfile.address!.addressEvents;
export const selectAddressCategoryTableData = (state: RootState) => state.employeeProfile.entities.employeeProfile.address!.addressEvents;
export const selectAddressStatus = (state: RootState) => state.employeeProfile.ui.addressStatus;
export const selectHomeCategory = (state: RootState) => state.employeeProfile.entities.employeeProfile.home!;
export const selectHomeCategoryTableData = (state: RootState) => state.employeeProfile.entities.employeeProfile.home!.childItems;
export const selectAboutCategory = (state: RootState) => state.employeeProfile.entities.employeeProfile.about!;
export const selectSuperpowers = (state: RootState) => state.employeeProfile.entities.superpowers;
export const selectHobbies = (state: RootState) => state.employeeProfile.entities.hobbies;
export const selectFoodPreferences = (state: RootState) => state.employeeProfile.entities.foodPreferences;
export const selectHomeCategoryStatus = (state: RootState) => state.employeeProfile.ui.homeCategoryStatus;
export const selectEeoCategoryStatus = (state: RootState) => state.employeeProfile.ui.eeoCategoryStatus;
export const selectEmergencyCategoryStatus = (state: RootState) => state.employeeProfile.ui.emergencyCategoryStatus;
export const selectPayrollCategoryStatus = (state: RootState) => state.employeeProfile.ui.payrollCategoryStatus;
export const selectEmploymentCategoryStatus = (state: RootState) => state.employeeProfile.ui.employmentCategoryStatus;
export const selectWorkEligibilityCategoryStatus = (state: RootState) => state.employeeProfile.ui.workEligibilityCategoryStatus;
export const selectFinancialCategoryStatus = (state: RootState) => state.employeeProfile.ui.financialCategoryStatus;
export const selectAboutStatus = (state: RootState) => state.employeeProfile.ui.aboutStatus;
export const selectIdentificationCategory = (state: RootState) => state.employeeProfile.entities.employeeProfile.identification!;
export const selectIdentificationStatus = (state: RootState) => state.employeeProfile.ui.identificationStatus;
export const selectIsNotificationVisible = (state: RootState) => state.employeeProfile.ui.isNotificationVisible;
export const selectProfileNotification = (state: RootState) => state.employeeProfile.ui.profileNotification;
export const selectEmployeeProfilePictureFile = (state: RootState) => state.employeeProfile.employeeProfilePictureFile;
export const selectEmployeeProfileCoverPictureFile = (state: RootState) => state.employeeProfile.employeeProfileCoverPictureFile!;
export const selectEmployeeProfileAndCoverReadSasToken = (state: RootState) => state.employeeProfile.employeeProfileAndCoverReadSasToken;
export const selectEmployeeProfileMedia = (state: RootState) => state.employeeProfile.entities.employeeProfile.profileMedia!;
export const selectEmployeeProfilePictureUrl = (state: RootState) => state.employeeProfile.entities.employeeProfile.profileMedia!.profilePictureUrl;
export const selectEmployeeProfileCoverUrl = (state: RootState) => state.employeeProfile.entities.employeeProfile.profileMedia!.coverPictureUrl;
export const selectEmployeeProfilePictureFileMultiSize = (state: RootState) => state.employeeProfile.employeeProfilePictureFileMultiSize;
export const selectEmployeeCoverPictureFileMultiSize = (state: RootState) => state.employeeProfile.employeeProfileCoverPictureMultiSize;
export const selectEmployeeItemById = (state: RootState, employeeId: string) => state.employeeProfile.entities.employeeItems.byId[employeeId];

export const selectBasicInfoCategoryDisplayName = (state: RootState) => state.employeeProfile.entities.employeeProfile.basicInfo!.displayName;

export const {
  localize,
  loadEmployeeProfileRequested,
  loadEmployeeProfileSucceeded,
  loadEmployeeProfileFailed,
  updateHomeFailed,
  updateHomeRequested,
  updateHomeSucceeded,
  removeChildItems,
  addNewPayrollEvent,
  removeNewPayrollEvents,
  addNewEmploymentEvent,
  addNewWorkEligibilityItem,
  updatePayrollFailed,
  updatePayrollRequested,
  updatePayrollSucceeded,
  updateEEOFailed,
  updateEEORequested,
  updateEEOSucceeded,
  setEeoResetStateToFalse,
  addNewWorkEvent,
  removeNewWorkEvent,
  addNewAddressEvent,
  addNewChildItem,
  updateEmergencyFailed,
  updateEmergencyRequested,
  updateEmergencySucceeded,
  updateFinancialFailed,
  updateFinancialRequested,
  updateFinancialSucceeded,
  removeNewWorkEligibilityItems,
  updateWorkEligibilityFailed,
  updateWorkEligibilityRequested,
  updateWorkEligibilitySucceeded,
  updateEmploymentFailed,
  updateEmploymentRequested,
  updateEmploymentSucceeded,
  removeNewEmploymentEvents,
  removeNewAddressEvents,
  updateBasicInfoRequested,
  updateBasicInfoSucceeded,
  updateBasicInfoFailed,
  updatePersonalRequested,
  updatePersonalSucceeded,
  updatePersonalFailed,
  updatePersonalContactDetailsRequested,
  updatePersonalContactDetailsSucceeded,
  updatePersonalContactDetailsFailed,
  updateIdentificationRequested,
  updateIdentificationSucceeded,
  updateIdentificationFailed,
  updateWorkRequested,
  updateWorkSucceeded,
  updateWorkFailed,
  updateWorkContactDetailsRequested,
  updateWorkContactDetailsSucceeded,
  updateWorkContactDetailsFailed,
  updateAddressRequested,
  updateAddressSucceeded,
  updateAddressFailed,
  updateAboutRequested,
  updateAboutSucceeded,
  updateAboutFailed,
  closeNotification,
  updateProfileMediaRequested,
  updateProfileMediaSucceeded,
  updateProfileMediaFailed,
  retrieveEmployeeProfileAndCoverReadSasTokenRequested,
  retrieveEmployeeProfileAndCoverReadSasTokenSucceeded,
  retrieveEmployeeProfileAndCoverReadSasTokenFailed,
  setEmployeeProfilePictureFileMultipleSizes,
  removeEmployeeProfilePictureFileMultipleSizes,
  resetEmployeeProfilePictureFileMultipleSizes,
  setEmployeeCoverPictureFileMultipleSizes,
  removeEmployeeProfileCoverPictureFileMultiSize,
  resetEmployeeProfileCoverPictureFileMultiSize
} = employeeProfileSlice.actions;
export default employeeProfileSlice.reducer;
