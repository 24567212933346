import React from 'react';

interface ChevronDownIconAttributes {
  width?: number; // Optional size prop to control the icon size
  height?: number; // Optional size prop to control the icon size
  color?: string; // Optional color prop to control the icon color
}

export const ChevronDownIcon: React.FC<ChevronDownIconAttributes> = ({ width = 12, height = 7, color = '#251D38' }: ChevronDownIconAttributes) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 7" fill={color} xmlns="http://www.w3.org/2000/svg">
      <path d="M1.41 0.0257568L6 4.02046L10.59 0.0257568L12 1.25557L6 6.4888L0 1.25557L1.41 0.0257568Z" fill="black" />
    </svg>
  );
};
