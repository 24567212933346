import { extension } from '@utils/getExtensionFromName';

export const getDocThumbnail = (name: string): string => {
  let src: string = '';

  switch (extension(name)) {
    case 'pdf': {
      src = '/images/pdf-placeholder.png';
      break;
    }
    case 'csv': {
      src = '/images/xlsl-placeholder.png';
      break;
    }
    case 'xlsl': {
      src = '/images/xlsl-placeholder.png';
      break;
    }
    case 'doc': {
      src = '/images/word-docl-placeholder.png';
      break;
    }
    case 'docx': {
      src = '/images/word-docl-placeholder.png';
      break;
    }
    default: {
      src = '/images/word-docl-placeholder.png';
      break;
    }
  }

  return src;
};
