import { Button, buttonClasses, ButtonProps, styled } from '@mui/material';

export const AssetsNewFolderButton = styled(Button)<ButtonProps>(({ theme, ...props }) => ({
  [`&.${buttonClasses.root}`]: {
    color: theme.palette.common.black,
    padding: props.startIcon || props.endIcon ? '12px 32px' : '12px 18px',
    margin: 0,
    backgroundColor: theme.palette.grey[600],
    borderRadius: '10px',
    border: '1px solid',
    borderColor: theme.palette.grey[900],
    transition: theme.transitions.create('background-color, color', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    '& svg, path': {
      transition: theme.transitions.create('fill', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      })
    },
    '&:hover': {
      backgroundColor: theme.palette.customTheme.navColor,
      color: theme.palette.common.white,
      '& svg, path': {
        fill: theme.palette.common.white
      }
    }
  }
}));
