import { CalculatedErbFieldOptions, ErbFieldValue, ErbScalarField, ErbTableField, ErTemplateField, TableErbFieldValue } from '@thrivea/organization-client';
import { DateTime } from 'luxon';

export const getScalarFieldValue = (field: ErbScalarField, allFieldValues: ErbFieldValue[]) => {
  if (field.isCalculated) {
    return getCalculatedValue(field.options!.kind.value as CalculatedErbFieldOptions, allFieldValues);
  }

  return getNonCalculatedValue(allFieldValues.find((f) => f.erbFieldId === field.id)!);
};

export const getFieldValuePpd = (field: ErbScalarField, allFieldValues: ErbFieldValue[]) => {
  if (field.isCalculated) {
    return getCalculatedValue(field.options!.kind.value as CalculatedErbFieldOptions, allFieldValues);
  }

  return getNonCalculatedValuePpd(allFieldValues.find((f) => f.erbFieldId === field.id)!);
};

export const getNonCalculatedValue = (fieldValue: ErbFieldValue) => {
  if (fieldValue.kind.case === 'textValue') return getStringValue(fieldValue);
  if (fieldValue.kind.case === 'numberValue') return fieldValue.kind.value.value as number;
  if (fieldValue.kind.case === 'dateValue') return getDateValue(fieldValue);
  if (fieldValue.kind.case === 'boolValue') return fieldValue.kind.value.value as boolean;
  if (fieldValue.kind.case === 'genericSetValue') return fieldValue.kind.value.option;
};

const getNonCalculatedValuePpd = (fieldValue: ErbFieldValue) => {
  if (fieldValue.kind.case === 'textValue') return getStringValue(fieldValue);
  if (fieldValue.kind.case === 'numberValue') return fieldValue.kind.value.value as number;
  if (fieldValue.kind.case === 'dateValue') return fieldValue.kind.value.value as string;
  if (fieldValue.kind.case === 'boolValue') return fieldValue.kind.value.value as boolean;
  if (fieldValue.kind.case === 'genericSetValue') return fieldValue.kind.value.option;
};

export const getColumnFieldValuePpd = (tableId: string, columnId: string, allFieldValues: ErbFieldValue[]) => {
  // TODO: temporary till correct seed
  if (allFieldValues.find((f) => f.erbFieldId === tableId) === undefined) return;

  const tableFieldValue = allFieldValues.find((f) => f.erbFieldId === tableId)!.kind.value as TableErbFieldValue;
  const cellValue = tableFieldValue.rows[0].cells.find((f) => f.erbTableFieldColumnId === columnId);

  if (!cellValue) return;

  if (cellValue.value.case === 'textValue') return cellValue.value.value.value;
  if (cellValue.value.case === 'numberValue') return cellValue.value.value.value as number;
  if (cellValue.value.case === 'dateValue') return cellValue.value.value.value as string;
  if (cellValue.value.case === 'boolValue') return cellValue.value.value.value as boolean;
  if (cellValue.value.case === 'genericSetValue') return cellValue.value.value.option;
};

export const getErbScalarFieldId = (field: ErTemplateField) => (field.kind.case === 'scalarField' && field.kind.value.erbScalarField!.id) as string;

export const getStringValue = (field: ErbFieldValue) =>
  ((field.kind.case === 'textValue' || field.kind.case === 'numberValue') && field.kind.value.value) as string;

export const getDateValue = (field: ErbFieldValue) => DateTime.fromISO((field.kind.case === 'dateValue' && field.kind.value.value) as string);

export const getCalculatedValue = (fieldOptions: CalculatedErbFieldOptions, allFieldValues: ErbFieldValue[]) => {
  const jsExpression = fieldOptions.jsExpression;
  const params = fieldOptions.params;

  try {
    const paramNames = Object.keys(params);
    const paramValues = Object.values(params).map((fieldId) => getNonCalculatedValue(allFieldValues.find((f) => f.erbFieldId === fieldId)!));

    const runFunc = new Function(...paramNames, jsExpression);

    return runFunc(...paramValues);
  } catch (error) {
    // TODO: Sentry
    return null;
  }
};
