import React from 'react';
import { Box, Stack, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography, useTheme, useMediaQuery } from '@mui/material';
import {
  BenefitsIcon,
  DocsIcon,
  HeartIcon,
  HomeIcon,
  OperationsIcon,
  OrganizationIcon,
  PeopleIcon,
  PictureSizeSuffix,
  TalkIcon,
  TimeIcon,
  WorkIcon
} from '@/shared';
import { Link, NavLink } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { selectOrganizationLogoFile, selectOrganizationLogoImageUrl, selectOrganizationLogoReadSasToken } from '@features/admin-settings';
import { pictureUrl } from '@utils/pictureUrl';
import { NavSettingsListItem, openNavigationDrawer } from '@features/navigation';
import { AllowedTo } from 'react-abac';
import { GroupPermissions } from '@features/abac';

interface NavigationProps {
  width: number;
  isOpen: boolean;
}

export const Navigation: React.FC<NavigationProps> = ({ width, isOpen }) => {
  const dispatch = useAppDispatch();
  const logo = useAppSelector(selectOrganizationLogoImageUrl);
  const logoFile = useAppSelector(selectOrganizationLogoFile);
  const organizationSasToken = useAppSelector(selectOrganizationLogoReadSasToken);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));
  const isBelowLgHeight = useMediaQuery('(max-height: 900px)');
  const isBelowMdHeight = useMediaQuery('(max-height: 780px)');

  const navItems = [
    {
      name: 'Home',
      url: '/',
      icon: <HomeIcon />,
      disabled: false
    },
    {
      name: 'People',
      url: '/people-directory',
      icon: <PeopleIcon />,
      disabled: false
    },
    {
      name: 'Org',
      url: '/organization',
      icon: <OrganizationIcon />,
      disabled: true
    },
    {
      name: 'Assets',
      url: '/assets',
      icon: <DocsIcon />,
      disabled: false
    },
    {
      name: 'Feedback',
      url: '/feedback',
      icon: <HeartIcon />,
      disabled: true
    },
    {
      name: 'Operations',
      url: '/operations',
      icon: <OperationsIcon />,
      disabled: true
    },
    {
      name: 'Time',
      url: '/time',
      icon: <TimeIcon />,
      disabled: true
    },
    {
      name: 'Comms',
      url: '/comms',
      icon: <TalkIcon />,
      disabled: true
    },
    {
      name: 'Work',
      url: '/work',
      icon: <WorkIcon />,
      disabled: true
    },
    {
      name: 'Payroll',
      url: '/payroll',
      icon: <BenefitsIcon />,
      disabled: true
    }
  ];

  const navContent = (
    <Stack
      component={'nav'}
      sx={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
        backgroundColor: (theme) => theme.palette.customTheme.navBgr,
        border: '1px solid',
        borderColor: (theme) => theme.palette.customTheme.borderMaster,
        overflowY: 'scroll',
        borderRadius: {
          xs: 0,
          md: 4
        }
      }}
    >
      <Toolbar
        sx={{
          minHeight: {
            xs: '90px'
          },
          padding: {
            xs: '1rem',
            md: '1rem'
          }
        }}
      >
        <Stack
          gap={2}
          sx={{
            width: '100%',
            justifyContent: 'center',
            alignItems: 'flex-start'
          }}
        >
          {isOpen && (
            <Box
              component="img"
              src="/images/nav-logo-expanded.png"
              alt="Thrivea logo"
              sx={{
                maxWidth: '100%'
              }}
            />
          )}
          {!isOpen && (
            <Box
              component="img"
              src="/images/nav-logo-default.png"
              alt="Thrivea logo"
              sx={{
                maxWidth: '100%'
              }}
            />
          )}
        </Stack>
      </Toolbar>
      <List
        sx={{
          color: 'inherit',
          margin: 'auto',
          width: '100%',
          maxHeight: 600,
          padding: 0
        }}
      >
        {navItems.map((item, index) => (
          <ListItem
            key={index}
            sx={{
              padding: !isOpen ? 0 : '6px 16px',
              mb: '16px',
              position: 'relative',
              '&:hover': {
                '& .MuiLinkText': {
                  opacity: 1
                }
              }
            }}
          >
            <ListItemButton
              disableGutters
              component={NavLink}
              to={item.url}
              sx={{
                padding: 0,
                justifyContent: 'center',
                pointerEvents: item.disabled ? 'none' : 'all',
                '&.active': {
                  color: (theme) => theme.palette.customTheme.focusItem,
                  '& path': {
                    fill: (theme) => theme.palette.customTheme.focusItem
                  }
                },
                opacity: item.disabled ? '0.25' : 1
              }}
            >
              <Stack
                sx={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&:hover': {
                    '& path': {
                      fill: (theme) => theme.palette.secondary.main
                    },
                    '& .MuiLinkText': {
                      transform: 'translateY(5px)',
                      opacity: 1,
                      color: (theme) => theme.palette.secondary.main
                    },
                    '& svg': {
                      width: isBelowLgHeight ? 20 : 'auto',
                      height: isBelowLgHeight ? 20 : 'auto'
                    }
                  }
                }}
              >
                {!isBelowMdHeight && (
                  <ListItemIcon
                    className="MuiLinkIcon"
                    sx={{
                      minWidth: 'auto',
                      '& path': {
                        fill: (theme) => theme.palette.common.black
                      }
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                )}
                <Typography
                  className="MuiLinkText"
                  variant="body2"
                  sx={{
                    opacity: isBelowLgHeight && !isBelowMdHeight ? 0 : 1,
                    transform: 'translateY(0)',
                    transition: (theme) =>
                      theme.transitions.create(['color', 'transform', 'width', 'height'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.standard
                      })
                  }}
                >
                  {item.name}
                </Typography>
              </Stack>
              {isOpen && (
                <ListItemText
                  primary={item.name}
                  sx={{
                    opacity: isOpen ? '1' : '0',
                    visibility: isOpen ? 'visible' : 'hidden',
                    transition: (theme) =>
                      theme.transitions.create(['opacity', 'visibility'], {
                        easing: theme.transitions.easing.easeInOut,
                        duration: theme.transitions.duration.standard
                      })
                  }}
                />
              )}
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      <List
        sx={{
          width: '100%',
          padding: 0,
          position: 'sticky',
          bottom: 0,
          backgroundColor: (theme) => theme.palette.common.white
        }}
      >
        <AllowedTo perform={GroupPermissions.ACCESS_SETTINGS} no={() => <NavSettingsListItem isDrawerOpened={isOpen} isDisabled={true} />}>
          <NavSettingsListItem isDrawerOpened={isOpen} isDisabled={false} />
        </AllowedTo>
        <ListItem
          sx={{
            padding: '0'
          }}
        >
          <ListItemButton
            disableGutters
            component={Link}
            to="#"
            sx={{
              justifyContent: 'center',
              '&:hover': {
                ' .hoverText': {
                  opacity: '1'
                }
              }
            }}
          >
            <Stack
              gap={1}
              sx={{
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <Box
                component="img"
                src={
                  logoFile
                    ? URL.createObjectURL(logoFile.sm)
                    : logo
                      ? pictureUrl(logo, organizationSasToken, PictureSizeSuffix.sm)
                      : '/images/logo-placeholder.png'
                }
                sx={{
                  width: 46,
                  height: 46,
                  objectFit: 'contain',
                  borderRadius: 2,
                  backgroundColor: logo ? 'transparent' : '#000'
                }}
              />
            </Stack>
          </ListItemButton>
        </ListItem>
      </List>
    </Stack>
  );

  const navigationDrawerOpenButtonClick = () => {
    dispatch(openNavigationDrawer());
  };

  return (
    <>
      {!isTablet && (
        <Drawer
          variant="temporary"
          open={isOpen}
          onClose={navigationDrawerOpenButtonClick}
          sx={{
            display: {
              xs: 'block',
              lg: 'none'
            },
            '& .MuiDrawer-paper': {
              width
            },
            '& .MuiBackdrop-root': {
              background: 'rgba(217, 217, 217, 0.60)',
              backdropFilter: 'blur(10px)'
            }
          }}
        >
          {navContent}
        </Drawer>
      )}
      {isTablet && <>{navContent}</>}
    </>
  );
};
