import { styled, TextField, inputBaseClasses, outlinedInputClasses, textFieldClasses } from '@mui/material';

export const AudienceInput = styled(TextField)(({ theme }) => ({
  [`& .${inputBaseClasses.input}`]: {
    padding: '16px 0',
    width: 130,
    caretColor: theme.palette.secondary.main
  },
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    borderColor: 'transparent',
    border: 0
  },
  [`&.${textFieldClasses.root}`]: {
    backgroundColor: 'inherit',
    borderColor: 'transparent',
    '&:hover': {
      backgroundColor: 'inherit',
      borderColor: 'transparent'
    }
  }
}));
