import React from 'react';

interface CalculatedIconProps {
  width?: number;
  height?: number;
  isSelected?: boolean;
}

export const CalculatedIcon: React.FC<CalculatedIconProps> = ({ width = 13, height = 12, isSelected }: CalculatedIconProps) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={13} height={height} fill="none">
      <path
        d="M4.755 11.3947L4.75536 11.3945C5.28274 11.1486 5.74817 10.7824 6.10113 10.3299C6.61322 9.67809 6.7862 8.84788 7.06604 7.50483C7.07192 7.47658 7.07786 7.4481 7.08384 7.41939L7.08392 7.419L7.20359 6.84037H9.75764C10.2232 6.84037 10.6186 6.47163 10.6186 6.00098C10.6186 5.53034 10.2232 5.16159 9.75764 5.16159H7.552L7.60317 4.91653L7.60325 4.91618C7.7374 4.26912 7.8357 3.79627 7.93829 3.43523C8.04073 3.07473 8.14087 2.8513 8.26575 2.69187C8.45538 2.45155 8.70331 2.25419 8.98765 2.12141L8.98774 2.12137C9.17697 2.03291 9.42648 1.98153 9.81476 1.95522C10.203 1.92892 10.7042 1.92878 11.389 1.92878C11.8546 1.92878 12.25 1.56003 12.25 1.08939C12.25 0.618747 11.8546 0.25 11.389 0.25L11.3257 0.249998C9.88627 0.249923 9.01021 0.249878 8.245 0.605286L8.24464 0.605452C7.71726 0.851408 7.25183 1.21763 6.89887 1.67005C6.38678 2.32191 6.2138 3.15212 5.93396 4.49517C5.92808 4.52342 5.92214 4.5519 5.91616 4.58061L5.91608 4.581L5.79641 5.15963H3.24236C2.7768 5.15963 2.38136 5.52837 2.38136 5.99902C2.38136 6.46966 2.7768 6.83841 3.24236 6.83841H5.448L5.39683 7.08347L5.39675 7.08382C5.2626 7.73087 5.1643 8.20372 5.06171 8.56477C4.95927 8.92527 4.85912 9.14871 4.73424 9.30813C4.54461 9.54845 4.29669 9.74582 4.01235 9.87859L4.01226 9.87863C3.82303 9.96709 3.57352 10.0185 3.18524 10.0448C2.797 10.0711 2.29579 10.0712 1.611 10.0712C1.14544 10.0712 0.75 10.44 0.75 10.9106C0.75 11.3813 1.14544 11.75 1.611 11.75L1.67433 11.75C3.11373 11.7501 3.98979 11.7501 4.755 11.3947Z"
        fill={isSelected ? '#FFFFFF' : '#000000'}
        stroke={isSelected ? '#FFFFFF' : '#000000'}
        strokeWidth="0.5"
      />
    </svg>
  );
};
