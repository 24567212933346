const VIDEO_MIME_TYPES = [
  'video/mp4',
  'video/webm',
  'video/ogg',
  'video/x-msvideo',
  'video/x-matroska',
  'video/quicktime',
  'video/mpeg',
  'video/3gpp',
  'video/3gpp2',
  'video/x-flv',
  'video/x-ms-wmv'
];

export const VIDEO_FILE_TYPES = ['mp4', 'avi', 'flv', 'wmv', 'mov', 'ogg', 'webm', 'mkv'];

const VIDEO_MIME_TYPES_PATTERN = /^video\/(mp4|x-msvideo|x-flv|x-ms-wmv|quicktime|ogg|webm|x-matroska)$/i;
const VIDEO_EXTENSION_PATTERN = /\.(mp4|avi|flv|wmv|mov|ogg|webm|mkv)$/i;

export const isVideoUrl = (url: string) => VIDEO_EXTENSION_PATTERN.test(new URL(url).pathname);
export const isVideo = (filename: string) => VIDEO_MIME_TYPES_PATTERN.test(filename);

export const isVideoMimeType = (mimeType: string) => VIDEO_MIME_TYPES.includes(mimeType);
