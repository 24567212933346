import { styled, Stack, StackProps } from '@mui/material';

export const FixedHeightWrapper = styled(Stack)<StackProps>(({ theme }) => ({
  overflowY: 'scroll',
  transform: 'translate3d(0,0,0)',
  willTransform: 'transform',
  backgroundColor: 'inherit',
  borderRadius: '10px',
  [theme.breakpoints.up('sm')]: {
    maxHeight: '100%'
  },
  [theme.breakpoints.up('lg')]: {
    maxHeight: 'calc(100vh - 136px)'
  }
}));
