import { Box, Divider, Fade, IconButton, Stack, Typography } from '@mui/material';
import Grid from '@mui/material/Grid2';
import Quill from 'quill/core';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import {
  AvailableBetaTag,
  CloseIcon,
  EventIcon,
  LinkIcon,
  MapPinIcon,
  MentionIcon,
  MoustacheIcon,
  QuillEditor,
  RowCenterStack,
  StackedBars,
  UploadIcon
} from '@/shared';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileUploader } from 'react-drag-drop-files';
import {
  ActionButton,
  CancelPostButton,
  editPostRequested,
  InlinePostMediaEdit,
  MediaCard,
  MentionAutocomplete,
  PostDoc,
  PostMedia,
  selectAudienceIdByPostId,
  selectNewFilesUrls,
  SubmitPostButton,
  uploadPostFilesRequested
} from '@features/homepage';
import { EditShoutoutRequest } from '@thrivea/networking-client';
import { fetchBlobFileNames } from '@api/blob-storage.api';
import { trimQueryParams, removeBomCharacters } from '@/utils';
import * as Sentry from '@sentry/react';

interface InlinePostEditProps {
  postId: string;
  authorId: string;
  content: string;
  files: PostMedia[];
  docs: PostDoc[];
  handleSetPostEditable: (id: string) => void;
}

export const InlinePostEdit: React.FC<InlinePostEditProps> = ({ postId, authorId, content, files, docs, handleSetPostEditable }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(['common', 'homepage']);
  const [text, setText] = useState(content);
  const [isMediaToolbarVisible, setIsMediaToolbarVisible] = useState(false);
  const [isMentionToolbarVisible, setIsMentionToolbarVisible] = useState(false);
  const [filenames, setFilenames] = useState<(PostMedia | PostDoc)[]>([]);
  const uploadedFilesUrls = useAppSelector<string[]>(selectNewFilesUrls);
  const quillRef = useRef<Quill | null>(null);
  const audienceId = useAppSelector((state) => selectAudienceIdByPostId(state, postId));

  useEffect(() => {
    const fetchAttachmentFileNames = async () => {
      try {
        const docFiles = await fetchBlobFileNames(docs.map((df) => df.src));
        // old docs were in mediaUrls
        const mediaFiles = await fetchBlobFileNames(files.map((f) => f.src));
        const docFileNames = docFiles.map((name, index) => ({
          name,
          src: docs[index].src,
          alt: docs[index].alt
        }));
        // old docs were in mediaUrls
        const mediaFileNames = mediaFiles.map((name, index) => ({
          name,
          src: files[index].src,
          alt: files[index].alt
        }));
        setFilenames([...mediaFileNames, ...docFileNames]);
      } catch (error) {
        Sentry.captureException(error);
      }
    };

    fetchAttachmentFileNames();
  }, [files, docs]);

  const handleMediaToolbarToggle = () => {
    setIsMediaToolbarVisible(!isMediaToolbarVisible);
    setIsMentionToolbarVisible(false);
  };

  const handleMentionToolbarToggle = () => {
    setIsMentionToolbarVisible(!isMentionToolbarVisible);
    setIsMediaToolbarVisible(false);
  };

  const handleMentionToolbarClose = () => {
    setIsMentionToolbarVisible(false);
  };

  const handleFilesUpload = async (files: FileList) => {
    dispatch(uploadPostFilesRequested(files));
  };

  const handleDeleteFile = (src: string) => {
    setFilenames(filenames.filter((file) => file.src !== src));
  };

  const handleUpdatePostButtonClick = () => {
    const oldUrls = filenames.map((nf) => trimQueryParams(nf.src));
    const mergedMediaUrls = [...oldUrls, ...uploadedFilesUrls];

    dispatch(
      editPostRequested(
        new EditShoutoutRequest({
          id: postId,
          editAuthorId: authorId,
          text: removeBomCharacters(text),
          mediaUrls: mergedMediaUrls
        })
      )
    );

    handleSetPostEditable(postId);
  };

  return (
    <Stack
      onKeyDown={(event) => {
        if (event.key === 'Escape') {
          handleSetPostEditable(postId);
        }
        if (event.key === 'Enter' && event.shiftKey) {
          handleUpdatePostButtonClick();
        }
      }}
      gap={2}
      sx={{
        backgroundColor: 'transparent',
        borderRadius: 4,
        mb: 2
      }}
    >
      <Stack gap={2}>
        <Box
          sx={{
            position: 'relative',
            '& .ql-editor': {
              whiteSpace: 'pre-wrap'
            }
          }}
        >
          <QuillEditor ref={quillRef} text={text} setText={setText} audienceId={audienceId} />
        </Box>
        {filenames.length > 0 && <InlinePostMediaEdit namedFiles={filenames} handleDeleteFile={handleDeleteFile} />}
        <Stack
          sx={{
            p: 2,
            borderRadius: 4,
            backgroundColor: (theme) =>
              isMediaToolbarVisible || isMentionToolbarVisible ? theme.palette.customTheme.drawerBackground : theme.palette.common.white
          }}
        >
          <Fade
            in={isMediaToolbarVisible}
            children={
              <Grid
                container
                columnSpacing={2}
                sx={{
                  py: isMediaToolbarVisible ? 2 : 0,
                  height: isMediaToolbarVisible ? 'auto' : 0
                }}
              >
                <Grid
                  sx={{
                    width: 258
                  }}
                >
                  <MediaCard>
                    <Box
                      component="img"
                      src="/images/upload-file-ilustration.png"
                      sx={{
                        width: 140,
                        height: 160,
                        objectFit: 'contain'
                      }}
                    />

                    <FileUploader
                      multiple
                      handleChange={handleFilesUpload}
                      name="file"
                      children={<ActionButton>{t('upload', { ns: 'common' })}</ActionButton>}
                    />
                    <Typography variant="body1" sx={{ fontWeight: 700 }}>
                      {t('your_device', { ns: 'homepage' })}
                    </Typography>
                  </MediaCard>
                </Grid>
                <Grid
                  sx={{
                    width: 258
                  }}
                >
                  <MediaCard>
                    <AvailableBetaTag top={10} right={10} />
                    <Box
                      component="img"
                      src="/images/microsoft-one-drive-illustration.png"
                      sx={{
                        width: 180,
                        height: 160,
                        objectFit: 'contain',
                        opacity: 0.25
                      }}
                    />
                    <ActionButton disabled>{t('select', { ns: 'common' })}</ActionButton>
                    <Typography variant="body1" sx={{ fontWeight: 700, opacity: 0.5 }}>
                      {t('microsoft_onedrive', { ns: 'homepage' })}
                    </Typography>
                  </MediaCard>
                </Grid>
                <Grid
                  sx={{
                    width: 258
                  }}
                >
                  <MediaCard>
                    <AvailableBetaTag top={10} right={10} />
                    <Box
                      component="img"
                      src="/images/google-drive-illustration.png"
                      sx={{
                        width: 180,
                        height: 160,
                        objectFit: 'contain',
                        opacity: 0.25
                      }}
                    />
                    <ActionButton disabled>{t('select', { ns: 'common' })}</ActionButton>
                    <Typography variant="body1" sx={{ fontWeight: 700, opacity: 0.5 }}>
                      {t('google_drive', { ns: 'homepage' })}
                    </Typography>
                  </MediaCard>
                </Grid>
              </Grid>
            }
          />
          {isMentionToolbarVisible && <MentionAutocomplete ref={quillRef} handleMentionToolbarClose={handleMentionToolbarClose} setText={setText} />}
          <RowCenterStack
            sx={{
              justifyContent: 'space-between'
            }}
          >
            <RowCenterStack gap={1}>
              <Typography variant="subtitle1">Insert</Typography>
              <IconButton disabled sx={{ opacity: 0.25 }}>
                <MoustacheIcon />
              </IconButton>
              <IconButton disabled sx={{ opacity: 0.25 }}>
                <StackedBars />
              </IconButton>
              <IconButton disabled sx={{ opacity: 0.25 }}>
                <EventIcon />
              </IconButton>
              <Divider orientation="vertical" flexItem sx={{ borderStyle: 'dashed' }} />
              <IconButton
                onClick={handleMentionToolbarToggle}
                sx={{
                  backgroundColor: (theme) => (isMentionToolbarVisible ? theme.palette.customTheme.focusItem : 'transparent'),
                  borderRadius: 2
                }}
              >
                {isMentionToolbarVisible ? (
                  <Stack
                    sx={{
                      width: 24,
                      height: 24,
                      borderRadius: '50%',
                      backgroundColor: (theme) => theme.palette.common.black,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <CloseIcon color="#FFFFFF" />
                  </Stack>
                ) : (
                  <MentionIcon />
                )}
              </IconButton>
              <IconButton
                onClick={handleMediaToolbarToggle}
                sx={{
                  backgroundColor: (theme) => (isMediaToolbarVisible ? theme.palette.customTheme.focusItem : 'transparent'),
                  borderRadius: 2
                }}
              >
                {isMediaToolbarVisible ? (
                  <Stack
                    sx={{
                      width: 24,
                      height: 24,
                      borderRadius: '50%',
                      backgroundColor: (theme) => theme.palette.common.black,
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <CloseIcon color="#FFFFFF" />
                  </Stack>
                ) : (
                  <UploadIcon />
                )}
              </IconButton>
              <IconButton disabled sx={{ opacity: 0.25 }}>
                <LinkIcon />
              </IconButton>
              <IconButton disabled sx={{ opacity: 0.25 }}>
                <MapPinIcon />
              </IconButton>
            </RowCenterStack>
            <RowCenterStack gap={1}>
              <CancelPostButton variant="outlined" onClick={() => handleSetPostEditable(postId)}>
                {t('cancel', { ns: 'common' })}
              </CancelPostButton>
              <SubmitPostButton variant="outlined" onClick={handleUpdatePostButtonClick}>
                {t('update', { ns: 'common' })}
              </SubmitPostButton>
            </RowCenterStack>
          </RowCenterStack>
        </Stack>
      </Stack>
    </Stack>
  );
};
