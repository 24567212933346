import { all, call, fork, put, select, takeLatest } from 'redux-saga/effects';
import {
  CreatePeopleDirViewRequest,
  DeletePeopleDirViewRequest,
  OrderDirection,
  RenameSavedViewRequest,
  RetrieveAllPeopleDirCategoriesSectionsFieldsResponse,
  RetrieveEmployeeRecordsRequest,
  RetrieveEmployeeRecordsResponse,
  RetrieveEmployeesByIdsRequest,
  RetrievePeopleDirViewsRequest,
  UpdatePeopleDirViewRequest
} from '@thrivea/organization-client';
import * as Sentry from '@sentry/react';
import {
  createPeopleDirView,
  deletePeopleDirView,
  renameSavedView,
  retrieveAllPeopleDirCategoriesSectionsFields,
  retrieveEmployeeRecords,
  retrievePeopleDirViews,
  updatePeopleDirView
} from '@api/people-dir.api';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  createPeopleDirViewFailed,
  createPeopleDirViewRequested,
  createPeopleDirViewSucceeded,
  deletePeopleDirViewFailed,
  deletePeopleDirViewRequested,
  deletePeopleDirViewSucceeded,
  loadPeopleDirectoryFailed,
  loadPeopleDirectoryRequested,
  loadPeopleDirectorySucceeded,
  PpdAdditionalData,
  renameSavedViewFailed,
  renameSavedViewRequested,
  renameSavedViewSucceeded,
  retrieveAllPeopleDirCategoriesSectionsFieldsFailed,
  retrieveAllPeopleDirCategoriesSectionsFieldsRequested,
  retrieveAllPeopleDirCategoriesSectionsFieldsSucceeded,
  retrieveEmployeeRecordsFailed,
  retrieveEmployeeRecordsRequested,
  retrieveEmployeeRecordsSucceeded,
  retrievePeopleDirViewsFailed,
  retrievePeopleDirViewsRequested,
  retrievePeopleDirViewsSucceeded,
  retrieveTwoStepDataFailed,
  retrieveTwoStepDataRequested,
  retrieveTwoStepDataSucceeded,
  selectEmployeeRecordIds,
  selectEmployeeRecordsWithMatchingErbFields,
  selectIsViewSaved,
  selectViewName,
  updatePeopleDirViewFailed,
  updatePeopleDirViewRequested,
  updatePeopleDirViewSucceeded
} from '@features/people-directory';
import { Empty } from '@bufbuild/protobuf';
import { PpdData } from '@features/people-directory';
import { retrieveErbFields } from '@api/erb.api';
import { showSuccess } from '@features/snackbar';
import { t } from 'i18next';
import { retrieveEmployeesByIds } from 'src/api/employees.api';

function* loadPeopleDirectoryGenerator(action: PayloadAction<string>) {
  try {
    const peopleDirectoryData: PpdData = yield all({
      allErbFields: call(retrieveErbFields, new Empty()),
      employeeRecords: call(
        retrieveEmployeeRecords,
        new RetrieveEmployeeRecordsRequest({ pageNumber: 1, pageSize: 25, orderDirection: OrderDirection.DESCENDING })
      ),
      allPeopleDirCategoriesSectionsFields: call(retrieveAllPeopleDirCategoriesSectionsFields, new Empty()),
      peopleDirViews: call(retrievePeopleDirViews, new RetrievePeopleDirViewsRequest({ employeeId: action.payload }))
    });

    yield put(loadPeopleDirectorySucceeded(peopleDirectoryData));
    yield put(retrieveTwoStepDataRequested());
  } catch (error) {
    Sentry.captureException(error);
    yield put(loadPeopleDirectoryFailed());
  }
}

function* retrieveTwoStepDataGenerator(action: PayloadAction<string>) {
  const employeeIds = yield select(selectEmployeeRecordsWithMatchingErbFields);
  try {
    const peopleDirectoryAdditionalData: PpdAdditionalData = yield all({
      employees: call(
        retrieveEmployeesByIds,
        new RetrieveEmployeesByIdsRequest({
          employeeIds
        })
      )
    });

    yield put(retrieveTwoStepDataSucceeded(peopleDirectoryAdditionalData));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveTwoStepDataFailed());
  }
}

function* retrieveEmployeeRecordsRequestedGenerator(action: PayloadAction<RetrieveEmployeeRecordsRequest>) {
  try {
    const response: RetrieveEmployeeRecordsResponse = yield call(retrieveEmployeeRecords, action.payload);
    yield put(retrieveEmployeeRecordsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveEmployeeRecordsFailed());
  }
}

function* retrieveAllPeopleDirCategoriesSectionsFieldsRequestedGenerator(action: PayloadAction<Empty>) {
  try {
    const response: RetrieveAllPeopleDirCategoriesSectionsFieldsResponse = yield call(retrieveAllPeopleDirCategoriesSectionsFields, action.payload);
    yield put(retrieveAllPeopleDirCategoriesSectionsFieldsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveAllPeopleDirCategoriesSectionsFieldsFailed());
  }
}

function* createPeopleDirViewRequestedGenerator(action: PayloadAction<CreatePeopleDirViewRequest>) {
  try {
    yield call(createPeopleDirView, action.payload);
    yield put(createPeopleDirViewSucceeded());

    const viewName = yield select(selectViewName, action.payload.viewId);
    yield put(showSuccess(t('successfully_saved_ppd_view', { ns: 'common', viewName })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(createPeopleDirViewFailed());
  }
}

function* retrievePeopleDirViewsRequestedGenerator(action: PayloadAction<RetrievePeopleDirViewsRequest>) {
  try {
    const response = yield call(retrievePeopleDirViews, action.payload);
    yield put(retrievePeopleDirViewsSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrievePeopleDirViewsFailed());
  }
}

function* renameSavedViewRequestedGenerator(action: PayloadAction<RenameSavedViewRequest>) {
  const viewOldName = yield select(selectViewName, action.payload.viewId);
  const isRenamedViewSaved = yield select(selectIsViewSaved, action.payload.viewId);

  try {
    if (isRenamedViewSaved) yield call(renameSavedView, action.payload);

    yield put(
      renameSavedViewSucceeded({
        id: action.payload.viewId,
        name: action.payload.name
      })
    );

    const viewNewName = yield select(selectViewName, action.payload.viewId);
    yield put(showSuccess(t('successfully_renamed_ppd_view', { ns: 'common', viewOldName, viewNewName })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(renameSavedViewFailed());
  }
}

function* deletePeopleDirViewRequestedGenerator(action: PayloadAction<DeletePeopleDirViewRequest>) {
  try {
    yield call(deletePeopleDirView, action.payload);
    yield put(deletePeopleDirViewSucceeded(action.payload.viewId));

    const viewName = yield select(selectViewName, action.payload.viewId);
    yield put(showSuccess(t('successfully_deleted_pdd_view', { ns: 'common', viewName })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(deletePeopleDirViewFailed());
  }
}

function* updatePeopleDirViewRequestedGenerator(action: PayloadAction<UpdatePeopleDirViewRequest>) {
  try {
    yield call(updatePeopleDirView, action.payload);
    yield put(updatePeopleDirViewSucceeded(action.payload.viewId));

    const viewName = yield select(selectViewName, action.payload.viewId);
    yield put(showSuccess(t('successfully_updated_ppd_view', { ns: 'common', viewName })));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updatePeopleDirViewFailed());
  }
}

function* retrieveEmployeeRecordsRequestedWatcher() {
  yield takeLatest(retrieveEmployeeRecordsRequested.type, retrieveEmployeeRecordsRequestedGenerator);
}

function* retrieveAllPeopleDirCategoriesSectionsFieldsRequestedWatcher() {
  yield takeLatest(retrieveAllPeopleDirCategoriesSectionsFieldsRequested.type, retrieveAllPeopleDirCategoriesSectionsFieldsRequestedGenerator);
}

function* createPeopleDirViewRequestedWatcher() {
  yield takeLatest(createPeopleDirViewRequested.type, createPeopleDirViewRequestedGenerator);
}

function* retrievePeopleDirViewsRequestedWatcher() {
  yield takeLatest(retrievePeopleDirViewsRequested.type, retrievePeopleDirViewsRequestedGenerator);
}

function* renameSavedViewRequestedWatcher() {
  yield takeLatest(renameSavedViewRequested.type, renameSavedViewRequestedGenerator);
}

function* deletePeopleDirViewRequestedWatcher() {
  yield takeLatest(deletePeopleDirViewRequested.type, deletePeopleDirViewRequestedGenerator);
}

function* loadPeopleDirectoryWatcher() {
  yield takeLatest(loadPeopleDirectoryRequested.type, loadPeopleDirectoryGenerator);
}

function* updatePeopleDirViewRequestedWatcher() {
  yield takeLatest(updatePeopleDirViewRequested.type, updatePeopleDirViewRequestedGenerator);
}

function* retrieveTwoStepDataWatcher() {
  yield takeLatest(retrieveTwoStepDataRequested.type, retrieveTwoStepDataGenerator);
}

export function* peopleDirSagas() {
  yield all([
    fork(retrieveEmployeeRecordsRequestedWatcher),
    fork(retrieveAllPeopleDirCategoriesSectionsFieldsRequestedWatcher),
    fork(createPeopleDirViewRequestedWatcher),
    fork(retrievePeopleDirViewsRequestedWatcher),
    fork(renameSavedViewRequestedWatcher),
    fork(deletePeopleDirViewRequestedWatcher),
    fork(loadPeopleDirectoryWatcher),
    fork(updatePeopleDirViewRequestedWatcher),
    fork(retrieveTwoStepDataWatcher)
  ]);
}
