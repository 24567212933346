import { FileWithStatus } from 'src/api/blob-storage.api';
import { isImage } from './isImage';
import { FileType } from '@thrivea/organization-client';
import { isVideo } from './isVideo';
import { isDocument } from './isDocument';

export const mapDocumentType = (fws: FileWithStatus) => {
  switch (true) {
    case isImage(fws.name):
      return FileType.PHOTO;
    case isVideo(fws.name):
      return FileType.AUDIO;
    case isDocument(fws.name):
      return FileType.DOCUMENT;
    default:
      return FileType.FILE_TYPES_UNDEFINED;
  }
};
