import React from 'react';

interface HomeIconAttributes {
  width?: number;
  height?: number;
  color?: string;
}

export const HomeIcon: React.FC<HomeIconAttributes> = ({ width = 23, height = 20, color = '#251D38' }: HomeIconAttributes) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path
        d="M22.186 8.16 20.25 6.777V1.5a1.502 1.502 0 0 0-1.5-1.5H16.5A1.502 1.502 0 0 0 15 1.5v1.527L12.12.97a1.503 1.503 0 0 0-1.743.001L.314 8.16a.75.75 0 0 0 .872 1.22l1.064-.76v8.632a2.253 2.253 0 0 0 2.25 2.25H18a2.253 2.253 0 0 0 2.25-2.25V8.62l1.064.76a.75.75 0 1 0 .872-1.22ZM16.5 1.5h2.25v4.206L16.5 4.098V1.5Zm-3.75 16.502h-3V13.5a.75.75 0 0 1 .75-.75H12a.75.75 0 0 1 .75.75v4.502Zm6-.75a.75.75 0 0 1-.75.75h-3.75V13.5A2.252 2.252 0 0 0 12 11.25h-1.5a2.253 2.253 0 0 0-2.25 2.25v4.502H4.5a.75.75 0 0 1-.75-.75V7.55l7.5-5.358 3.964 2.832c.03.025.062.048.096.068l3.44 2.458v9.703Z"
        fill={color}
      />
    </svg>
  );
};
