import { styled, Tab, TabProps } from '@mui/material';

export const FieldTab = styled(Tab)<TabProps>(({ theme, disabled }) => ({
  backgroundColor: disabled ? theme.palette.customTheme.zebraFreezeTwo : theme.palette.customTheme.solidBgr,
  minWidth: 'auto',
  height: 20,
  padding: '0 4px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 4
}));
