import { styled, Button, ButtonProps } from '@mui/material';

export const CancelPostButton = styled(Button)<ButtonProps>(({ theme }) => ({
  border: `1px solid`,
  borderColor: theme.palette.common.black,
  padding: '12px 32px',
  backgroundColor: theme.palette.grey[800],
  fontSize: 16,
  fontWeight: 700,
  borderRadius: '10px',
  '&:hover': {
    backgroundColor: theme.palette.customTheme.contColor,
    color: theme.palette.common.white
  }
}));
