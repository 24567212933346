import React from 'react';

interface CheckedIconAttributes {
  width?: number;
  height?: number;
  color?: string;
}

export const CheckedIcon: React.FC<CheckedIconAttributes> = ({ width = 24, height = 24, color = '#251D38' }: CheckedIconAttributes) => {
  return (
    <svg width={width} height={height} fill="none" xmlns="http://www.w3.org/2000/svg">
      <g id="Icon / Check">
        <path id="Vector" d="M20 6L9 17L4 12" stroke={color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
      </g>
    </svg>
  );
};
