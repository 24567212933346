import { Empty } from '@bufbuild/protobuf';
import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { SasToken, SasUri } from '@thrivea/auth-client';
import {
  CopyFilesRequest,
  CreateFolderRequest,
  DeletedFileItem,
  EditDescriptionRequest,
  FileInfo,
  FileItem,
  FileType,
  FolderInfo,
  MoveFilesRequest,
  MultipleFilesInfo,
  RenameFileRequest,
  RetrieveFilesInfoRequest,
  RetrieveFilesInfoResponse,
  RetrieveFilesRequest,
  RetrieveFilesResponse,
  RetrieveTopLevelFoldersRequest,
  RetrieveTopLevelFoldersResponse,
  RetrieveTrashBinFilesRequest,
  RetrieveTrashBinFilesResponse,
  SoftDeleteFilesRequest,
  StarFileRequest,
  TopLevelFolder
} from '@thrivea/organization-client';
import { FileWithStatus, UploadedFile, UploadProgress } from '@api/blob-storage.api';
import { RootState } from '@app/store';
import { ActionStatus } from '@/shared';
import { trimQueryParams } from '@/utils';
import { mapDocumentType } from 'src/utils/mapDocumentType';
import { sortBy } from 'lodash';

export const MY_FILES_FOLDER_ID = '11d49e84-8b3c-421d-8dfb-12458fe2f911';

export interface AssetsState {
  entities: {
    files: {
      byInitialId: { [key: string]: FileItem };
      byUpdatedId: { [key: string]: FileItem };
      initialIds: string[];
      updatedIds: string[];
      addedIds: string[];
      removedIds: string[];
    };
    topLevelFolders: {
      byId: { [key: string]: TopLevelFolder };
      allIds: string[];
    };
    trashBinFiles: {
      byId: { [key: string]: DeletedFileItem };
      allIds: string[];
    };
  };
  ui: {
    filesWithStatus: FileWithStatus[];
    fileUploadProgress: UploadProgress;
    retrieveFilesStatus: ActionStatus;
    retrieveTopLevelFoldersStatus: ActionStatus;
    uploadFilesStatus: ActionStatus;
    filesTotalCount: number;
    foldersTotalCount: number;
    filesPageNumber: number;
    filesPageSize: number;
    retrieveTrashFilesStatus: ActionStatus;
    trashBinFilesTotalCount: number;
    trashBinFilesPageNumber: number;
    trashBinFilesPageSize: number;
  };
  activeFile: FileItem;
  myUploadsReadSasToken: string;
  myUploadsCreateDeleteSasUri: string;
  rootFolderId: string;
  assestsDrawerItem: FileInfo | FolderInfo | MultipleFilesInfo | null;
  assetsDrawerFileItem: FileInfo;
  assetsDrawerFolderItem: FolderInfo;
  assetsDrawerMultipleFilesItem: MultipleFilesInfo;
  draggingState: 'Internal' | 'External';
}

const initialState: AssetsState = {
  entities: {
    files: {
      byInitialId: {},
      byUpdatedId: {},
      addedIds: [],
      updatedIds: [],
      initialIds: [],
      removedIds: []
    },
    topLevelFolders: {
      byId: {},
      allIds: []
    },
    trashBinFiles: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    filesWithStatus: [],
    fileUploadProgress: {
      fileProgress: 0,
      totalProgress: 0
    },
    retrieveFilesStatus: ActionStatus.Idle,
    retrieveTopLevelFoldersStatus: ActionStatus.Idle,
    uploadFilesStatus: ActionStatus.Idle,
    filesTotalCount: 0,
    foldersTotalCount: 0,
    filesPageNumber: 1,
    filesPageSize: 5,
    retrieveTrashFilesStatus: ActionStatus.Idle,
    trashBinFilesTotalCount: 0,
    trashBinFilesPageNumber: 1,
    trashBinFilesPageSize: 5
  },
  activeFile: {} as FileItem,
  myUploadsReadSasToken: '',
  myUploadsCreateDeleteSasUri: '',
  rootFolderId: MY_FILES_FOLDER_ID,
  assestsDrawerItem: null,
  assetsDrawerFileItem: {} as FileInfo,
  assetsDrawerFolderItem: {} as FolderInfo,
  assetsDrawerMultipleFilesItem: {} as MultipleFilesInfo,
  draggingState: 'External'
};

export const assetSlice = createSlice({
  name: 'assets',
  initialState,
  reducers: {
    retrieveFilesRequested: (state, action: PayloadAction<RetrieveFilesRequest>) => {
      const { pageNumber, pageSize } = action.payload;

      state.entities.files.byInitialId = {};
      state.entities.files.byUpdatedId = {};
      state.entities.files.initialIds = [];
      state.entities.files.updatedIds = [];
      state.entities.files.addedIds = [];
      state.entities.files.removedIds = [];

      state.ui.retrieveFilesStatus = ActionStatus.Pending;
      state.ui.filesPageNumber = pageNumber;
      state.ui.filesPageSize = pageSize;
    },
    retrieveFilesSucceeded: (state, action: PayloadAction<RetrieveFilesResponse>) => {
      const { files, totalCount } = action.payload;

      for (const file of files) {
        if (!state.entities.files.byInitialId.hasOwnProperty(file.id)) {
          state.entities.files.byInitialId[file.id] = file;
          state.entities.files.byUpdatedId[file.id] = file;
          state.entities.files.initialIds.push(file.id);
        }
      }

      state.ui.retrieveFilesStatus = ActionStatus.Idle;
      state.ui.filesTotalCount = totalCount;
    },
    retrieveFilesFailed: (state) => {
      state.ui.retrieveFilesStatus = ActionStatus.Failed;
    },
    retrieveTopLevelFoldersRequested: (state, action: PayloadAction<RetrieveTopLevelFoldersRequest>) => {
      state.ui.retrieveTopLevelFoldersStatus = ActionStatus.Pending;
    },
    retrieveTopLevelFoldersSucceeded: (state, action: PayloadAction<RetrieveTopLevelFoldersResponse>) => {
      const { folders, totalCount } = action.payload;

      for (const folder of folders) {
        if (!state.entities.topLevelFolders.byId.hasOwnProperty(folder.id)) {
          state.entities.topLevelFolders.byId[folder.id] = folder;
          state.entities.topLevelFolders.allIds.push(folder.id);
        }
      }

      state.ui.retrieveTopLevelFoldersStatus = ActionStatus.Idle;
      state.ui.foldersTotalCount = totalCount;
    },
    retrieveTopLevelFoldersFailed: (state) => {
      state.ui.retrieveTopLevelFoldersStatus = ActionStatus.Failed;
    },
    uploadFilesRequested: (state, action: PayloadAction<{ rootFolderID: string; files: FileWithStatus[] }>) => {
      state.ui.filesWithStatus = action.payload.files;
      state.rootFolderId = action.payload.rootFolderID;

      state.ui.uploadFilesStatus = ActionStatus.Pending;
    },
    notifyBackendOnUpload: (state) => {},
    uploadFilesSucceeded: (state) => {
      state.ui.uploadFilesStatus = ActionStatus.Idle;
    },
    uploadFilesFailed: (state) => {
      state.ui.uploadFilesStatus = ActionStatus.Failed;
    },
    uploadFile: (state, action: PayloadAction<FileWithStatus>) => {
      const fileWithStatus = state.ui.filesWithStatus.find((fws) => fws.id === action.payload.id)!;
      fileWithStatus.isUploading = true;
      fileWithStatus.isUploaded = false;
    },
    updateFileUploadProgress: (state, action: PayloadAction<UploadProgress>) => {
      state.ui.fileUploadProgress = {
        fileProgress: action.payload.fileProgress,
        totalProgress: action.payload.totalProgress
      };
    },
    fileUploaded: (state, action: PayloadAction<UploadedFile>) => {
      const fileWithStatus = state.ui.filesWithStatus.find((fws) => fws.id === action.payload.fileWithStatus.id)!;
      fileWithStatus.isUploading = false;
      fileWithStatus.isUploaded = true;
      fileWithStatus.url = trimQueryParams(action.payload.url);

      state.entities.files.byInitialId[fileWithStatus.id] = new FileItem({
        id: fileWithStatus.id,
        name: fileWithStatus.name,
        sizeInBytes: fileWithStatus.file.size,
        type: mapDocumentType(fileWithStatus),
        url: fileWithStatus.url
      });
      state.entities.files.initialIds.push(fileWithStatus.id);
      state.entities.files.addedIds.push(fileWithStatus.id);

      state.ui.fileUploadProgress = {
        fileProgress: 0,
        totalProgress: 0
      };
    },
    setActiveFile: (state, action: PayloadAction<FileItem>) => {
      state.activeFile = action.payload;
    },
    createFolderRequested: (state, action: PayloadAction<CreateFolderRequest>) => {},
    createFolderSucceeded: (state, action: PayloadAction<CreateFolderRequest>) => {
      const newFolder = action.payload;

      state.entities.files.byInitialId[newFolder.id] = new FileItem({
        id: newFolder.id,
        description: '',
        isStarred: false,
        name: newFolder.name,
        sizeInBytes: 0,
        type: FileType.FOLDER,
        url: ''
      });
      state.entities.files.initialIds.push(newFolder.id);

      state.entities.topLevelFolders.byId[newFolder.id] = new TopLevelFolder({
        id: newFolder.id,
        isPinned: false,
        name: newFolder.name
      });
      state.entities.topLevelFolders.allIds.push(newFolder.id);
    },
    createFolderFailed: (state) => {},
    retrieveMyUploadsReadSasTokenRequested: (state, action: PayloadAction<Empty>) => {},
    retrieveMyUploadsReadSasTokenSucceeded: (state, action: PayloadAction<SasToken>) => {
      state.myUploadsReadSasToken = action.payload.token;
    },
    retrieveMyUploadsReadSasTokenFailed: (state) => {},
    retrieveMyUploadsCreateDeleteSasUriRequested: (state, action: PayloadAction<Empty>) => {},
    retrieveMyUploadsCreateDeleteSasUriSucceeded: (state, action: PayloadAction<SasUri>) => {
      state.myUploadsCreateDeleteSasUri = action.payload.uri;
    },
    retrieveMyUploadsCreateDeleteSasUriFailed: (state) => {},
    refreshMyUploadsCreateDeleteSasUriRequested: (state, action: PayloadAction<Empty>) => {},
    refreshMyUploadsCreateDeleteSasUriSucceeded: (state, action: PayloadAction<SasUri>) => {
      state.myUploadsCreateDeleteSasUri = action.payload.uri;
    },
    refreshMyUploadsCreateDeleteSasUriFailed: (state) => {},
    renameFileRequested: (state, action: PayloadAction<RenameFileRequest>) => {},
    renameFileSucceeded: (state, action: PayloadAction<string>) => {
      const activeFile = state.activeFile;

      state.entities.files.byUpdatedId[activeFile.id] = {
        ...activeFile,
        name: action.payload
      };

      state.entities.topLevelFolders.byId[activeFile.id] = {
        ...state.entities.topLevelFolders.byId[activeFile.id],
        name: action.payload
      };

      state.activeFile.name = action.payload;
    },
    renameFileFailed: (state) => {},
    editDescriptionRequested: (state, action: PayloadAction<EditDescriptionRequest>) => {},
    editDescriptionSucceeded: (state) => {},
    editDescriptionFailed: (state) => {},
    softDeleteFilesRequested: (state, action: PayloadAction<SoftDeleteFilesRequest>) => {},
    softDeleteFilesSucceeded: (state, action: PayloadAction<string[]>) => {
      const removedIds = action.payload;
      for (const id of removedIds) {
        delete state.entities.files.byUpdatedId[id];
        state.entities.files.removedIds.push(id);
      }
    },
    softDeleteFilesFailed: (state) => {},
    retrieveFilesInfoRequested: (state, action: PayloadAction<RetrieveFilesInfoRequest>) => {
      state.assetsDrawerFileItem = {} as FileInfo;
      state.assetsDrawerFolderItem = {} as FolderInfo;
      state.assetsDrawerMultipleFilesItem = {} as MultipleFilesInfo;
    },
    retrieveFilesInfoSucceeded: (state, action: PayloadAction<RetrieveFilesInfoResponse>) => {
      const { kind } = action.payload;

      switch (kind.case) {
        case 'fileInfo': {
          state.assetsDrawerFileItem = kind.value;
          break;
        }
        case 'folderInfo': {
          state.assetsDrawerFolderItem = kind.value;
          break;
        }
        case 'multipleFilesInfo': {
          state.assetsDrawerMultipleFilesItem = kind.value;
          break;
        }
        default: {
          state.assetsDrawerFileItem = {} as FileInfo;
          state.assetsDrawerFolderItem = {} as FolderInfo;
          state.assetsDrawerMultipleFilesItem = {} as MultipleFilesInfo;
        }
      }
    },
    retrieveFilesInfoFailed: (state) => {},
    retrieveTrashBinFilesRequested: (state, action: PayloadAction<RetrieveTrashBinFilesRequest>) => {
      const { pageNumber, pageSize } = action.payload;

      state.ui.trashBinFilesPageNumber = pageNumber;
      state.ui.trashBinFilesPageSize = pageSize;
      state.ui.retrieveTrashFilesStatus = ActionStatus.Pending;

      state.entities.trashBinFiles.byId = {};
      state.entities.trashBinFiles.allIds = [];
    },
    retrieveTrashBinFilesSucceeded: (state, action: PayloadAction<RetrieveTrashBinFilesResponse>) => {
      const { files, totalCount } = action.payload;

      for (const file of files) {
        if (!state.entities.trashBinFiles.byId.hasOwnProperty(file.id)) {
          state.entities.trashBinFiles.byId[file.id] = file;
          state.entities.trashBinFiles.allIds.push(file.id);
        }
      }

      state.ui.trashBinFilesTotalCount = totalCount;
      state.ui.retrieveTrashFilesStatus = ActionStatus.Idle;
    },
    retrieveTrashBinFilesFailed: (state) => {
      state.ui.retrieveTrashFilesStatus = ActionStatus.Failed;
    },
    copyFilesRequested: (state, action: PayloadAction<CopyFilesRequest>) => {},
    copyFilesSucceeded: (state) => {},
    copyFilesFailed: (state) => {},
    setActiveRootFolder: (state, action: PayloadAction<string>) => {
      state.rootFolderId = action.payload;
    },
    starFileRequested: (state, action: PayloadAction<StarFileRequest>) => {},
    starFileSucceeded: (state, action: PayloadAction<string>) => {
      state.entities.files.byUpdatedId[action.payload] = {
        ...state.entities.files.byUpdatedId[action.payload],
        isStarred: !state.entities.files.byUpdatedId[action.payload].isStarred
      };

      if (!state.entities.files.updatedIds.includes(action.payload)) {
        state.entities.files.updatedIds.push(action.payload);
      }
    },
    starFileFailed: (state) => {},
    moveFilesRequested: (state, action: PayloadAction<MoveFilesRequest>) => {},
    moveFilesSucceeded: (state, action: PayloadAction<string[]>) => {
      const fileIds = action.payload;

      for (const id of fileIds) {
        state.entities.files.removedIds.push(id);
      }
    },
    moveFilesFailed: (state) => {},
    setInnerDragging: (state, action: PayloadAction<'Internal' | 'External'>) => {
      state.draggingState = action.payload;
    }
  }
});

export const selectFileByInitialId = (state: RootState) => state.assets.entities.files.byInitialId;
export const selectFileByUpdatedId = (state: RootState) => state.assets.entities.files.byUpdatedId;
export const selectFileInitalIds = (state: RootState) => state.assets.entities.files.initialIds;
export const selectFileUpdatedIds = (state: RootState) => state.assets.entities.files.updatedIds;
export const selectFileAddedIds = (state: RootState) => state.assets.entities.files.addedIds;
export const selectFileRemovedIds = (state: RootState) => state.assets.entities.files.removedIds;
export const selectFilesTotalCount = (state: RootState) => state.assets.ui.filesTotalCount;

export const selectFileIds = createSelector(
  [selectFileInitalIds, selectFileAddedIds, selectFileUpdatedIds, selectFileRemovedIds],
  (initialIds, addedIds, updatedIds, removedIds) => Array.from(new Set([...initialIds, ...addedIds, ...updatedIds].filter((id) => !removedIds.includes(id))))
);

export const selectFiles = createSelector(
  [selectFileIds, selectFileByInitialId, selectFileByUpdatedId, selectFileUpdatedIds],
  (chosenEmployeeIds, byInitialId, byUpdatedId, updatedIds) =>
    sortBy(
      chosenEmployeeIds
        .filter((id) => !updatedIds.includes(id))
        .map((id) => byInitialId[id])
        .concat(updatedIds.map((id) => byUpdatedId[id])),
      ['name'] // Replace 'name' with the key you want to sort by
    )
);

export const selectFilesPageNumber = (state: RootState) => state.assets.ui.filesPageNumber;
export const selectFilesPageSize = (state: RootState) => state.assets.ui.filesPageSize;

export const selectMemoizedFilePagesStatusText = createSelector(
  [selectFilesPageNumber, selectFilesPageSize, selectFilesTotalCount],
  (pageNumber, pageSize, totalCount) => {
    const start = (pageNumber - 1) * pageSize + 1;
    const end = Math.min(pageNumber * pageSize, totalCount);

    return `${start} - ${end} of ${totalCount}`;
  }
);

export const selectFilesPageCount = createSelector([selectFilesTotalCount, selectFilesPageSize], (totalCount, pageSize) => Math.ceil(totalCount / pageSize));

export const selectRetrieveFilesStatus = (state: RootState) => state.assets.ui.retrieveFilesStatus;

export const selectTopLevelFolderById = (state: RootState) => state.assets.entities.topLevelFolders.byId;
export const selectTopLevelFolderIds = (state: RootState) => state.assets.entities.topLevelFolders.allIds;
export const selectTopLevelFolders = createSelector([selectTopLevelFolderById, selectTopLevelFolderIds], (byId, ids) => ids.map((id) => byId[id]));

export const selectFoldersTotalCount = (state: RootState) => state.assets.ui.foldersTotalCount;
export const selectRetrieveTopLevelFoldersStatus = (state: RootState) => state.assets.ui.retrieveTopLevelFoldersStatus;

export const selectUploadFilesStatus = (state: RootState) => state.assets.ui.uploadFilesStatus;

export const selectActiveFile = (state: RootState) => state.assets.activeFile;

export const selectMyUploadsCreateDeleteSasUri = (state: RootState) => state.assets.myUploadsCreateDeleteSasUri;
export const selectMyUploadsReadSasToken = (state: RootState) => state.assets.myUploadsReadSasToken;

export const selectFilesWithStatusForUpload = (state: RootState) =>
  state.assets.ui.filesWithStatus.filter((fws) => fws.isUploaded === false && fws.isUploading === false && fws.url === '');

export const selectUploadedFilesWithStatuses = (state: RootState) => state.assets.ui.filesWithStatus;
export const selectRootFolderId = (state: RootState) => state.assets.rootFolderId;

export const selectAssestsDrawerItem = (state: RootState) => state.assets.assestsDrawerItem!;
export const selectAssetsDrawerFileItem = (state: RootState) => state.assets.assetsDrawerFileItem!;
export const selectAssetsDrawerFolderItem = (state: RootState) => state.assets.assetsDrawerFolderItem!;

export const selectDraggingState = (state: RootState) => state.assets.draggingState;

export const selectTrashBinFileById = (state: RootState) => state.assets.entities.trashBinFiles.byId;
export const selectTrashBinFileIds = (state: RootState) => state.assets.entities.trashBinFiles.allIds;
export const selectTrashBinTotalCount = (state: RootState) => state.assets.ui.trashBinFilesTotalCount;

export const selectTrashBinFiles = createSelector([selectTrashBinFileById, selectTrashBinFileIds], (byId, ids) =>
  sortBy(
    ids.map((id) => byId[id]),
    ['name'] // Replace 'name' with the key you want to sort by
  )
);

export const selectTrashFilesPageNumber = (state: RootState) => state.assets.ui.trashBinFilesPageNumber;
export const selectTrashFilesPageSize = (state: RootState) => state.assets.ui.trashBinFilesPageSize;
export const selectTrashFilesStatus = (state: RootState) => state.assets.ui.retrieveTrashFilesStatus;

export const selectTrashBinPagesCount = createSelector([selectTrashBinTotalCount, selectTrashFilesPageSize], (totalCount, pageSize) => Math.ceil(totalCount / pageSize));

export const selectMemoizedTrashFilePagesStatusText = createSelector(
  [selectTrashFilesPageNumber, selectTrashFilesPageSize, selectTrashBinTotalCount],
  (pageNumber, pageSize, totalCount) => {
    const start = (pageNumber - 1) * pageSize + 1;
    const end = Math.min(pageNumber * pageSize, totalCount);

    return `${start} - ${end} of ${totalCount}`;
  }
);

export const selectTrashFilesPageCount = createSelector([selectTrashBinTotalCount, selectTrashFilesPageSize], (totalCount, pageSize) =>
  Math.ceil(totalCount / pageSize)
);

export const selectFileUploadTotalProgress = (state: RootState) => state.assets.ui.fileUploadProgress.totalProgress;

export const {
  copyFilesRequested,
  copyFilesSucceeded,
  copyFilesFailed,
  retrieveFilesRequested,
  retrieveFilesSucceeded,
  retrieveFilesFailed,
  retrieveFilesInfoRequested,
  retrieveFilesInfoSucceeded,
  retrieveFilesInfoFailed,
  retrieveTopLevelFoldersRequested,
  retrieveTopLevelFoldersSucceeded,
  retrieveTopLevelFoldersFailed,
  uploadFilesRequested,
  notifyBackendOnUpload,
  uploadFilesSucceeded,
  uploadFilesFailed,
  uploadFile,
  updateFileUploadProgress,
  fileUploaded,
  createFolderRequested,
  createFolderSucceeded,
  createFolderFailed,
  retrieveMyUploadsReadSasTokenRequested,
  retrieveMyUploadsReadSasTokenSucceeded,
  retrieveMyUploadsReadSasTokenFailed,
  retrieveMyUploadsCreateDeleteSasUriRequested,
  retrieveMyUploadsCreateDeleteSasUriSucceeded,
  retrieveMyUploadsCreateDeleteSasUriFailed,
  refreshMyUploadsCreateDeleteSasUriRequested,
  refreshMyUploadsCreateDeleteSasUriSucceeded,
  refreshMyUploadsCreateDeleteSasUriFailed,
  renameFileRequested,
  renameFileSucceeded,
  renameFileFailed,
  retrieveTrashBinFilesRequested,
  retrieveTrashBinFilesSucceeded,
  retrieveTrashBinFilesFailed,
  editDescriptionRequested,
  editDescriptionSucceeded,
  editDescriptionFailed,
  softDeleteFilesRequested,
  softDeleteFilesSucceeded,
  softDeleteFilesFailed,
  setActiveFile,
  setActiveRootFolder,
  starFileRequested,
  starFileSucceeded,
  starFileFailed,
  setInnerDragging,
  moveFilesRequested,
  moveFilesSucceeded,
  moveFilesFailed
} = assetSlice.actions;
export default assetSlice.reducer;
