import { styled, tabClasses, Tabs, tabsClasses, TabsProps } from '@mui/material';

export const FieldTabs = styled(Tabs)<TabsProps>(() => ({
  minHeight: 'auto',
  [`& .${tabsClasses.flexContainer}`]: {
    direction: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start'
  },
  [`& .${tabClasses.root}`]: {
    fontWeight: 700
  }
}));
