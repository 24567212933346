import { styled, Autocomplete, autocompleteClasses, filledInputClasses } from '@mui/material';

export const EmployeesAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`&.${autocompleteClasses.root}`]: {
    height: 50,
    paddingRight: theme.spacing(0.5),
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  [`&.${autocompleteClasses.hasPopupIcon}`]: {
    [`& .${filledInputClasses.root}`]: {
      paddingRight: 0,
      paddingTop: 0
    }
  }
})) as typeof Autocomplete;
