import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { EmployeeRecord, RetrieveEmployeeRecordRequest, UpdateErSectionRequest } from '@thrivea/organization-client';
import * as Sentry from '@sentry/react';
import { retrieveEmployeeRecord, updateErSection } from '@api/erp.api';
import { PayloadAction } from '@reduxjs/toolkit';
import {
  retrieveEmployeeRecordFailed,
  retrieveEmployeeRecordRequested,
  retrieveEmployeeRecordSucceeded,
  updateErSectionFailed,
  updateErSectionRequested,
  updateErSectionSucceeded
} from '@features/employee-record-page';

function* retrieveEmployeeRecordRequestedGenerator(action: PayloadAction<RetrieveEmployeeRecordRequest>) {
  try {
    const response: EmployeeRecord = yield call(retrieveEmployeeRecord, action.payload);
    yield put(retrieveEmployeeRecordSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(retrieveEmployeeRecordFailed());
  }
}

function* updateErSectionRequestedGenerator(action: PayloadAction<UpdateErSectionRequest>) {
  try {
    const response = yield call(updateErSection, action.payload);
    yield put(updateErSectionSucceeded(response));
  } catch (error) {
    Sentry.captureException(error);
    yield put(updateErSectionFailed());
  }
}

function* retrieveEmployeeRecordRequestedWatcher() {
  yield takeLatest(retrieveEmployeeRecordRequested.type, retrieveEmployeeRecordRequestedGenerator);
}

function* updateErSectionRequestedWatcher() {
  yield takeLatest(updateErSectionRequested.type, updateErSectionRequestedGenerator);
}

export function* employeeRecordSagas() {
  yield all([fork(retrieveEmployeeRecordRequestedWatcher), fork(updateErSectionRequestedWatcher)]);
}
