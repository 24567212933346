import { styled, ListItemButton } from '@mui/material';

export const AudienceListItemButton = styled(ListItemButton)<{ selected: boolean }>(({ theme, selected }) => ({
  padding: '8px',
  borderLeft: `7px solid`,
  borderRadius: '4px',
  backgroundColor: selected ? theme.palette.customTheme.drawerBackground : 'transparent',
  borderColor: selected ? theme.palette.customTheme.focusItem : 'transparent',
  margin: selected ? 0 : undefined,
  transition: theme.transitions.create('border-color, background-color', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  })
}));
