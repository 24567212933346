import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { RetrieveGroupsRequest, RetrieveGroupsResponse } from '@thrivea/auth-client';
import { RootState } from '@app/store';
import { AudienceRole, RetrieveAudiencesByEmployeeIdRequest, RetrieveAudiencesByEmployeeIdResponse } from '@thrivea/networking-client';
import { CommentInfo, PostInfo, ProfileCategoryInfo } from '@features/abac';

export enum AudiencePermissions {
  DELETE_POST = 'DELETE_POST',
  PIN_POST = 'PIN_POST',
  UNPIN_POST = 'UNPIN_POST',
  PUBLISH_SHOUTOUT = 'PUBLISH_SHOUTOUT',
  COMMENT_ON_POST = 'COMMENT_ON_POST',
  DELETE_COMMENT = 'DELETE_COMMENT',
  EDIT_POST = 'EDIT_POST',
  EDIT_COMMENT = 'EDIT_COMMENT',
  VIEW_POST_ANALYTICS = 'VIEW_POST_ANALYTICS'
}

export enum GroupPermissions {
  ACCESS_NEWS_FEED = 'ACCESS_NEWS_FEED',

  ACCESS_SETTINGS = 'ACCESS_SETTINGS',
  ACCESS_SETTINGS_MY_COMPANY = 'ACCESS_SETTINGS_MY_COMPANY',
  ACCESS_SETTINGS_SITES = 'ACCESS_SETTINGS_SITES',
  ACCESS_SETTINGS_WP = 'ACCESS_SETTINGS_WP',

  ACCESS_PERMISSIONS = 'ACCESS_PERMISSIONS',
  CREATE_PERMISSION_GROUP = 'CREATE_PERMISSION_GROUP',
  EDIT_PERMISSION_GROUP = 'EDIT_PERMISSION_GROUP',
  DELETE_PERMISSION_GROUP = 'DELETE_PERMISSION_GROUP',
  EDIT_PERMISSION_GROUP_MEMBERS = 'EDIT_PERMISSION_GROUP_MEMBERS',

  ACCESS_AUDIENCES = 'ACCESS_AUDIENCES',
  CREATE_AUDIENCE_GROUP = 'CREATE_AUDIENCE_GROUP',
  EDIT_AUDIENCE_GROUP = 'EDIT_AUDIENCE_GROUP',
  DELETE_AUDIENCE_GROUP = 'DELETE_AUDIENCE_GROUP',
  EDIT_AUDIENCE_GROUP_MEMBERS = 'EDIT_AUDIENCE_GROUP_MEMBERS',

  VIEW_PROFILE = 'VIEW_PROFILE',
  EDIT_PROFILE = 'EDIT_PROFILE'
}

interface AbacRules {
  [key: string]: any;
}

interface AbacState {
  abacUser: AbacUser;
  abacRules: AbacRules;
  isInitialized: boolean;
}

export interface AbacUser {
  employeeId: string;
  // key: audienceId, value: AudienceRole
  audiencesWithRoles: Map<string, AudienceRole>;
  groupNames: string[];
  otherEmployeeIds: OtherEmployeeIds;
}

enum OtherEmployees {
  Everyone,
  None
}

type OtherEmployeeIds = string[] | OtherEmployees;

const editableProfileCategories = ['basic_info', 'personal_branding', 'personal', 'personal_contact_details'];

const ownPermissionMap = new Map<string, boolean | ((data: any, user: AbacUser) => boolean)>([
  [GroupPermissions.VIEW_PROFILE, (employeeId: string, user: AbacUser) => employeeId === user.employeeId],
  [
    GroupPermissions.EDIT_PROFILE,
    (profileCategoryInfo: ProfileCategoryInfo, user: AbacUser) =>
      profileCategoryInfo.employeeId === user.employeeId && editableProfileCategories.includes(profileCategoryInfo.categoryName)
  ]
]);

const otherPermissionMap = new Map<string, boolean | ((data: any, user: AbacUser) => boolean)>([
  [
    GroupPermissions.VIEW_PROFILE,
    (employeeId: string, user: AbacUser) => {
      if (user.otherEmployeeIds === OtherEmployees.Everyone) return true;
      if (user.otherEmployeeIds === OtherEmployees.None) return false;

      return user.otherEmployeeIds.includes(employeeId);
    }
  ],
  [
    GroupPermissions.EDIT_PROFILE,
    (employeeId: string, user: AbacUser) => {
      if (user.otherEmployeeIds === OtherEmployees.Everyone) return true;
      if (user.otherEmployeeIds === OtherEmployees.None) return false;

      return user.otherEmployeeIds.includes(employeeId);
    }
  ]
]);

export const ADMIN_GROUP_ID = '41ace84f-48e5-4d8a-8c5e-e284ea3ddf11';
export const EVERYONE_GROUP_ID = '41ace84f-48e5-4d8a-8c5e-e284ea3ddf12';

const initialState: AbacState = {
  abacRules: {
    [AudiencePermissions.DELETE_POST]: (postInfo: PostInfo, user: AbacUser) => {
      if (postInfo.authorId === user.employeeId && user.audiencesWithRoles.get(postInfo.audienceId)! === AudienceRole.CONTRIBUTOR) return true;

      return [AudienceRole.OWNER, AudienceRole.EDITOR].includes(user.audiencesWithRoles.get(postInfo.audienceId)!);
    },
    [AudiencePermissions.PUBLISH_SHOUTOUT]: (postInfo: PostInfo, user: AbacUser) => {
      return user.audiencesWithRoles.get(postInfo.audienceId)
        ? [AudienceRole.OWNER, AudienceRole.EDITOR, AudienceRole.CONTRIBUTOR].includes(user.audiencesWithRoles.get(postInfo.audienceId)!)
        : false;
    },
    [AudiencePermissions.COMMENT_ON_POST]: (audienceId: string, user: AbacUser) => {
      return user.audiencesWithRoles.get(audienceId)
        ? [AudienceRole.OWNER, AudienceRole.EDITOR, AudienceRole.CONTRIBUTOR, AudienceRole.COMMENTER].includes(user.audiencesWithRoles.get(audienceId)!)
        : false;
    },
    [AudiencePermissions.DELETE_COMMENT]: (commentInfo: CommentInfo, user: AbacUser) => {
      if (
        commentInfo.authorId === user.employeeId &&
        [AudienceRole.CONTRIBUTOR, AudienceRole.COMMENTER].includes(user.audiencesWithRoles.get(commentInfo.audienceId)!)
      )
        return true;

      return [AudienceRole.OWNER, AudienceRole.EDITOR].includes(user.audiencesWithRoles.get(commentInfo.audienceId)!);
    },
    [AudiencePermissions.EDIT_COMMENT]: (commentInfo: CommentInfo, user: AbacUser) => {
      return (
        commentInfo.authorId === user.employeeId && [AudienceRole.OWNER, AudienceRole.EDITOR].includes(user.audiencesWithRoles.get(commentInfo.audienceId)!)
      );
    },
    [AudiencePermissions.EDIT_POST]: (postInfo: PostInfo, user: AbacUser) => {
      return (
        postInfo.authorId === user.employeeId &&
        [AudienceRole.OWNER, AudienceRole.EDITOR, AudienceRole.CONTRIBUTOR].includes(user.audiencesWithRoles.get(postInfo.audienceId)!)
      );
    },
    [AudiencePermissions.VIEW_POST_ANALYTICS]: (postInfo: PostInfo, user: AbacUser) => {
      if (postInfo.authorId === user.employeeId && [AudienceRole.CONTRIBUTOR].includes(user.audiencesWithRoles.get(postInfo.audienceId)!)) return true;

      return [AudienceRole.OWNER, AudienceRole.EDITOR].includes(user.audiencesWithRoles.get(postInfo.audienceId)!);
    }
  },
  abacUser: { employeeId: '', groupNames: [], audiencesWithRoles: new Map<string, AudienceRole>(), otherEmployeeIds: [] },
  isInitialized: false
};

const permissionNamesMap = new Map<string, string>([
  ['VIEW_OWN_PROFILE', GroupPermissions.VIEW_PROFILE],
  ['VIEW_OTHER_PROFILE', GroupPermissions.VIEW_PROFILE],
  ['EDIT_OWN_PROFILE', GroupPermissions.EDIT_PROFILE],
  ['EDIT_OTHER_PROFILE', GroupPermissions.EDIT_PROFILE]
]);

export const abacSlice = createSlice({
  name: 'abac',
  initialState,
  reducers: {
    retrieveGroupsRequested: (state, action: PayloadAction<RetrieveGroupsRequest>) => {},
    retrieveGroupsSucceeded: (state, action: PayloadAction<RetrieveGroupsResponse>) => {
      const rules = {};

      for (const group of action.payload.groups) {
        if (group.id === ADMIN_GROUP_ID) {
          state.abacUser.otherEmployeeIds = OtherEmployees.Everyone;
        }

        const ownGroup = group.name + '_OWN';
        const otherGroup = group.name + '_OTHER';

        state.abacUser.groupNames = [...state.abacUser.groupNames, ownGroup, otherGroup];

        // Own Permissions
        const ownGroupPermissions = {};

        for (const ownPermission of group.ownPermissions) {
          const permissionName = permissionNamesMap.get(ownPermission.name) ?? ownPermission.name;
          ownGroupPermissions[permissionName] = ownPermissionMap.get(permissionName) ?? ownPermission.isEnabled;
        }

        rules[ownGroup] = ownGroupPermissions;

        // Permissions for Others
        const otherGroupPermissions = {};

        for (const otherPermission of group.otherPermissions) {
          const permissionName = permissionNamesMap.get(otherPermission.name) ?? otherPermission.name;
          otherGroupPermissions[permissionName] = otherPermissionMap.get(permissionName) ?? otherPermission.isEnabled;
        }

        rules[otherGroup] = otherGroupPermissions;
      }

      state.abacRules = { ...rules, ...initialState.abacRules };
      state.isInitialized = true;
    },
    retrieveGroupsFailed: () => {},
    retrieveAudiencesByEmployeeIdRequested: (state, action: PayloadAction<RetrieveAudiencesByEmployeeIdRequest>) => {},
    retrieveAudiencesByEmployeeIdSucceeded: (state, action: PayloadAction<RetrieveAudiencesByEmployeeIdResponse>) => {
      const audienceMap = new Map<string, AudienceRole>();

      for (const audienceWithRole of action.payload.audiencesWithRole) {
        audienceMap.set(audienceWithRole.audienceId, audienceWithRole.role);
      }

      state.abacUser.audiencesWithRoles = audienceMap;
    },
    retrieveAudiencesByEmployeeIdFailed: () => {},
    setAbacUserId: (state, action: PayloadAction<string>) => {
      state.abacUser.employeeId = action.payload;
    }
  }
});

export const selectAbacUser = (state: RootState) => state.abac.abacUser;
export const selectAbacRules = (state: RootState) => state.abac.abacRules;
export const selectIsInitialized = (state: RootState) => state.abac.isInitialized;

export const {
  retrieveGroupsRequested,
  retrieveGroupsSucceeded,
  retrieveGroupsFailed,
  retrieveAudiencesByEmployeeIdFailed,
  retrieveAudiencesByEmployeeIdRequested,
  retrieveAudiencesByEmployeeIdSucceeded,
  setAbacUserId
} = abacSlice.actions;
export default abacSlice.reducer;
