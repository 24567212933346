import { Button, ButtonProps, styled, typographyClasses } from '@mui/material';

export const AudienceButton = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.customTheme.focusItem,
  textDecoration: 'underline',
  minWidth: 'auto',
  padding: 0,
  margin: 0,
  backgroundColor: 'transparent',
  '&:hover': {
    backgroundColor: 'transparent'
  },
  [`& .${typographyClasses.root}`]: {
    fontWeight: 700
  }
}));
