import { styled, Button, ButtonProps, buttonClasses } from '@mui/material';

export const ActionButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.focusItem,
  color: theme.palette.common.white,
  borderRadius: '10px',
  padding: '12px 32px',
  [`&.${buttonClasses.disabled}`]: {
    opacity: 0.5,
    color: theme.palette.common.white
  },
  '&:hover': {
    backgroundColor: theme.palette.customTheme.focusItem
  }
}));
