import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import {
  GroupItem,
  RetrieveGroupByIdRequest,
  RetrieveGroupByIdResponse,
  RetrieveGroupItemsRequest,
  RetrieveGroupItemsResponse,
  UpdateGroupRequest
} from '@thrivea/auth-client';
import {
  EmployeeListItem,
  EmployeeResults,
  RetrieveEmployeesByIdsRequest,
  RetrieveEmployeesByQueryRequest,
  RetrieveEmployeesByQueryResponse,
  RetrieveEmployeesTotalCountBySelectedRequest,
  RetrieveEmployeesTotalCountBySelectedResponse
} from '@thrivea/organization-client';
import { RootState } from '@app/store';
import { ActionStatus } from '@shared/shared.model';

export interface PermissionGroupState {
  entities: {
    permissionGroups: {
      byId: { [key: string]: GroupItem };
      allIds: string[];
    };
    permissionGroupEmployees: {
      byId: { [key: string]: EmployeeListItem };
      initialIds: string[];
      addedIds: string[];
      removedIds: string[];
    };
    autocompleteItems: RetrieveEmployeesByQueryResponse;
  };
  ui: {
    permissionGroupsLoaded: ActionStatus;
    chosenPermissionGroupMembersTotalCount: number;
    retrievedPermissionGroupByIdStatus: ActionStatus;
    permissionGroupEmployeesStatus: ActionStatus;
    autocompleteItemsStatus: ActionStatus;
  };
  currentPermissionGroup: RetrieveGroupByIdResponse;
}

export interface PermissionMember {
  id: string;
  name: string;
  email: string;
  site: string;
  position: string;
  profilePictureUrl: string;
}

const initialState: PermissionGroupState = {
  entities: {
    permissionGroups: {
      byId: {},
      allIds: []
    },
    permissionGroupEmployees: {
      byId: {},
      initialIds: [],
      removedIds: [],
      addedIds: []
    },
    autocompleteItems: {} as RetrieveEmployeesByQueryResponse
  },
  ui: {
    permissionGroupsLoaded: ActionStatus.Idle,
    chosenPermissionGroupMembersTotalCount: 0,
    retrievedPermissionGroupByIdStatus: ActionStatus.Idle,
    permissionGroupEmployeesStatus: ActionStatus.Idle,
    autocompleteItemsStatus: ActionStatus.Idle
  },
  currentPermissionGroup: {} as RetrieveGroupByIdResponse
};

// TODO - Infinite scroll after creation of group is enabled

export const permissionGroupSlice = createSlice({
  name: 'permissionGroup',
  initialState,
  reducers: {
    retrievePermissionGroupsRequested: (state, action: PayloadAction<RetrieveGroupItemsRequest>) => {
      state.entities.permissionGroups.byId = {};
      state.entities.permissionGroups.allIds = [];
      state.ui.permissionGroupsLoaded = ActionStatus.Pending;
    },
    retrievePermissionGroupsSucceeded: (state, action: PayloadAction<RetrieveGroupItemsResponse>) => {
      const { groups } = action.payload;
      for (const group of groups) {
        state.entities.permissionGroups.byId[group.id] = group;
        state.entities.permissionGroups.allIds.push(group.id);
      }
      state.ui.permissionGroupsLoaded = ActionStatus.Idle;
    },
    retrievePermissionGroupsFailed: (state) => {
      state.ui.permissionGroupsLoaded = ActionStatus.Failed;
    },
    retrievePermissionGroupByIdRequested: (state, action: PayloadAction<RetrieveGroupByIdRequest>) => {
      state.ui.retrievedPermissionGroupByIdStatus = ActionStatus.Pending;
    },
    retrievePermissionGroupByIdSucceeded: (state, action: PayloadAction<RetrieveGroupByIdResponse>) => {
      state.currentPermissionGroup = action.payload;
      state.ui.retrievedPermissionGroupByIdStatus = ActionStatus.Idle;
    },
    retrievePermissionGroupByIdFailed: (state) => {
      state.ui.retrievedPermissionGroupByIdStatus = ActionStatus.Failed;
    },
    retrievePermissionGroupAutocompleteItemsRequested: (state, _action: PayloadAction<RetrieveEmployeesByQueryRequest>) => {
      state.ui.autocompleteItemsStatus = ActionStatus.Pending;
    },
    retrievePermissionGroupAutocompleteItemsSucceeded: (state, action: PayloadAction<RetrieveEmployeesByQueryResponse>) => {
      state.entities.autocompleteItems = action.payload;
      state.ui.autocompleteItemsStatus = ActionStatus.Idle;
    },
    retrievePermissionGroupAutocompleteItemsFailed: (state) => {
      state.entities.autocompleteItems = {} as RetrieveEmployeesByQueryResponse;
      state.ui.autocompleteItemsStatus = ActionStatus.Failed;
    },
    retrieveAudienceMembersRequested: (state) => {
      state.entities.autocompleteItems = {} as RetrieveEmployeesByQueryResponse;
    },
    retrieveAudienceMembersSucceeded: (state, action: PayloadAction<RetrieveEmployeesByQueryResponse>) => {
      state.entities.autocompleteItems = action.payload;
    },
    retrieveAudienceMembersFailed: (state) => {
      state.entities.autocompleteItems = {} as RetrieveEmployeesByQueryResponse;
    },
    retrievePermissionGroupSelectedEmployeesTotalCountRequested: (state, action: PayloadAction<RetrieveEmployeesTotalCountBySelectedRequest>) => {},
    retrievePermissionGroupSelectedEmployeesTotalCountSucceeded: (state, action: PayloadAction<RetrieveEmployeesTotalCountBySelectedResponse>) => {
      const { totalCount } = action.payload;
      state.ui.chosenPermissionGroupMembersTotalCount = totalCount;
    },
    retrievePermissionGroupSelectedEmployeesTotalCountFailed: (state) => {},
    retrievePermissionGroupMembersByIdsRequested: (state, action: PayloadAction<RetrieveEmployeesByIdsRequest>) => {
      state.entities.permissionGroupEmployees.byId = {};
      state.entities.permissionGroupEmployees.initialIds = [];
      state.entities.permissionGroupEmployees.addedIds = [];
      state.entities.permissionGroupEmployees.removedIds = [];
      state.ui.chosenPermissionGroupMembersTotalCount = 0;

      state.ui.permissionGroupEmployeesStatus = ActionStatus.Pending;
    },
    retrievePermissionGroupMembersByIdsSucceeded: (state, action: PayloadAction<EmployeeResults>) => {
      const { employees } = action.payload;

      for (const employee of employees) {
        state.entities.permissionGroupEmployees.byId[employee.employeeId] = employee;
        state.entities.permissionGroupEmployees.initialIds.push(employee.employeeId);
      }

      state.ui.permissionGroupEmployeesStatus = ActionStatus.Idle;
    },
    retrievePermissionGroupMembersByIdsFailed: (state) => {
      state.ui.permissionGroupEmployeesStatus = ActionStatus.Failed;
    },
    addEmployeesToPermissionGroup: (state, action: PayloadAction<EmployeeListItem[]>) => {
      const employees = action.payload;

      for (const employee of employees) {
        state.entities.permissionGroupEmployees.byId[employee.employeeId] = employee;
        state.entities.permissionGroupEmployees.addedIds.push(employee.employeeId);
      }
    },
    removeSingleEmployeeFromPermissionGroup: (state, action: PayloadAction<string>) => {
      state.entities.permissionGroupEmployees.removedIds = Array.from(new Set(state.entities.permissionGroupEmployees.removedIds).add(action.payload));
    },
    removeEmployeesFromPermissionGroup: (state, action: PayloadAction<string[]>) => {
      state.entities.permissionGroupEmployees.removedIds = Array.from(new Set([...state.entities.permissionGroupEmployees.removedIds, ...action.payload]));
    },
    updatePermissionGroupRequested: (state, action: PayloadAction<UpdateGroupRequest>) => {},
    updatePermissionGroupSucceeded: (state, action: PayloadAction<GroupItem>) => {
      state.entities.permissionGroups.byId[action.payload.id] = action.payload;
    },
    updatePermissionGroupFailed: (state) => {},
    cancelPermissionGroupEdit: (state) => {
      state.entities.permissionGroupEmployees.addedIds = [];
      state.entities.permissionGroupEmployees.removedIds = [];
    }
  }
});

export const selectPermissionGroupEmployeesById = (state: RootState) => state.permissionGroup.entities.permissionGroupEmployees.byId;
const selectInitialPermissionGroupEmployeeIds = (state: RootState) => state.permissionGroup.entities.permissionGroupEmployees.initialIds;
const selectAddedPermissionGroupEmployeeIds = (state: RootState) => state.permissionGroup.entities.permissionGroupEmployees.addedIds;
const selectRemovedPermissionGroupEmployeeIds = (state: RootState) => state.permissionGroup.entities.permissionGroupEmployees.removedIds;
export const selectPermissionGroupEmployeesStatus = (state: RootState) => state.permissionGroup.ui.permissionGroupEmployeesStatus;

export const selectChosenPermissionGroupEmployeeIds = createSelector(
  [selectInitialPermissionGroupEmployeeIds, selectAddedPermissionGroupEmployeeIds, selectRemovedPermissionGroupEmployeeIds],
  (initialIds, addedIds, removedIds) => Array.from(new Set([...initialIds, ...addedIds].filter((id) => !removedIds.includes(id))))
);

export const selectChosenPermissionGroupEmployees = createSelector(
  [selectChosenPermissionGroupEmployeeIds, selectPermissionGroupEmployeesById],
  (chosenEmployeeIds, byId) => chosenEmployeeIds.map((id) => byId[id])
);

export const selectHasMembersNotChanged = createSelector(
  [selectAddedPermissionGroupEmployeeIds, selectRemovedPermissionGroupEmployeeIds],
  (addedIds, removedIds) => addedIds.length === 0 && removedIds.length === 0
);

export const selectPermissionGroupsById = (state: RootState) => state.permissionGroup.entities.permissionGroups.byId;
export const selectPermissionGroupsIds = (state: RootState) => state.permissionGroup.entities.permissionGroups.allIds;
export const selectEmployeesTotalCount = (state: RootState) => state.permissionGroup.ui.chosenPermissionGroupMembersTotalCount;
export const selectPermissionGroupsAreLoaded = (state: RootState) => state.permissionGroup.ui.permissionGroupsLoaded;

export const selectAutocompleteItems = (state: RootState) => state.permissionGroup.entities.autocompleteItems;
export const selectPermissionGroupAutocompleteEmployees = (state: RootState) =>
  state.permissionGroup.entities.autocompleteItems.employeeResults?.employees || [];
export const selectPermissionGroupAutoCompleteItemsStatus = (state: RootState) => state.permissionGroup.ui.autocompleteItemsStatus;

export const selectCurrentPermissionGroup = (state: RootState) => state.permissionGroup.currentPermissionGroup;
export const selectRetrievedPermissionGroupByIdStatus = (state: RootState) => state.permissionGroup.ui.retrievedPermissionGroupByIdStatus;

export const selectPermissionItemById = (state: RootState, permissionGroupId: string) =>
  state.permissionGroup.entities.permissionGroups.byId[permissionGroupId];

export const {
  retrievePermissionGroupsRequested,
  retrievePermissionGroupsSucceeded,
  retrievePermissionGroupsFailed,
  retrieveAudienceMembersRequested,
  retrieveAudienceMembersSucceeded,
  retrieveAudienceMembersFailed,
  retrievePermissionGroupAutocompleteItemsRequested,
  retrievePermissionGroupAutocompleteItemsSucceeded,
  retrievePermissionGroupAutocompleteItemsFailed,
  retrievePermissionGroupSelectedEmployeesTotalCountRequested,
  retrievePermissionGroupSelectedEmployeesTotalCountSucceeded,
  retrievePermissionGroupSelectedEmployeesTotalCountFailed,
  retrievePermissionGroupByIdRequested,
  retrievePermissionGroupByIdSucceeded,
  retrievePermissionGroupByIdFailed,
  retrievePermissionGroupMembersByIdsRequested,
  retrievePermissionGroupMembersByIdsSucceeded,
  retrievePermissionGroupMembersByIdsFailed,
  addEmployeesToPermissionGroup,
  removeSingleEmployeeFromPermissionGroup,
  removeEmployeesFromPermissionGroup,
  updatePermissionGroupRequested,
  updatePermissionGroupSucceeded,
  updatePermissionGroupFailed,
  cancelPermissionGroupEdit
} = permissionGroupSlice.actions;

export default permissionGroupSlice.reducer;
