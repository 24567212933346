import React, { Dispatch, SetStateAction, forwardRef, useCallback, useState } from 'react';
import {
  FormControlLabel,
  Avatar,
  Paper,
  PaperProps,
  ListItem,
  typographyClasses,
  Stack,
  PopperProps,
  Box,
  autocompleteClasses,
  Typography,
  Button
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {
  ChevronDownIcon,
  HighlightText,
  PictureSizeSuffix,
  QuillInstance,
  RowCenterStack,
  StyledGroupWrapper,
  StyledCountOpenDrawerButton,
  StyledGroupCheckbox
} from '@/shared';
import { useAppDispatch, useAppSelector } from '@app/hooks';
import { retrieveEmployeeBasicInfoRequested } from '@features/admin-settings';
import { EmployeeListItem, RetrieveEmployeeBasicInfoRequest } from '@thrivea/organization-client';
import { selectEmployeeProfileAndCoverReadSasToken } from '@features/employee-profile';
import { initials, pictureUrl } from '@/utils';
import Quill, { Delta } from 'quill/core';
import { EmployeeBlot } from '@shared/other/employee-blot';
import { selectMentionEmployees, EmployeesAutocomplete, EmployeesInput } from '@features/homepage';
import { useTranslation } from 'react-i18next';

Quill.register(EmployeeBlot);

interface MentionAutocompleteProps {
  handleMentionToolbarClose: () => void;
  setText: Dispatch<SetStateAction<string>>;
}

const CustomPaper = forwardRef<HTMLDivElement, PaperProps & { selectedOptions: EmployeeListItem[] | undefined }>(
  ({ children, selectedOptions, ...props }, ref) => (
    <Paper
      {...props}
      ref={ref}
      sx={{
        backgroundColor: 'transparent',
        px: 1
      }}
      elevation={0}
    >
      {children}
    </Paper>
  )
);

const CustomPopper = forwardRef<HTMLDivElement, PopperProps>(function CustomPopper(props, ref) {
  const { children, open, anchorEl, ...other } = props;

  const getChildren = () => {
    if (typeof children === 'function') {
      return children({ placement: 'bottom-start' });
    }
    return children;
  };

  return (
    <Box
      ref={ref}
      sx={{
        display: open ? 'block' : 'none',
        width: anchorEl ? (anchorEl as HTMLElement).clientWidth : '100%',
        [`&.${autocompleteClasses.popper}`]: {
          position: 'static',
          [`& .${autocompleteClasses.noOptions}`]: {
            display: 'none'
          }
        }
      }}
      {...other}
    >
      {getChildren()}
    </Box>
  );
});

export const MentionAutocomplete = forwardRef<QuillInstance, MentionAutocompleteProps>(({ handleMentionToolbarClose, setText }, ref) => {
  const { t } = useTranslation(['common', 'homepage']);
  const dispatch = useAppDispatch();

  const employeeProfileSasToken = useAppSelector(selectEmployeeProfileAndCoverReadSasToken);
  const mentionEmployees = useAppSelector(selectMentionEmployees);

  const [selectedOptions, setSelectedOptions] = useState<EmployeeListItem[]>([]);
  const [inputValue, setInputValue] = useState('');

  const handleChange = (event: React.ChangeEvent<{}>, newValue: EmployeeListItem[]) => {
    event.preventDefault();
    setSelectedOptions(newValue);
  };

  const handleEmployeeDrawerOpen = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, employeeId: string) => {
    e.stopPropagation();
    e.preventDefault();
    dispatch(
      retrieveEmployeeBasicInfoRequested(
        new RetrieveEmployeeBasicInfoRequest({
          employeeId
        })
      )
    );
  };

  const handleMentionClick = useCallback(() => {
    const quill = (ref as React.MutableRefObject<Quill | null>).current;

    if (quill) {
      const range = quill.getSelection(true);
      let position = range ? range.index : 0; // Initialize position to current cursor position or start

      selectedOptions.forEach((employee, index) => {
        // Check if we need to add a comma to the last mention
        if (index === 0 && range && range.index > 0) {
          const previousChar = quill.getText(position - 1, 1);
          if (previousChar && previousChar !== ' ' && previousChar !== '\n') {
            quill.deleteText(position - 1, 1);
            quill.insertText(position - 1, ', ');
            position += 2; // Increment position for the inserted comma and space
          }
        }

        // Construct the mention Delta
        const mentionDelta = new Delta().retain(position).insert({ employee: { id: employee.employeeId, name: employee.displayName } });

        // If it's not the last employee, insert a comma and a space after the mention
        if (index < selectedOptions.length - 1) {
          mentionDelta.insert(', ');
          position += 2; // Increment position for the inserted comma and space
        } else {
          mentionDelta.insert(' ');
          position += 1; // Increment position for the inserted space
        }

        // Apply the mention Delta to the editor
        quill.updateContents(mentionDelta);
        position += 1; // Increment position for the next mention
      });

      // Update the editor's text with semantic HTML
      setText(quill.root.innerHTML);

      // Set the cursor position after all mentions
      quill.setSelection(position, Quill.sources.SILENT);
    }

    handleMentionToolbarClose();
  }, [selectedOptions, ref, handleMentionToolbarClose, setText]);

  return (
    <Stack
      sx={{
        mb: 2,
        backgroundColor: (theme) => theme.palette.grey[600]
      }}
    >
      <StyledGroupWrapper expanded={true}>
        <EmployeesAutocomplete
          multiple
          open
          disableCloseOnSelect
          disableClearable
          options={inputValue.length < 3 ? [] : mentionEmployees}
          getOptionLabel={(option) => option.displayName}
          popupIcon={<ChevronDownIcon />}
          PopperComponent={CustomPopper}
          renderInput={(params) => {
            const { InputProps, ...restParams } = params;
            return (
              <EmployeesInput
                {...restParams}
                InputProps={{
                  ...InputProps,
                  startAdornment: (
                    <>
                      <Typography sx={{ color: (theme) => theme.palette.customTheme.focusItem }}>@</Typography>
                      {InputProps.startAdornment}
                    </>
                  ),
                  endAdornment: (
                    <>
                      {InputProps.endAdornment}
                      <Button onClick={handleMentionClick} variant="contained">
                        Mention
                      </Button>
                    </>
                  )
                }}
                variant="outlined"
                placeholder={t('mention_search_employees', { ns: 'homepage' })}
              />
            );
          }}
          renderOption={(props, option, { selected }) => (
            <ListItem {...props} key={option.employeeId}>
              <FormControlLabel
                control={<StyledGroupCheckbox checked={selected} />}
                label={
                  <Grid container>
                    <Grid size={4} alignContent="center">
                      <RowCenterStack gap={1}>
                        <Avatar
                          onClick={(e) => handleEmployeeDrawerOpen(e, option.employeeId)}
                          variant="rounded"
                          src={pictureUrl(option.profilePictureUrl, employeeProfileSasToken, PictureSizeSuffix.sm)}
                          slotProps={{
                            img: {
                              loading: 'lazy',
                              width: 36,
                              height: 36
                            }
                          }}
                          sx={{
                            width: 36,
                            height: 36,
                            border: `1px solid #000000`,
                            borderRadius: 2
                          }}
                        >
                          {initials(option.displayName)}
                        </Avatar>
                        <Stack>
                          <HighlightText color="rgba(47, 188, 196, 0.40)" bold text={option.displayName} highlight={inputValue} />
                          <HighlightText color="rgba(47, 188, 196, 0.40)" text={option.jobTitle} highlight={inputValue} />
                        </Stack>
                      </RowCenterStack>
                    </Grid>
                    <Grid size={4} alignContent="center">
                      <HighlightText color="rgba(47, 188, 196, 0.40)" text={option.emailAddress} highlight={inputValue} />
                    </Grid>
                    <Grid size={4} alignContent="center">
                      <StyledCountOpenDrawerButton>
                        <HighlightText color="rgba(47, 188, 196, 0.40)" text={option.siteName} highlight={inputValue} />
                      </StyledCountOpenDrawerButton>
                    </Grid>
                  </Grid>
                }
                sx={{
                  width: '100%',
                  [`& .${typographyClasses.root}`]: {
                    width: '100%'
                  }
                }}
              />
            </ListItem>
          )}
          onChange={handleChange}
          inputValue={inputValue}
          renderTags={(tagValue, _getTagProps) => {
            return tagValue.map((option, _index) => (
              <RowCenterStack gap={2} key={option.employeeId} sx={{ ml: 2 }}>
                <Avatar
                  onClick={(e) => handleEmployeeDrawerOpen(e, option.employeeId)}
                  variant="rounded"
                  src={pictureUrl(option.profilePictureUrl, employeeProfileSasToken, PictureSizeSuffix.sm)}
                  slotProps={{
                    img: {
                      loading: 'lazy',
                      width: 36,
                      height: 36
                    }
                  }}
                  sx={{
                    width: 36,
                    height: 36,
                    border: `1px solid #000000`,
                    borderRadius: 2
                  }}
                >
                  {initials(option.displayName)}
                </Avatar>
                <Stack>
                  <Typography sx={{ fontWeight: 700 }}>{option.displayName}</Typography>
                  <Typography>{option.jobTitle}</Typography>
                </Stack>
              </RowCenterStack>
            ));
          }}
          onInputChange={(_event, newInputValue) => setInputValue(newInputValue)}
          isOptionEqualToValue={(option, value) => option.employeeId === value.employeeId}
          PaperComponent={(props) => <CustomPaper {...props} selectedOptions={selectedOptions} />}
        />
      </StyledGroupWrapper>
    </Stack>
  );
});
