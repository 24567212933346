import { forwardRef } from 'react';
import { styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';

interface StyledTooltipProps extends TooltipProps {
  customWidth?: number;
  parentColor?: boolean;
}

export const TableTooltip = styled(
  forwardRef(({ className, customWidth, ...props }: StyledTooltipProps, ref) => <Tooltip ref={ref} {...props} classes={{ popper: className }} />)
)(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    width: 'fit-content',
    minHeight: 30,
    fontSize: 16,
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
    padding: '8px',
    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));
