import { styled, Button, ButtonProps } from '@mui/material';

export const PostAnalyticsButton = styled(Button)<ButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.contColor,
  padding: '3px 7px',
  minWidth: 126,
  fontSize: 12,
  wordWrap: 'break-word',
  borderRadius: '8px',
  color: theme.palette.common.white,
  '&:hover': {
    backgroundColor: theme.palette.customTheme.focusItem
  }
}));
