import { Chip, ChipProps, styled } from '@mui/material';

export const AssetsExpiryChip = styled(Chip)<ChipProps>(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  color: '#E62E05',
  padding: '2px 8px',
  border: '2px solid',
  borderColor: '#E62E05',
  borderRadius: '16px',
  fontSize: 12,
  fontWeight: 500,
  lineHeight: '18px'
}));
