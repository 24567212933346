import React from 'react';

interface TimeIconAttributes {
  width?: number;
  height?: number;
  color?: string;
}

export const TimeIcon: React.FC<TimeIconAttributes> = ({ width = 25, height = 26, color = '#251D38' }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 25 26">
      <path
        fill={color}
        d="M20.567 19.985a9.75 9.75 0 1 0-16.095 0l-3 3a.75.75 0 0 0 0 1.065.748.748 0 0 0 1.065 0l2.865-2.873a9.704 9.704 0 0 0 14.205 0l2.865 2.873a.75.75 0 0 0 1.065 0 .751.751 0 0 0 0-1.065l-2.97-3Zm-8.048 2.76a8.25 8.25 0 1 1 0-16.501 8.25 8.25 0 0 1 0 16.5ZM2.477 8.787a4.126 4.126 0 0 1 5.835-5.835.75.75 0 0 1-1.043 1.043 2.685 2.685 0 0 0-3.75 0 2.625 2.625 0 0 0 0 3.75.75.75 0 0 1-.244 1.219.75.75 0 0 1-.288.056.75.75 0 0 1-.51-.233Zm14.25-4.792a.75.75 0 0 1 0-1.058 4.126 4.126 0 0 1 5.835 5.835.749.749 0 0 1-.533.218.75.75 0 0 1-.532-.218.75.75 0 0 1 .022-1.027 2.625 2.625 0 0 0 0-3.75 2.685 2.685 0 0 0-3.75 0 .75.75 0 0 1-1.042 0Zm.187 13.117a.75.75 0 0 1-.645.383.75.75 0 0 1-.382-.105l-3.75-2.25a.75.75 0 0 1-.368-.645v-6a.75.75 0 0 1 1.5 0v5.572l3.383 2.033a.75.75 0 0 1 .262 1.027v-.015Z"
      />
    </svg>
  );
};
