import React, { useId, useMemo, useState } from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Modal,
  Typography,
  IconButton,
  Stack,
  TablePagination,
  Box,
  Autocomplete,
  FormControl,
  ListItem
} from '@mui/material';
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import { addNewWorkEligibilityItem, createWorkEligibilitySchema, selectWorkEligibilityTableData, selectWorkEligibilityTypes } from '@features/employee-profile';
import {
  AddIcon,
  AlertIcon,
  ChevronDownIcon,
  StyledAddNewRowButton,
  CancelButton,
  StyledUpdateButton,
  RowCenterStack,
  StyledModalContent,
  StyledTransparentFilledInput,
  StyledTransparentFilledInputDatePicker
} from '@/shared';
import { useAppSelector } from '@app/hooks';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { WorkEligibilityItem } from '@thrivea/organization-client';
import { useTranslation } from 'react-i18next';
import { Controller, useForm } from 'react-hook-form';
import { DateTime } from 'luxon';
import { useDispatch } from 'react-redux';
import { zodResolver } from '@hookform/resolvers/zod';
import { snakeCase } from 'lodash';
import { AllowedTo } from 'react-abac';
import { GroupPermissions, ProfileCategoryInfo } from '@features/abac';

interface WorkEligibilityCategoryTableProps {
  handleSetEditable: () => void;
  employeeId: string;
}

export const WorkEligibilityCategoryTable = ({ handleSetEditable, employeeId }: WorkEligibilityCategoryTableProps) => {
  const id = useId();
  const { t } = useTranslation(['common', 'employee_profile']);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const dispatch = useDispatch();
  const workEligibilityTableData = useAppSelector(selectWorkEligibilityTableData);
  const workEligibilityTypes = useAppSelector(selectWorkEligibilityTypes);
  const workEligibilitySchema = useMemo(() => createWorkEligibilitySchema(t), [t]);

  const {
    control,
    handleSubmit,
    reset,
    setValue,
    formState: { isValid }
  } = useForm<WorkEligibilityItem>({
    mode: 'all',
    resolver: zodResolver(workEligibilitySchema),
    defaultValues: {
      workEligibilityTypeId: '',
      docIds: [],
      workEligibilityExpirationDate: '',
      workEligibilityNumber: '',
      workEligibilityIssuedBy: '',
      workEligibilityValidFromDate: '',
      workEligibilityApplicationDate: ''
    }
  });

  const handleChangePage = (_event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleModalOpen = () => {
    handleSetEditable();
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    reset({
      workEligibilityTypeId: '',
      docIds: [],
      workEligibilityExpirationDate: '',
      workEligibilityNumber: '',
      workEligibilityIssuedBy: '',
      workEligibilityValidFromDate: '',
      workEligibilityApplicationDate: ''
    });
  };

  const handleRowEdit = (event: React.MouseEvent<HTMLElement>, id: string) => {
    event.stopPropagation();
    // setActiveEditModal(id);
  };

  const onSubmit = (data: WorkEligibilityItem) => {
    dispatch(addNewWorkEligibilityItem(data));
    handleModalClose();
  };

  return (
    <Stack
      gap={2}
      sx={{
        height: '100%',
        justifyContent: 'stretch',
        padding: 0
      }}
    >
      <Paper
        elevation={0}
        sx={{
          width: '100%',
          overflow: 'hidden',
          '&.MuiPaper-root': {
            backgroundColor: (theme) => (workEligibilityTableData.length === 0 ? theme.palette.customTheme.drawerBackground : 'transparent')
          }
        }}
      >
        <Stack
          gap={2}
          sx={{
            flex: 1,
            justifyContent: 'stretch'
          }}
        >
          <TableContainer sx={{ maxHeight: 440 }} tabIndex={0}>
            {workEligibilityTableData.length === 0 && (
              <Stack
                sx={{
                  width: '100%',
                  height: 250,
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '16px',
                  '& svg': {
                    width: 24,
                    height: 24
                  }
                }}
              >
                <AlertIcon />
                <Typography>{t('empty_table', { ns: 'employee_profile' })}</Typography>
                <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'workEligibility' } as ProfileCategoryInfo}>
                  <StyledAddNewRowButton variant="outlined" endIcon={<AddIcon />} onClick={handleModalOpen}>
                    {t('add_new_row', { ns: 'common' })}
                  </StyledAddNewRowButton>
                </AllowedTo>
              </Stack>
            )}
            {workEligibilityTableData.length !== 0 && (
              <>
                <Table stickyHeader aria-label="sticky table">
                  <TableHead>
                    <TableRow>
                      <TableCell>{t('work_eligibility_type_id', { ns: 'employee_profile' })}</TableCell>
                      <TableCell>{t('work_eligibility_expiration_date', { ns: 'employee_profile' })}</TableCell>
                      <TableCell>{t('work_eligibility_number', { ns: 'employee_profile' })}</TableCell>
                      <TableCell>{t('work_eligibility_issued_by', { ns: 'employee_profile' })}</TableCell>
                      <TableCell>{t('work_eligibility_valid_from_date', { ns: 'employee_profile' })}</TableCell>
                      <TableCell>{t('work_eligibility_application_date', { ns: 'employee_profile' })}</TableCell>
                      {/* <TableCell
                        sx={{
                          position: 'sticky',
                          right: 0,
                          backgroundColor: (theme) => theme.palette.common.white,
                          minWidth: '90px',
                          height: '100%',
                          boxShadow: '-5px 0px 3px 0px rgba(0,0,0,0.12)'
                        }}
                      /> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {workEligibilityTableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, rowIndex) => (
                      <TableRow hover key={rowIndex}>
                        <TableCell>{workEligibilityTypes.find((we) => we.id === row.workEligibilityTypeId)?.name}</TableCell>
                        <TableCell>{row.workEligibilityExpirationDate}</TableCell>
                        <TableCell>{row.workEligibilityNumber}</TableCell>
                        <TableCell>{row.workEligibilityIssuedBy}</TableCell>
                        <TableCell>{row.workEligibilityValidFromDate}</TableCell>
                        <TableCell>{row.workEligibilityApplicationDate}</TableCell>
                        {/* TODO - Implement after adding edit/delete actions */}
                        {/* <TableCell
                          sx={{
                            position: 'sticky',
                            right: 0,
                            backgroundColor: (theme) => theme.palette.common.white,
                            minWidth: '90px',
                            height: '100%',
                            boxShadow: '-5px 0px 3px 0px rgba(0,0,0,0.12)',
                            textAlign: 'center'
                          }}
                        >
                          <IsolatedMenu id={crypto.randomUUID()} handleRowEdit={(e) => handleRowEdit(e, crypto.randomUUID())} />
                        </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 20]}
                  component="div"
                  count={workEligibilityTableData!.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
                <AllowedTo perform={GroupPermissions.EDIT_PROFILE} data={{ employeeId, categoryName: 'workEligibility' } as ProfileCategoryInfo}>
                  <StyledAddNewRowButton
                    variant="outlined"
                    endIcon={<AddIcon />}
                    onClick={handleModalOpen}
                    sx={{
                      ml: 2,
                      mb: 2
                    }}
                  >
                    {t('add_new_row', { ns: 'common' })}
                  </StyledAddNewRowButton>
                </AllowedTo>
              </>
            )}
          </TableContainer>
        </Stack>
        <Modal
          open={isModalOpen}
          onClose={handleModalClose}
          sx={{
            background: 'rgba(217, 217, 217, 0.60)',
            backdropFilter: 'blur(10px)'
          }}
        >
          <StyledModalContent
            sx={{
              position: 'relative',
              justifyContent: 'center',
              alignItems: 'center',
              minWidth: {
                xs: 'auto',
                lg: '1316px'
              },
              height: {
                xs: '80%',
                lg: 'auto'
              },
              padding: '120px',
              display: 'flex',
              borderRadius: '20px',
              boxShadow: '0px 5px 12px 0px rgba(0, 0, 0, 0.10)'
            }}
          >
            <Typography
              variant="h3"
              sx={{
                mb: 3
              }}
            >
              {t('add_new_work_eligibility', { ns: 'employee_profile' })}
            </Typography>
            <Grid
              container
              columnSpacing={2}
              rowGap={2}
              sx={{
                mt: 2,
                mb: 4
              }}
            >
              <Box
                component="form"
                onSubmit={handleSubmit(onSubmit)}
                sx={{
                  width: '100%'
                }}
              >
                <Grid
                  container
                  spacing={2}
                  sx={{
                    padding: 2
                  }}
                >
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="workEligibilityTypeId"
                      control={control}
                      rules={{
                        required: true
                      }}
                      render={({ field, fieldState }) => (
                        <Autocomplete
                          popupIcon={<ChevronDownIcon />}
                          disableClearable
                          limitTags={2}
                          options={workEligibilityTypes}
                          getOptionLabel={(option) => option.name}
                          onChange={(_, value, reason) => {
                            if (reason === 'blur' && !value) {
                              setValue('workEligibilityTypeId', '');
                            } else {
                              field.onChange(value!.id);
                            }
                          }}
                          renderInput={(params) => (
                            <StyledTransparentFilledInput
                              {...params}
                              id={id + field.name}
                              label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                              error={!!fieldState.error}
                              helperText={fieldState.error?.message}
                              required
                              onBlur={() => field.onBlur()}
                            />
                          )}
                          renderOption={(props, option) => (
                            <ListItem {...props} key={crypto.randomUUID()}>
                              {option.name}
                            </ListItem>
                          )}
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="workEligibilityExpirationDate"
                      control={control}
                      rules={{
                        required: true
                      }}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth>
                          <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker
                              {...field}
                              className="MuiDate-root"
                              format="yyyy-MM-dd"
                              value={field.value ? DateTime.fromISO(field.value) : null}
                              disableFuture
                              slots={{ textField: StyledTransparentFilledInputDatePicker }}
                              slotProps={{
                                textField: {
                                  id: id + field.name,
                                  placeholder: 'YYYY-MM-DD',
                                  variant: 'filled',
                                  label: t(snakeCase(field.name), { ns: 'employee_profile' }),
                                  error: !!fieldState.error,
                                  helperText: fieldState.error?.message,
                                  required: true,
                                  fullWidth: true,
                                  InputProps: {
                                    disableUnderline: true,
                                    required: true,
                                    onBlur: () => {
                                      field.onBlur();
                                    }
                                  },
                                  InputLabelProps: {
                                    shrink: true
                                  }
                                }
                              }}
                              onChange={(date: DateTime | null) => {
                                if (date) {
                                  const jsDate = date.toJSDate();
                                  const stringDate = DateTime.fromJSDate(jsDate).toFormat('yyyy-MM-dd');
                                  field.onChange(stringDate);
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="workEligibilityNumber"
                      control={control}
                      rules={{
                        required: true
                      }}
                      render={({ field, fieldState }) => (
                        <StyledTransparentFilledInput
                          {...field}
                          required
                          id={id + field.name}
                          label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={(e) => {
                            const val = e.target.value;
                            if (val.match(/[^0-9]/)) {
                              return e.preventDefault();
                            }
                            field.onChange(e);
                          }}
                          inputProps={{ inputMode: 'numeric' }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="workEligibilityIssuedBy"
                      control={control}
                      rules={{
                        required: true
                      }}
                      render={({ field, fieldState }) => (
                        <StyledTransparentFilledInput
                          {...field}
                          id={id + field.name}
                          label={t(snakeCase(field.name), { ns: 'employee_profile' })}
                          error={!!fieldState.error}
                          helperText={fieldState.error?.message}
                          onChange={(e) => field.onChange(e)}
                          required
                        />
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="workEligibilityValidFromDate"
                      control={control}
                      rules={{
                        required: true
                      }}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth>
                          <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker
                              {...field}
                              className="MuiDate-root"
                              format="yyyy-MM-dd"
                              value={field.value ? DateTime.fromISO(field.value) : null}
                              disableFuture
                              slots={{ textField: StyledTransparentFilledInputDatePicker }}
                              slotProps={{
                                textField: {
                                  id: id + field.name,
                                  placeholder: 'YYYY-MM-DD',
                                  variant: 'filled',
                                  label: t(snakeCase(field.name), { ns: 'employee_profile' }),
                                  error: !!fieldState.error,
                                  helperText: fieldState.error?.message,
                                  required: true,
                                  fullWidth: true,
                                  InputProps: {
                                    disableUnderline: true,
                                    required: true,
                                    onBlur: () => {
                                      field.onBlur();
                                    }
                                  },
                                  InputLabelProps: {
                                    shrink: true
                                  }
                                }
                              }}
                              onChange={(date: DateTime | null) => {
                                if (date) {
                                  const jsDate = date.toJSDate();
                                  const stringDate = DateTime.fromJSDate(jsDate).toFormat('yyyy-MM-dd');
                                  field.onChange(stringDate);
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      )}
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6, lg: 4 }}>
                    <Controller
                      name="workEligibilityApplicationDate"
                      control={control}
                      rules={{
                        required: true
                      }}
                      render={({ field, fieldState }) => (
                        <FormControl fullWidth>
                          <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker
                              {...field}
                              className="MuiDate-root"
                              format="yyyy-MM-dd"
                              value={field.value ? DateTime.fromISO(field.value) : null}
                              disableFuture
                              slots={{ textField: StyledTransparentFilledInputDatePicker }}
                              slotProps={{
                                textField: {
                                  id: id + field.name,
                                  placeholder: 'YYYY-MM-DD',
                                  variant: 'filled',
                                  label: t(snakeCase(field.name), { ns: 'employee_profile' }),
                                  error: !!fieldState.error,
                                  helperText: fieldState.error?.message,
                                  required: true,
                                  fullWidth: true,
                                  InputProps: {
                                    disableUnderline: true,
                                    required: true,
                                    onBlur: () => {
                                      field.onBlur();
                                    }
                                  },
                                  InputLabelProps: {
                                    shrink: true
                                  }
                                }
                              }}
                              onChange={(date: DateTime | null) => {
                                if (date) {
                                  const jsDate = date.toJSDate();
                                  const stringDate = DateTime.fromJSDate(jsDate).toFormat('yyyy-MM-dd');
                                  field.onChange(stringDate);
                                }
                              }}
                            />
                          </LocalizationProvider>
                        </FormControl>
                      )}
                    />
                  </Grid>
                </Grid>
                <RowCenterStack
                  gap={2}
                  sx={{
                    justifyContent: 'flex-end'
                  }}
                >
                  <CancelButton variant="outlined" onClick={handleModalClose}>
                    {t('cancel', { ns: 'common' })}
                  </CancelButton>
                  <StyledUpdateButton type="submit" variant="contained" disabled={!isValid}>
                    {t('update', { ns: 'common' })}
                  </StyledUpdateButton>
                </RowCenterStack>
              </Box>
            </Grid>
            <IconButton
              sx={{
                position: 'absolute',
                top: 10,
                right: 10,
                zIndex: 1
              }}
              onClick={handleModalClose}
            >
              <CloseIcon />
            </IconButton>
          </StyledModalContent>
        </Modal>
      </Paper>
    </Stack>
  );
};
