import { TextField, TextFieldProps, inputBaseClasses, styled } from '@mui/material';

export const AdminSettingsSearch = styled(TextField)<TextFieldProps>(({ theme }) => ({
  [`& .${inputBaseClasses.root}`]: {
    width: '100%',
    backgroundColor: theme.palette.common.white,
    borderRadius: '4px',
    padding: '0 11px',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    }),
    '& fieldset': {
      borderWidth: 1,
      borderColor: theme.palette.customTheme.contColor,
      transition: theme.transitions.create('border-color', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      '& svg': {
        fill: theme.palette.customTheme.contColor
      }
    },
    [`&.${inputBaseClasses.focused}`]: {
      width: '100%',
      border: 0,
      '& fieldset': {
        borderWidth: 1,
        borderColor: theme.palette.secondary.main
      },
      '& svg': {
        fill: theme.palette.secondary.main
      }
    }
  },
  [`& .${inputBaseClasses.input}`]: {
    height: 40,
    padding: 0
  }
}));
