import { IconButton, IconButtonProps, styled } from '@mui/material';

export const FilterButton = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  backgroundColor: theme.palette.customTheme.primaryA,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '12px',
  height: 43,
  borderRadius: 8
}));
