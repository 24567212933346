import { styled, filledInputClasses, inputAdornmentClasses, svgIconClasses, inputBaseClasses, formControlClasses } from '@mui/material';
import { StyledFilledInput } from '@/shared';

export const PermissionMembersInput = styled(StyledFilledInput, {
  shouldForwardProp: (prop) => prop !== 'width'
})<{
  width?: number;
}>(({ width = '100%' }) => ({
  [`& .${filledInputClasses.root}`]: {
    border: 0,
    backgroundColor: 'inherit',
    borderColor: 'transparent',
    paddingTop: 0,
    [`& .${inputAdornmentClasses.root}`]: {
      [`& .${svgIconClasses.root}`]: {
        width: 24,
        height: 24
      }
    },
    '&:hover': {
      backgroundColor: 'inherit'
    }
  },
  [`& .${filledInputClasses.input}`]: {
    '&::placeholder': {
      textFillColor: 'rgba(37, 29, 56, 0.45)',
      fontSize: 12
    }
  },
  [`& .${inputBaseClasses.focused}.${filledInputClasses.root}`]: {
    backgroundColor: 'inherit',
    borderColor: 'transparent'
  },
  [`&.${formControlClasses.root}`]: {
    width: width
  }
}));
