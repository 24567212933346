import React, { forwardRef } from 'react';
import { useMediaQuery, useTheme, Typography, Stack, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface ProfileActionsFieldsProps {
  scrollToSection: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, sectionScrollLabelID: string) => void;
  categoryButtonRefs: React.MutableRefObject<Record<string, React.RefObject<HTMLButtonElement>>>;
}

interface Field {
  name: string;
  scrollId: number;
  sectionScrollLabelID: string;
}

const fieldsList: Field[] = [
  {
    name: 'Overview',
    scrollId: 1,
    sectionScrollLabelID: 'overview'
  },
  {
    name: 'Basic info',
    scrollId: 2,
    sectionScrollLabelID: 'basic_info'
  },
  {
    name: 'Personal branding',
    scrollId: 3,
    sectionScrollLabelID: 'personal_branding'
  },
  {
    name: 'Personal',
    scrollId: 4,
    sectionScrollLabelID: 'personal'
  },
  {
    name: 'Personal contact details',
    scrollId: 5,
    sectionScrollLabelID: 'personal_contact_details'
  },
  {
    name: 'Identification',
    scrollId: 6,
    sectionScrollLabelID: 'identification'
  },
  {
    name: 'Work',
    scrollId: 7,
    sectionScrollLabelID: 'work'
  },
  {
    name: 'Work contact details',
    scrollId: 8,
    sectionScrollLabelID: 'work_contact_details'
  },
  {
    name: 'Address',
    scrollId: 9,
    sectionScrollLabelID: 'address'
  },
  {
    name: 'Home',
    scrollId: 10,
    sectionScrollLabelID: 'home'
  },
  {
    name: 'About',
    scrollId: 11,
    sectionScrollLabelID: 'about'
  },
  {
    name: 'Financial',
    scrollId: 12,
    sectionScrollLabelID: 'financial'
  },
  {
    name: 'Work eligibility',
    scrollId: 13,
    sectionScrollLabelID: 'work_eligibility'
  },
  {
    name: 'Employment',
    scrollId: 14,
    sectionScrollLabelID: 'employment'
  },
  {
    name: 'Payroll',
    scrollId: 15,
    sectionScrollLabelID: 'payroll'
  },
  {
    name: 'Emergency',
    scrollId: 16,
    sectionScrollLabelID: 'emergency'
  },
  {
    name: 'User data',
    scrollId: 17,
    sectionScrollLabelID: 'user_data'
  },
  {
    name: 'EEO',
    scrollId: 18,
    sectionScrollLabelID: 'eeo'
  }
];

export const ProfileActionsFields = forwardRef<HTMLDivElement, ProfileActionsFieldsProps>(({ scrollToSection, categoryButtonRefs }, ref) => {
  const { t } = useTranslation(['common']);
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  return (
    <Stack
      ref={ref}
      direction={isTablet ? 'column' : 'row'}
      sx={{
        maxWidth: '100%',
        overflowX: isTablet ? 'hidden' : 'scroll',
        borderRadius: (theme) => theme.spacing(2),
        backgroundColor: 'inherit',
        alignItems: 'flex-start',
        gap: isTablet ? '3px' : '16px',
        '& .Mui-selected': {
          backgroundColor: (theme) => theme.palette.secondary.dark,
          borderColor: (theme) => theme.palette.secondary.dark
        }
      }}
    >
      {fieldsList.length === 0 && (
        <Typography
          sx={{
            padding: '0 16px'
          }}
        >
          {t('no_fields', { ns: 'common' })}
        </Typography>
      )}
      <Typography
        sx={{
          color: (theme) => theme.palette.customTheme.contColor,
          padding: '16px 24px 8px 28px',
          textTransform: 'uppercase',
          fontSize: '14px',
          fontWeight: 700
        }}
      >
        {t('categories', { ns: 'common' })}
      </Typography>
      {fieldsList.map((link) => (
        <Button
          ref={categoryButtonRefs.current[link.sectionScrollLabelID]}
          value={link.name.toLowerCase().replace(/ /g, '_')}
          key={crypto.randomUUID()}
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'flex-start',
            textAlign: 'left',
            backgroundColor: 'transparent',
            mb: '3px',
            padding: '8px 12px 8px 20px',
            height: 25,
            fontSize: '12px',
            borderRadius: '7px',
            borderLeft: '8px solid transparent',
            textTransform: 'initial',
            '&::before': {
              content: 'none'
            },
            '&:hover, &:focus-visible': {
              borderLeft: (theme) => `8px solid ${theme.palette.primary.dark}`,
              backgroundColor: (theme) => theme.palette.grey[800]
            },
            '&.Mui-selected': {
              borderLeft: (theme) => `8px solid ${theme.palette.secondary.dark}`
            }
          }}
          data-to-scrollspy-id={link.sectionScrollLabelID}
          onClick={(e) => scrollToSection(e, link.sectionScrollLabelID)}
        >
          {link.name}
        </Button>
      ))}
    </Stack>
  );
});
