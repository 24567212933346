import { styled, Autocomplete, autocompleteClasses } from '@mui/material';

export const GroupAutocomplete = styled(Autocomplete)(({ theme }) => ({
  [`&.${autocompleteClasses.root}`]: {
    height: 50,
    paddingRight: theme.spacing(0.5),
    transition: theme.transitions.create('height', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  }
})) as typeof Autocomplete;
