import { styled, Card, CardProps } from '@mui/material';

interface AdminSettingsPermissionGroupCardProps extends CardProps {
  locked?: boolean;
}

export const GroupCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'locked'
})<AdminSettingsPermissionGroupCardProps>(({ theme, locked }) => ({
  position: 'relative',
  width: '100%',
  minHeight: 226,
  backgroundColor: locked ? 'transparent' : theme.palette.grey[800],
  borderRadius: theme.spacing(2),
  border: locked ? `1px solid ${theme.palette.common.black}` : 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  transition: theme.transitions.create('background-color', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  '&:hover': {
    backgroundColor: theme.palette.customTheme.drawerBackground
  }
}));
