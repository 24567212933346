import { PayloadAction, createSelector, createSlice } from '@reduxjs/toolkit';
import { EmployeeRecord, ErbFieldValue, RetrieveEmployeeRecordRequest, UpdateErSectionRequest } from '@thrivea/organization-client';
import { RootState } from '@app/store';
import { ActionStatus } from 'src/shared';
import { ErbFieldAndValue } from '@features/employee-record-page';
import { selectErbScalarFieldsBySectionId } from '@features/employee-record-builder';

export interface EmployeeRecordState {
  entities: {
    employeeRecordFieldValues: {
      byId: { [key: string]: ErbFieldValue };
      allIds: string[];
    };
  };
  ui: {
    retrieveEmployeeRecordRequestedStatus: ActionStatus;
    updateErSectionRequestedStatus: ActionStatus;
  };
}

const initialState: EmployeeRecordState = {
  entities: {
    employeeRecordFieldValues: {
      byId: {},
      allIds: []
    }
  },
  ui: {
    retrieveEmployeeRecordRequestedStatus: ActionStatus.Idle,
    updateErSectionRequestedStatus: ActionStatus.Idle
  }
};

export const employeeRecordSlice = createSlice({
  name: 'peopleDir',
  initialState,
  reducers: {
    retrieveEmployeeRecordRequested: (state, _action: PayloadAction<RetrieveEmployeeRecordRequest>) => {
      state.ui.retrieveEmployeeRecordRequestedStatus = ActionStatus.Pending;
    },
    retrieveEmployeeRecordSucceeded: (state, action: PayloadAction<EmployeeRecord>) => {
      const { fieldValues } = action.payload;

      for (const value of fieldValues) {
        state.entities.employeeRecordFieldValues.byId[value.erbFieldId] = value;
        state.entities.employeeRecordFieldValues.allIds.push(value.erbFieldId);
      }

      state.ui.retrieveEmployeeRecordRequestedStatus = ActionStatus.Idle;
    },
    retrieveEmployeeRecordFailed: (state) => {
      state.ui.retrieveEmployeeRecordRequestedStatus = ActionStatus.Failed;
    },
    updateErSectionRequested: (state, _action: PayloadAction<UpdateErSectionRequest>) => {
      state.ui.updateErSectionRequestedStatus = ActionStatus.Pending;
    },
    updateErSectionSucceeded: (state) => {
      state.ui.updateErSectionRequestedStatus = ActionStatus.Idle;
    },
    updateErSectionFailed: (state) => {
      state.ui.updateErSectionRequestedStatus = ActionStatus.Failed;
    }
  }
});

const selectErbFieldValuesById = (state: RootState) => state.employeeRecord.entities.employeeRecordFieldValues.byId;
const selectErbFieldValuesIds = (state: RootState) => state.employeeRecord.entities.employeeRecordFieldValues.allIds;

export const selectErbFieldValues = createSelector([selectErbFieldValuesById, selectErbFieldValuesIds], (byId, ids) => ids.map((id) => byId[id]));

export const selectErbFieldValueById = (state: RootState, fieldId: string) => state.employeeRecord.entities.employeeRecordFieldValues.byId[fieldId];

export const selectRetrieveEmployeeRecordRequestedStatus = (state: RootState) => state.employeeRecord.ui.retrieveEmployeeRecordRequestedStatus;

export const selectErbScalarFieldValuesBySectionId = createSelector(
  [selectErbFieldValuesById, selectErbScalarFieldsBySectionId],
  (fieldValuesById, erbFieldsInSection) =>
    erbFieldsInSection.map(
      (field) =>
        ({
          erbScalarField: field,
          erbFieldValue: fieldValuesById[field!.id]
        }) as ErbFieldAndValue
    )
);

export const {
  retrieveEmployeeRecordRequested,
  retrieveEmployeeRecordSucceeded,
  retrieveEmployeeRecordFailed,
  updateErSectionRequested,
  updateErSectionSucceeded,
  updateErSectionFailed
} = employeeRecordSlice.actions;

export default employeeRecordSlice.reducer;
