import { Chip, ChipProps, styled } from '@mui/material';

interface EmployeeRecordFieldChipProps {
  backgroundColor: string;
  textColor: string;
  iconColor?: string;
}

enum ErbUsage {
  Selected,
  PresentInOtherSection,
  Unused
}

interface StyledEmployeeRecordFieldChipProps extends ChipProps {
  isSelected?: boolean;
  isCalculated?: boolean;
  isPresentInOtherSection?: boolean;
}

export const EmployeeRecordFieldChip = styled(Chip, {
  shouldForwardProp: (prop) => prop !== 'isInGroup' && prop !== 'isSelected' && prop !== 'isCalculated' && prop !== 'isPresentInOtherSection'
})<StyledEmployeeRecordFieldChipProps>(({ theme, isSelected, isCalculated, isPresentInOtherSection }) => {
  const scalarNotCalculated: EmployeeRecordFieldChipProps[] = [
    {
      backgroundColor: theme.palette.customTheme.focusItem,
      textColor: theme.palette.common.white
    },
    {
      backgroundColor: theme.palette.secondary.dark,
      textColor: theme.palette.common.black
    },
    { backgroundColor: theme.palette.customTheme.solidBgr, textColor: theme.palette.common.black }
  ];

  const calculated: EmployeeRecordFieldChipProps[] = [
    {
      backgroundColor: '#BDE73E',
      textColor: theme.palette.common.white
    },
    {
      backgroundColor: '#A6FF47',
      textColor: theme.palette.common.black
    },
    { backgroundColor: theme.palette.customTheme.solidBgr, textColor: theme.palette.common.black }
  ];

  const chips = {
    scalarNotCalculated,
    calculated
  };

  const fieldType = isCalculated ? 'calculated' : 'scalarNotCalculated';

  let idx = ErbUsage.Unused;
  if (isPresentInOtherSection) idx = ErbUsage.PresentInOtherSection;
  if (isSelected) idx = ErbUsage.Selected;

  return {
    backgroundColor: chips[fieldType][idx].backgroundColor,
    color: chips[fieldType][idx].textColor,
    width: 'auto',
    height: 22,
    padding: '0 7px',
    borderRadius: '8px',
    '& .MuiChip-label': {
      padding: 0
    }
  };
});
